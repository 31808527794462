import React, {useState, useRef, useEffect}  from 'react';
import {Box, Typography, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Radio, Checkbox} from "@material-ui/core";
import NavBar from "./navBar";
import Step from "./step";

const Page1 = ({orgData, setShowScreen, setOrgData}) => {

    const countries = [
        { code: 'AU', code3: 'AUS', label: 'Australia' },
        { code: 'GE', code3: 'GEO', label: 'Georgia' },
        { code: 'PK', code3: 'PAK', label: 'Pakistan' },
        { code: 'PL', code3: 'POL', label: 'Poland' },
        { code: 'US', code3: 'USA', label: 'United States' },
    ];

    const inputRefs = useRef([]);
    const [submitClick, setSubmitClick] = useState(false);
    const [hqAddressSame, setHqAddressSame] = useState(false);
    const [otherAddressSame, setOtherAddressSame] = useState(false);
    const [transAddressSame, setTransAddressSame] = useState(false);

    useEffect(() => {
        if(hqAddressSame) {
            setOrgData(prevData => ({...prevData,
                hqAddressStreet: prevData.legalAddressStreet,
                hqAddressCity: prevData.legalAddressCity,
                hqAddressState: prevData.legalAddressState,
                hqAddressPostal: prevData.legalAddressPostal,
                hqAddressCountry: prevData.legalAddressCountry
            }));
        }
    }, [orgData.legalAddressStreet, orgData.legalAddressCity, orgData.legalAddressState, orgData.legalAddressPostal, orgData.legalAddressCountry, hqAddressSame]);

    useEffect(() => {
        if(otherAddressSame) {
            setOrgData(prevData => ({...prevData,
                otherAddressStreet: prevData.hqAddressStreet,
                otherAddressCity: prevData.hqAddressCity,
                otherAddressState: prevData.hqAddressState,
                otherAddressPostal: prevData.hqAddressPostal,
                otherAddressCountry: prevData.hqAddressCountry
            }));
        }
    }, [orgData.hqAddressStreet, orgData.hqAddressCity, orgData.hqAddressState, orgData.hqAddressPostal, orgData.hqAddressCountry, otherAddressSame]);

    useEffect(() => {
        if(transAddressSame) {
            setOrgData(prevData => ({...prevData,
                transAddressStreet: prevData.otherAddressStreet,
                transAddressCity: prevData.otherAddressCity,
                transAddressState: prevData.otherAddressState,
                transAddressPostal: prevData.otherAddressPostal,
                transAddressCountry: prevData.otherAddressCountry
            }));
        }
    }, [orgData.otherAddressStreet, orgData.otherAddressCity, orgData.otherAddressState, orgData.otherAddressPostal, orgData.otherAddressCountry, transAddressSame]);

    const changeData = (e) => {
        const { name, value } = e.target;
        setOrgData(prevData => ({...prevData, [name]: value}));
    };

    const nextStep = () => {
        setSubmitClick(true);
        let error = false;
        const rInputs = [
            "adminSet", "adminSet2", "name", "legalAddressStreet", "legalAddressCity", "legalAddressState", "legalAddressPostal", "legalAddressCountry",
            "hqAddressStreet", "hqAddressCity", "hqAddressState", "hqAddressPostal", "hqAddressCountry", "url", "phone"
        ];

        for(let i = 0; i < rInputs.length; i++) {
            if(!orgData[rInputs[i]]) {
                inputRefs.current[rInputs[i]].focus();
                error = true;
                return false;
            }
        }

        if(!error) {
            setSubmitClick(false);
            setShowScreen("page2");
        }
    };

    return (
        <Box>
            <NavBar title={"OrgEverID Setup"} backHandler={() => setShowScreen('terms')} setShowScreen={setShowScreen} />
            <Box className="wizard-box">
                <Box className=" text-center">
                    <Step totalSteps={5} currentStep={1} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item={true} xs={12}>
                        <TextField
                            name="adminSet"
                            variant="outlined"
                            label="Admin Set"
                            placeholder="EverID address"
                            value={orgData.adminSet}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.adminSet}
                            autoFocus
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="adminSet2"
                            variant="outlined"
                            label="Admin Set 2"
                            placeholder="EverID address"
                            value={orgData.adminSet2}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.adminSet2}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="name"
                            variant="outlined"
                            label="Org Name"
                            placeholder="Organization Legal Name"
                            value={orgData.name}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.name}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="otherName"
                            variant="outlined"
                            label="Org Other Name (optional)"
                            placeholder="Organization Nickname or AKA"
                            value={orgData.otherName}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Typography variant="h6" className="font-bold">Org Legal Address</Typography>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="legalAddressStreet"
                            variant="outlined"
                            label="Street Number Street Name"
                            placeholder="Street Number Street Name"
                            value={orgData.legalAddressStreet}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.legalAddressStreet}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="legalAddressCity"
                            variant="outlined"
                            label="City"
                            placeholder="City"
                            value={orgData.legalAddressCity}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.legalAddressCity}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="legalAddressState"
                            variant="outlined"
                            label="State"
                            placeholder="State / Province"
                            value={orgData.legalAddressState}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.legalAddressState}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="legalAddressPostal"
                            variant="outlined"
                            label="Postal"
                            placeholder="Postal Code"
                            value={orgData.legalAddressPostal}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.legalAddressPostal}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Select country</InputLabel>
                            <Select
                                name="legalAddressCountry"
                                value={orgData.legalAddressCountry}
                                label="Select country"
                                onChange={changeData}
                                inputProps={{ref: el =>  el && (inputRefs.current["legalAddressCountry"] = el)}}
                                error={submitClick && !orgData.legalAddressCountry}
                                required
                            >
                                {countries.map((val, index) =>
                                    <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>




                    <Grid item={true} xs={6} style={{marginTop: "5px"}}>
                        <Typography variant="h6" className="font-bold">Org HQ Address</Typography>
                    </Grid>
                    <Grid item={true} xs={6} style={{textAlign: "right"}}>
                        <FormControlLabel style={{fontWeight: "bold"}} value="female" control={<Checkbox checked={hqAddressSame} onChange={() => setHqAddressSame(!hqAddressSame)} />} label={<Typography variant="h6" className="font-bold primary-color">Same as above</Typography>} />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="hqAddressStreet"
                            variant="outlined"
                            label="Street Number Street Name"
                            placeholder="Street Number Street Name"
                            value={orgData.hqAddressStreet}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.hqAddressStreet}
                            disabled={hqAddressSame}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="hqAddressCity"
                            variant="outlined"
                            label="City"
                            placeholder="City"
                            value={orgData.hqAddressCity}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.hqAddressCity}
                            disabled={hqAddressSame}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="hqAddressState"
                            variant="outlined"
                            label="State"
                            placeholder="State / Province"
                            value={orgData.hqAddressState}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.hqAddressState}
                            disabled={hqAddressSame}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="hqAddressPostal"
                            variant="outlined"
                            label="Postal"
                            placeholder="Postal Code"
                            value={orgData.hqAddressPostal}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.hqAddressPostal}
                            disabled={hqAddressSame}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Select country</InputLabel>
                            <Select
                                name="hqAddressCountry"
                                value={orgData.hqAddressCountry}
                                label="Select country"
                                onChange={changeData}
                                inputProps={{ref: el =>  el && (inputRefs.current["hqAddressCountry"] = el)}}
                                error={submitClick && !orgData.hqAddressCountry}
                                disabled={hqAddressSame}
                                required
                            >
                                {countries.map((val, index) =>
                                    <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item={true} xs={6} style={{marginTop: "5px"}}>
                        <Typography variant="h6" className="font-bold">Org Other Address (optional)</Typography>
                    </Grid>
                    <Grid item={true} xs={6} style={{textAlign: "right"}}>
                        <FormControlLabel style={{fontWeight: "bold"}} value="female" control={<Checkbox checked={otherAddressSame} onChange={() => setOtherAddressSame(!otherAddressSame)} />} label={<Typography variant="h6" className="font-bold primary-color">Same as above</Typography>} />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="otherAddressStreet"
                            variant="outlined"
                            label="Street Number Street Name"
                            placeholder="Street Number Street Name"
                            value={orgData.otherAddressStreet}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={otherAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="otherAddressCity"
                            variant="outlined"
                            label="City"
                            placeholder="City"
                            value={orgData.otherAddressCity}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={otherAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="otherAddressState"
                            variant="outlined"
                            label="State"
                            placeholder="State / Province"
                            value={orgData.otherAddressState}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={otherAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="otherAddressPostal"
                            variant="outlined"
                            label="Postal"
                            placeholder="Postal Code"
                            value={orgData.otherAddressPostal}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={otherAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Select country</InputLabel>
                            <Select
                                name="otherAddressCountry"
                                value={orgData.otherAddressCountry}
                                label="Select country"
                                onChange={changeData}
                                inputProps={{ref: el =>  el && (inputRefs.current["otherAddressCountry"] = el)}}
                                disabled={otherAddressSame}
                            >
                                <MenuItem value="">None</MenuItem>
                                {countries.map((val, index) =>
                                    <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item={true} xs={6} style={{marginTop: "5px"}}>
                        <Typography variant="h6" className="font-bold">Transliterated Other Address (optional)</Typography>
                    </Grid>
                    <Grid item={true} xs={6} style={{textAlign: "right"}}>
                        <FormControlLabel style={{fontWeight: "bold"}} value="female" control={<Checkbox checked={transAddressSame} onChange={() => setTransAddressSame(!transAddressSame)} />} label={<Typography variant="h6" className="font-bold primary-color">Same as above</Typography>} />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="transAddressStreet"
                            variant="outlined"
                            label="Street Number Street Name"
                            placeholder="Street Number Street Name"
                            value={orgData.transAddressStreet}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={transAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="transAddressCity"
                            variant="outlined"
                            label="City"
                            placeholder="City"
                            value={orgData.transAddressCity}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={transAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="transAddressState"
                            variant="outlined"
                            label="State"
                            placeholder="State / Province"
                            value={orgData.transAddressState}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={transAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="transAddressPostal"
                            variant="outlined"
                            label="Postal"
                            placeholder="Postal Code"
                            value={orgData.transAddressPostal}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            disabled={transAddressSame}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Select country</InputLabel>
                            <Select
                                name="transAddressCountry"
                                value={orgData.transAddressCountry}
                                label="Select country"
                                onChange={changeData}
                                inputProps={{ref: el =>  el && (inputRefs.current["transAddressCountry"] = el)}}
                                disabled={transAddressSame}
                            >
                                <MenuItem value="">None</MenuItem>
                                {countries.map((val, index) =>
                                    <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>



                    <Grid item={true} xs={12}>
                        <TextField
                            name="url"
                            variant="outlined"
                            label="Organization URL"
                            placeholder="Organization URL"
                            value={orgData.url}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.url}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="phone"
                            variant="outlined"
                            label="Organization Telephone"
                            placeholder="Org Full International Phone"
                            value={orgData.phone}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.phone}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="fax"
                            variant="outlined"
                            label="Organization Fax (optional)"
                            placeholder="Org Full International Fax"
                            value={orgData.fax}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                </Grid>

                <Button className="wizard-btn primary font-bold mt-20" variant="contained" onClick={nextStep} fullWidth>Save & continue</Button>
            </Box>
        </Box>
    );
};

export default Page1;