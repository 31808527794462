import axios from 'axios';
import {API_URL, ORGNAME, SECRET} from "../js/variables";

const apiUrl = API_URL;
const orgname = ORGNAME;
const secret = SECRET;

// const orgname = window._env_.ORGNAME;
// const secret = window._env_.SECRET;
// const apiUrl = window._env_.API_URL;

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
// lookup table to find the index.
const lookup = new Uint8Array(256);
for (let i = 0; i < chars.length; i++) {
    lookup[chars.charCodeAt(i)] = i;
}

const array2B64encode = (arraybuffer, byteOffset, length) => {
    if (length === null || length === undefined) {
        length = arraybuffer.byteLength;
    }
    const bytes = new Uint8Array(arraybuffer, byteOffset || 0, length);
    const len = bytes.length;

    let base64 = '';
    for (let i = 0; i < len; i += 3) {
        base64 += chars[bytes[i] >> 2];
        base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
        base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
        base64 += chars[bytes[i + 2] & 63];
    }

    if (len % 3 === 2) {
        base64 = base64.substring(0, base64.length - 1) + '=';
    } else if (len % 3 === 1) {
        base64 = base64.substring(0, base64.length - 2) + '==';
    }

    return base64;
};

const _createKongAuthHeader = (orgname, verb, url, date) => {
    const enc = new TextEncoder('utf-8');

    return window.crypto.subtle.importKey(
        "raw", // raw format of the key - should be Uint8Array
        enc.encode(secret),
        {
            name: 'HMAC',
            hash: 'SHA-256'
        },
        false, // export = false
        ['sign', 'verify']
    )
        .then(key => {
            let signing_string = `${verb} ${url.pathname} HTTP/1.1\nhost: ${url.hostname}\nx-date: ${date}`;
            return window.crypto.subtle.sign('HMAC', key, enc.encode(signing_string));
        })
        .then(array2B64encode)
        .then(signature => {
            let authHeader = `hmac username="${orgname}", algorithm="hmac-sha256", headers="request-line host x-date", signature="${signature}"`;
            // let authHeader = `hmac username="INF", algorithm="hmac-sha256", headers="request-line host x-date", signature="${signature}"`;
            return authHeader;
        });
};

const _fetch = (path, method = 'GET', data = null, options) => {
    const url = new URL(`${path}`, apiUrl);
    const config = {
        url: url,
        method: method,
        headers: {
            'cache-control': 'no-cache'
        },
        data: data,
        json: true
    };

    Object.assign(config, options);

    const date = new Date().toUTCString();
    // requires explicit Access-Control-Allow-Origin, no wildcard allowed
    // config.credentials = 'include'
    return _createKongAuthHeader(window.ADMIN_SUB_ORGNAME || orgname, method, url, date)
        .then(authHeader => {
            config.headers.Authorization = authHeader;
            config.headers['x-date'] = date;
            return config;
        })
        .then(axios);
};

export default _fetch;
