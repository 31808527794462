import _fetch from './swagger';
import axios from 'axios';
import { API_URL } from "../js/variables";

export default {
  get: (url, data, options) => _fetch(url, 'GET', data, options),
  post: (url, data, options) => _fetch(url, 'POST', data, options),
  put: (url, data, options) => _fetch(url, 'PUT', data, options),
  auth0: {
    get: (url, options) => axios.get(`${API_URL}${url}`, options),
    post: (url, data, options) => axios.post(`${API_URL}${url}`, data, options),
    put: (url, data, options) => axios.put(`${API_URL}${url}`, data, options),
    delete: (url, options) => axios.delete(`${API_URL}${url}`, options)
  }
};
