import React, {useRef}  from 'react';
import {Box, Button, Grid, TextField} from "@material-ui/core";
import NavBar from "./navBar";
import Step from "./step";

const Page2 = ({orgData, setShowScreen, setOrgData}) => {

    const inputRefs = useRef([]);


    const changeData = (e) => {
        const { name, value } = e.target;
        setOrgData(prevData => ({...prevData, [name]: value}));
    };

    const nextStep = () => {
        setShowScreen("page3");
    };

    return (
        <Box>
            <NavBar title={"OrgEverID Setup"} backHandler={() => setShowScreen('page1')} setShowScreen={setShowScreen} />
            <Box className="wizard-box">
                <Box className=" text-center">
                    <Step totalSteps={5} currentStep={2} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item={true} xs={6}>
                        <TextField
                            name="accountNumber"
                            variant="outlined"
                            label="Account Number"
                            placeholder="Type here"
                            value={orgData.accountNumber}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            autoFocus
                            fullWidth
                        />
                    </Grid>
                    <Grid item={true} xs={6}>
                        <TextField
                            name="iban"
                            variant="outlined"
                            label="IBAN"
                            placeholder="Type here"
                            value={orgData.iban}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="bic"
                            variant="outlined"
                            label="BIC"
                            placeholder="Type here"
                            value={orgData.bic}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item={true} xs={6}>
                        <TextField
                            name="routingNumber"
                            variant="outlined"
                            label="Routing Number"
                            placeholder="Type here"
                            value={orgData.routingNumber}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={6}>
                        <TextField
                            name="clabe"
                            variant="outlined"
                            label="Clabe"
                            placeholder="Type here"
                            value={orgData.clabe}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item={true} xs={6}>
                        <TextField
                            name="bsb"
                            variant="outlined"
                            label="BSB"
                            placeholder="Type here"
                            value={orgData.bsb}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Button className="wizard-btn secondary font-bold mt-20" variant="contained" onClick={nextStep} fullWidth>Skip this section</Button>
                <Button className="wizard-btn primary font-bold mt-20" variant="contained" onClick={nextStep} fullWidth>Save & continue</Button>
            </Box>
        </Box>
    );
};

export default Page2;