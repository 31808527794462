import { sortBy } from 'lodash';
import { isArray } from 'lodash';
import moment from 'moment-timezone';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Message, Pagination, Table, Grid, Segment, Loader } from 'semantic-ui-react';
import helpers, { cancelToken, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_ERROR, STATUS_OK } from '../../services/helpers';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ReportsContext from '../../contexts/ReportsContext';
import PageContext from '../../contexts/PageContext';
import PageSelect from '../common/PageSelect';
import ErrorMessage from '../common/ErrorMessage';


const ReportsList = (props) => {
    console.log("listRep11",props.list);
    const [selectedReport, setSelectedReport] = useState(null);
    const [column, setColumn] = useState(props.sortByColumn);
    const [direction, setDirection] = useState(props.sortDirection);
    const [showModal, setShowModal] = useState(false);
    const [listPromise, setListPromise] = useState({ promise: null, source: null });

    const setReports = useContext(ReportsContext);
    const { searchTerm } = useContext(SearchTermContext);
    const { itemsPerPage } = useContext(ShowResultsContext);
    const { currentPage, setCurrentPage } = useContext(PageContext);

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            if (listPromise.source !== null) {
                helpers.cancel(listPromise.promise, listPromise.source);
            }
        }
    }, [listPromise]);

    if (props.status === STATUS_INIT) {
        return (
            <Segment>
                <Loader active size='large' inline='centered'>{t('list_message_loading_reports')}</Loader>
            </Segment>
        );
    }

    if (props.status === STATUS_ERROR) {
        return <ErrorMessage error={props.error} />;
    }

    if (props.status === STATUS_NO_RESULTS) {
        return (
            <Message>
                <Message.Header>{t('list_message_no_results_title')}</Message.Header>
                <p>{t('list_message_no_results_text')}</p>
            </Message>
        );
    }

    const loadingContent = (
        <Table.Row>
            <Table.Cell colSpan='8'>
                <Segment basic>
                    <Loader active size='large' inline='centered'>{t('list_message_loading_reports')}</Loader>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );


    // pagination
    const pageNumbers = [];
    for (let x = 0; x < Math.ceil(props.totalCount / itemsPerPage); x++) {
        pageNumbers.push(x);
    }

    const resolved = (response) => {
        const { reportList, reportCount, success } = response.data;
        if (success === true) {
            setListPromise({ promise: null, source: null });
            setReports({
                list: reportList,
                count: reportCount,
                status: (reportList && reportList.length && reportCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const handlePaginationChange = (event, { activePage }) => {
        setCurrentPage(activePage);
        setReports({
            list: -1,
            count: props.totalCount,
            status: STATUS_LOADING
        });

        if (listPromise.source !== null) {
            helpers.cancel(listPromise.promise, listPromise.source);
        }

        const options = { keyword: `${searchTerm}*`, page: activePage, limit: itemsPerPage, sort: 'createdAt' };
        const source = cancelToken.source();
        const promise = helpers
            .search(props.listURL, options, source)
            .then(resolved)
            .catch(console.error);
        setListPromise({ promise, source });
    };

    // column sort
    const sortByColumn = (clickedColumn) => () => {
        if (column !== clickedColumn) {
            setColumn(clickedColumn);
            setDirection('ascending');
            return;
        }
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    };

    function handleSort(list, clickedColumn) {
        const sortList = sortBy(list, (obj) => {
            switch (clickedColumn) {
                case 'address':
                case 'balance':
                    return obj.wallet[clickedColumn] && obj.wallet[clickedColumn].toLowerCase();
                default:
                    return obj.profile[clickedColumn] && obj.profile[clickedColumn].toLowerCase();
            }
        });
        if (direction === 'descending') {
            return sortList.reverse();
        }
        return sortList;
    };
    
    const tableRows = (isArray(props.list) ? props.list : []).map(({id, reportDate,reportName,reportType}) => {
        return (
            <Table.Row key={id}>
                <Table.Cell>{reportDate}</Table.Cell>
                <Table.Cell>{reportName}</Table.Cell>
                <Table.Cell>{reportType}</Table.Cell>
                <Table.Cell textAlign='center'>
                    <Icon circular inverted name='info' color='blue' data-key={id} className='icon-button' />
                </Table.Cell>
            </Table.Row>
        );
    });

    return (
        <Grid padded='vertically'>
            <Grid.Column>
                <Table sortable celled singleLine compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'date' ? direction : null}
                                onClick={sortByColumn('date')}>
                                Date
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === 'reportname' ? direction : null}
                                onClick={sortByColumn('reportname')}>
                                Report Name
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === 'reporttype' ? direction : null}
                                onClick={sortByColumn('reporttype')}>
                                Report Type
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {props.status === STATUS_LOADING ? loadingContent : tableRows}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6' textAlign='center' style={{ position: 'relative' }}>
                                <Pagination
                                    activePage={currentPage}
                                    totalPages={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                    siblingRange={1}
                                    boundaryRange={1}
                                    ellipsisItem={undefined}
                                    firstItem={undefined}
                                    lastItem={undefined}
                                    prevItem={undefined}
                                    nextItem={undefined}
                                    disabled={pageNumbers.length === 1}
                                />
                                <PageSelect activePage={currentPage}
                                    pagesTotal={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Grid.Column>
        </Grid>
    );
};

export default ReportsList;
