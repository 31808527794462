import React from 'react';
import { Message } from 'semantic-ui-react';

export default function ErrorMessage (props) {
  const { error } = props;
  return (
    <Message color='red'>
      <Message.Header>[{error.name}] {error.message} {error.response && `(${error.response.statusText})`}</Message.Header>
      {error.response && (
        <>
          <p><strong>Message:</strong> {error.response.data.Message}</p>
          <p>{error.config.method.toUpperCase()} {error.request.responseURL}</p>
        </>
      )}
    </Message>
  );
};
