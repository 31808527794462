import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React, {useRef, useState} from 'react'
import {Icon, Confirm, Modal, Grid as GridS, Dimmer, Loader} from 'semantic-ui-react'
import TransactionStatusIcon from '../common/TransactionStatusIcon'
import {Box, Grid, Container, Dialog, DialogTitle, DialogContent, CircularProgress, Divider, Input, InputAdornment, IconButton, FormControl} from "@material-ui/core"
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import helpers, {cancelToken} from "../../services/helpers";
import {useAuth0} from "../../react-auth0-wrapper";
import {useTranslation} from "react-i18next"
import Close from '@material-ui/icons/Close';

const ProfileRow = ({label, value}) => {
    return (
        <>
            <Grid item={true} lg={6} dangerouslySetInnerHTML={{__html: label}} />
            <Grid item={true} xs={6}>{value}</Grid>
        </>
    );
};

const FileUpdate = ({value, label, type, id, status, setDataToast, setShowModal, setRefreshList}) => {
    const [text, setText] = useState(value);
    const [file, setFile] = useState("");
    const [updateFileModal, setUpdateFileModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const fileRef = useRef(null);
    const { getIdToken } = useAuth0();

    const changeFileData = (e) => {
        const files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setFile(e.target.result);
        };
    };

    const updateData = () => {
        if(!file) {
            setDataToast({ open: true, variant: "error", message: "Please upload the new File", timeAutoHide: 6000 });
            return false;
        }
        const token = getIdToken();
        const source = cancelToken.source();
        if(!loading) {
            setLoading(true);
            const dataToPost = {id, type, file, text};
            helpers.auth0.updateOrgProfileFile(token, source, dataToPost)
                .then(resp => {
                    setLoading(false);
                    if(resp.data.success) {
                        setDataToast({ open: true, variant: "success", message: resp.data.data, timeAutoHide: 6000 });
                        setUpdateFileModal(false);
                        setRefreshList(prev => !prev);
                        setShowModal(false);
                    } else {
                        setDataToast({ open: true, variant: "error", message: resp.data.error, timeAutoHide: 6000 });
                    }
                }).catch(error => {
                setLoading(false);
                const resp = JSON.parse(error.request.response);
                const msg = resp.error ? resp.error : resp.message;
                setDataToast({ open: true, variant: "error", message: msg, timeAutoHide: 6000 });
            });
        }
    };
    if(status === "approved" || status === "needs_review") {
        return (
            <>
                <Icon onClick={() => setUpdateFileModal("ads")} color='blue' name='edit' style={{'marginLeft': '10px', 'cursor': 'pointer' }} />
                <Dialog open={updateFileModal} fullWidth={true} onClose={() => setUpdateFileModal(false)}>
                    <DialogTitle style={{padding: "20px", color: '#16359D'}}>
                        <IconButton
                            aria-label="close"
                            onClick={() => setUpdateFileModal(false)}
                            style={{position: 'absolute', right: 8, top: 8, color: '#16359D'}}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Divider style={{marginTop: "15px"}} />
                    <DialogContent>
                        <Box style={{padding: "10px", textAlign: 'center'}}>
                            <h3>{label}</h3>
                            <FormControl fullWidth required variant="outlined">
                                <Input
                                    variant="outlined"
                                    placeholder="Upload a document"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => fileRef.current.click()}
                                                edge="end"
                                            >
                                                <CloudUploadIcon />
                                                <input ref={fileRef} name="file" type="file" onChange={changeFileData} accept="application/pdf, image/*" hidden />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={320}
                                />
                            </FormControl>
                            <Button style={{ backgroundColor: '#fa4261', color: 'white', marginTop: '20px' }} onClick={updateData}>
                                {loading ? <><CircularProgress size={20} color="inherit" /> Submitting...</> : "Submit"}
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        );
    } else {
        return(null);
    }
};

const OrgDetails = (props) => {
    const data = props.data;
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [messageModalConfirm, setMessageModalConfirm] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [kybScreening, setKybScreening] = useState('');
    const [kybScreeningFile, setKybScreeningFile] = useState('');
    const [submitClick, setSubmitClick] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [fileModal, setFileModal] = useState(false);
    const { getIdToken } = useAuth0();
    const { t } = useTranslation();

    const legalAddress = data.address;
    const dispAddress = legalAddress.street+", "+legalAddress.city+", "+legalAddress.state+", "+legalAddress.postal+", "+legalAddress.country;

    const hqAddress = data.hqAddress;
    const dispHqAddress = hqAddress.street+", "+hqAddress.city+", "+hqAddress.state+", "+hqAddress.postal+", "+hqAddress.country;

    let dispOtherAddress = "";
    if(data.otherAddress.street) {
        const address = data.otherAddress;
        dispOtherAddress = address.street+", "+address.city+", "+address.state+", "+address.postal+", "+address.country;
    }

    let dispTransAddress = "";
    if(data.transAddress.street) {
        const address = data.transAddress;
        dispTransAddress = address.street+", "+address.city+", "+address.state+", "+address.postal+", "+address.country;
    }

    const kybScreeningRef = useRef(null);
    const kybScreeningFileRef = useRef(null);

    const changeFileData = (e) => {
        const files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setKybScreeningFile(e.target.result);
        };
    };

    const actionClickStatus = (statusData) => {
        if(statusData === "approve") {
            if(!kybScreening) {
                setSubmitClick(true);
                kybScreeningRef.current.focus();
                return false;
            }
            if(!kybScreeningFile) {
                props.setDataToast({ open: true, variant: "error", message: "Please upload the KYB Sanctions List Screening File", timeAutoHide: 6000 });
                return false;
            }
        }

        setSubmitClick(false);
        setNewStatus(statusData);
        setMessageModalConfirm("Are you sure you want to change the status to "+statusData);
        setShowModalConfirm(true);
    };

    const handleConfirm = () => {
        const dataToPost = {};
        dataToPost.id = data.id;
        dataToPost.status = newStatus === "cancel" ? "cancelled" : "approved";
        if(newStatus === "approve") {
            dataToPost.kybScreening = kybScreening;
            dataToPost.kybScreeningFile = kybScreeningFile;
        }

        const token = getIdToken();
        const source = cancelToken.source();
        setLoading(true);
        helpers.auth0.updateOrgProfile(token, source, dataToPost)
        .then(resp => {
            setLoading(false);
            if(resp.data.success) {
                props.setDataToast({ open: true, variant: "success", message: resp.data.data, timeAutoHide: 6000 });
                props.setRefreshList(prev => !prev);
                props.setShowModal(false);
                setShowModalConfirm(false);
            } else {
                props.setDataToast({ open: true, variant: "error", message: resp.data.error, timeAutoHide: 6000 });
            }
        }).catch(error => {
            setLoading(false);
            const resp = JSON.parse(error.request.response);
            const msg = resp.error ? resp.error : resp.message;
            props.setDataToast({ open: true, variant: "error", message: msg, timeAutoHide: 6000 });
        });
    };

    const previewFile = (content) => {
        setSelectedFile(content);
        setFileModal(true);
    }

    return (
        <React.Fragment>
            <Modal.Header>
                <GridS columns={3}>
                    <GridS.Row>
                        <GridS.Column>
                            Organization Detail
                        </GridS.Column>
                        <GridS.Column textAlign='center'>
                            {data && (
                                <>
                                    {(data.status === "in_progress" || data.status === "needs_review") && (
                                        <>
                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                <Button onClick={() => actionClickStatus('approve')}>
                                                    <CheckCircleIcon fontSize="small" className="text-success">
                                                    </CheckCircleIcon>
                                                    Approve
                                        </Button>
                                                <Button onClick={() => actionClickStatus('cancel')}>
                                                    <BlockIcon fontSize="small" className="text-danger">
                                                    </BlockIcon>
                                                    Cancel
                                        </Button>
                                            </ButtonGroup>
                                        </>
                                    )}
                                </>
                            )}
                        </GridS.Column>
                        <Confirm
                            header={"Please Confirm"}
                            open={showModalConfirm}
                            content={messageModalConfirm}
                            cancelButton={"Cancel"}
                            confirmButton={"Ok"}
                            onOpen={() => setShowModalConfirm(true)}
                            onCancel={() => setShowModalConfirm(false)}
                            onConfirm={handleConfirm}
                            size='tiny'
                        />
                        <GridS.Column textAlign='right'>
                            <div style={{ fontSize: 14, fontWeight: 500 }}><TransactionStatusIcon iconSize={"large"} status={data ? data.status : null} /></div>
                        </GridS.Column>
                    </GridS.Row>
                </GridS>
                {/*<div style={{ fontSize: 14, fontWeight: 500 }}>ID: {txID}</div>*/}
            </Modal.Header>
            <Modal.Content scrolling>
                <Container component="main" maxWidth="lg">
                    <Box>
                        <Grid style={{paddingTop: '18px', fontSize: '22px'}} container>
                            <ProfileRow label={"Org EverWallet:"} value={data.everWallet} />
                            <ProfileRow label={"Admin Set: (≥2 EverID addresses)"} value={data.adminSet+"; "+data.adminSet2} />
                            <ProfileRow label={"Org Name:"} value={data.name} />
                            <ProfileRow label={"Org Other Name:"} value={data.otherName} />
                            <ProfileRow label={"Org Legal Address:"} value={dispAddress} />
                            <ProfileRow label={"Org HQ Address:"} value={dispHqAddress} />
                            <ProfileRow label={"Org Other Address:"} value={dispOtherAddress} />
                            <ProfileRow label={"Transliterated Other Address:"} value={dispTransAddress} />
                            <ProfileRow label={"Organization URL:"} value={data.url} />
                            <ProfileRow label={"Organization Telephone:"} value={data.phone} />
                            <ProfileRow label={"Organization Fax:"} value={data.fax} />
                            <ProfileRow label={"Bank Account: IBAN | Account Number:"} value={data.iban} />
                            <ProfileRow label={"Bank Account: BIC | Routing Number | Clabe | BSB"} value={data.bic} />
                            <ProfileRow label={"Account Number:"} value={data.accountNumber} />
                            <ProfileRow label={"Routing Number:"} value={data.routingNumber} />
                            <ProfileRow label={"Caleb:"} value={data.clabe} />
                            <ProfileRow label={"BSB:"} value={data.bsb} />
                            <ProfileRow label={"Organization Categorization: [Family, Neighborhood, Community, Sole Proprietorship, Partnership (SubType: LP, LLP, LLLP), Company, Corporation (SubType: LLC, LTD, C, S, Nonprofit), Co-op, Trust, Government, NGO, Educational]"} value={data.category} />
                            <ProfileRow label={"Description of Services:"} value={data.services} />
                            <ProfileRow label={"Business Model:"} value={data.businessModel} />
                            <ProfileRow label={"Organization LEI: [from GLEIF]"} value={data.lei} />
                            <ProfileRow label={"Organization Date of Incorporation:"} value={data.incorporationDate} />
                            <ProfileRow
                                label={"Entity Registration Number:"}
                                value={
                                    <>
                                        <span>{data.registrationNumber}</span> | <span className="org-detail-file-link" onClick={() => previewFile(data.registrationNumberFile)}>View File</span>
                                        <FileUpdate
                                            value={data.registrationNumber}
                                            type={"registrationNumber"}
                                            label={"Entity Registration Number"}
                                            setDataToast={props.setDataToast}
                                            id={data.id}
                                            status={data.status}
                                            setRefreshList={props.setRefreshList}
                                            setShowModal={props.setShowModal}
                                        />
                                    </>
                                }
                            />
                            <ProfileRow
                                label={"TAX Identification Number:"}
                                value={
                                    <>
                                        <span>{data.taxIdentificationNumber}</span> | <span className="org-detail-file-link" onClick={() => previewFile(data.taxIdentificationNumberFile)}>View File</span>
                                        <FileUpdate
                                            value={data.taxIdentificationNumber}
                                            type={"taxIdentificationNumber"}
                                            label={"TAX Identification Number"}
                                            setDataToast={props.setDataToast}
                                            id={data.id}
                                            status={data.status}
                                            setRefreshList={props.setRefreshList}
                                            setShowModal={props.setShowModal}
                                        />
                                    </>
                                }
                            />
                            <ProfileRow
                                label={"Register of Directors and/or Members/UBOs:"}
                                value={
                                    <>
                                        <span>{data.ubos}</span> | <span className="org-detail-file-link" onClick={() => previewFile(data.ubosFile)}>View File</span>
                                        <FileUpdate
                                            value={data.ubos}
                                            type={"ubos"}
                                            label={"Register of Directors and/or Members/UBOs"}
                                            setDataToast={props.setDataToast}
                                            id={data.id}
                                            status={data.status}
                                            setRefreshList={props.setRefreshList}
                                            setShowModal={props.setShowModal}
                                        />
                                    </>
                                }
                            />
                            <ProfileRow label={"Organization Type: [entity legal form in LEI] see This"} value={data.type} />
                            <ProfileRow label={"Legal Jurisdiction: <a href='https://www.gleif.org/en/about-lei/code-lists/gleif-accepted-legal-jurisdictions-code-list#' target='_blank'>See This</a> and <a href='https://www.gleif.org/content/2-about-lei/7-code-lists/3-gleif-accepted-legal-juris[…]ist/2019-03-19-gleif_legal_jurisdiction_codes_v1.1-public.xlsx' target='_blank'>This</a>"} value={data.legalJurisdiction} />
                            <ProfileRow label={"Legal Registration: <a href='https://www.gleif.org/en/about-lei/code-lists/gleif-registration-authorities-list#' target='_blank'>See This</a> and <a href='https://www.gleif.org/content/2-about-lei/7-code-lists/1-gleif-registration-authorities-list/2019-12-05_ra-list-v1.5.csv' target='_blank'>This</a>"} value={data.legalRegistration} />
                            <ProfileRow label={"License (if financial institution, link to license):"} value={data.license} />
                            <ProfileRow label={"Organization Reg Body/ Authority:"} value={data.registrationBody} />
                            <ProfileRow label={"Link to Auth Body database / directory / registry (to look up entities):"} value={data.registrationBodyLink} />
                            <ProfileRow label={"Associated Entity:"} value={data.associatedEntity} />
                            <ProfileRow label={"Entity Status:"} value={data.entityStatus} />
                            <ProfileRow label={"Entity Expiration Date:"} value={data.entityExpirationDate} />
                            <ProfileRow label={"Entity Expiration Reason:"} value={data.entityExpirationReason} />
                            <ProfileRow label={"Successor Entity:"} value={data.successorEntity} />
                            <ProfileRow label={"Initial Registration Date:"} value={data.initialRegistrationDate} />
                            <ProfileRow label={"Last Update Date:"} value={data.lastUpdateDate} />
                            <ProfileRow label={"Next Renewal Date:"} value={data.nextRenewalDate} />
                            <ProfileRow
                                label={"Registration Status (certificate of good standing):"}
                                value={
                                    <>
                                        <span>{data.registrationStatus}</span> | <span className="org-detail-file-link" onClick={() => previewFile(data.registrationStatusFile)}>View File</span>
                                        <FileUpdate
                                            value={data.registrationStatus}
                                            type={"registrationStatus"}
                                            label={"Registration Status (certificate of good standing)"}
                                            setDataToast={props.setDataToast}
                                            id={data.id}
                                            status={data.status}
                                            setRefreshList={props.setRefreshList}
                                            setShowModal={props.setShowModal}
                                        />
                                    </>
                                }
                            />
                            <ProfileRow label={"Parent Org(s):"} value={data.parentOrgs} />
                            <ProfileRow label={"Child Org(s):"} value={data.childOrgs} />
                            {data.status === "needs_review" ?
                                <>
                                    <Grid item={true} lg={6}>Organizational KYB Sanctions List Screening:</Grid>
                                    <Grid item={true} xs={6}>
                                        <FormControl fullWidth required variant="outlined">
                                            {/*<InputLabel>Organizational KYB Sanctions List Screening</InputLabel>*/}
                                            <Input
                                                name="kybScreening"
                                                variant="outlined"
                                                placeholder="Upload a document"
                                                value={kybScreening}
                                                onChange={(e) => setKybScreening(e.target.value)}
                                                inputRef={kybScreeningRef}
                                                error={submitClick && !kybScreening}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => kybScreeningFileRef.current.click()}
                                                            edge="end"
                                                        >
                                                            <CloudUploadIcon />
                                                            <input ref={kybScreeningFileRef} name="kybScreeningFile" type="file" onChange={changeFileData} accept="application/pdf, image/*" hidden />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={320}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                                : <>{data.status === "approved" && <ProfileRow
                                    label={"Organizational KYB Sanctions List Screening:"}
                                    value={
                                        <>
                                            <span>{data.kybScreening}</span> | <span className="org-detail-file-link" onClick={() => previewFile(data.kybScreeningFile)}>View File</span>
                                            <FileUpdate
                                                value={data.kybScreening}
                                                type={"kybScreening"}
                                                label={"Organizational KYB Sanctions List Screening"}
                                                setDataToast={props.setDataToast}
                                                id={data.id}
                                                status={data.status}
                                                setRefreshList={props.setRefreshList}
                                                setShowModal={props.setShowModal}
                                            />
                                            {data.approvedBy && <span style={{fontSize: "12px"}}>({data.approvedBy} | {data.approvedAt})</span>}
                                        </>
                                    } />
                                }</>
                            }
                        </Grid>
                    </Box>
                    <Dialog open={fileModal} fullWidth={true} onClose={() => setFileModal(false)}>
                        <DialogTitle style={{padding: "20px", color: '#16359D'}}>
                            <IconButton
                                aria-label="close"
                                onClick={() => setFileModal(false)}
                                style={{position: 'absolute', right: 8, top: 8, color: '#16359D'}}
                            >
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <Divider style={{marginTop: "15px"}} />
                        <DialogContent>
                            <object data={selectedFile} width="100%" height="600px" />
                        </DialogContent>
                    </Dialog>
                </Container>
                {(loading === true) ? (
                    <Dimmer active={true} page>
                        <Loader size='huge'>{t('popup_loading')}</Loader>
                    </Dimmer>
                ) : ""}
            </Modal.Content>
        </React.Fragment>
    )
};

export default OrgDetails
