import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './assets/resource.json';

export const initLang = () => {
  const savedLang = localStorage.getItem('language');
  if (savedLang) {
    return savedLang;
  }
  const browserLang = navigator.language.split('-')[0] || 'en'; // if navigattor.language == null
  const acceptedLang = ['en','id'];
  if (acceptedLang.includes(browserLang)) {
    return browserLang;
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: initLang(),
    fallbackLng: 'en',
    
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
});

  export default i18n;
