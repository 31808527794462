import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import AgentsContext from '../../contexts/AgentsContext';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import AgentsList from './AgentsList';

const AgentsProvider = ({updateOrg, setNameTitleModule}) => {
    const [agents, setAgents] = useState({ list: undefined, count: 0, status: STATUS_INIT });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const { getIdToken, logout } = useAuth0();
    const SEARCH_URL = '/v3/admin/restricted/organization/search_agent_index';
    //const UPDATE_URL = '/v3/admin/organization/update_agent_index';
    const urlParams = { page: 1, limit: itemsPerPage, sort: 'createdAt' };
    
    setNameTitleModule('sidebar_agents');

    const resolved = (response) => {
        const { agentList, agentCount, success } = response.data;
        if (success === true) {
            setAgents({
                list: agentList,
                count: agentCount,
                status: (agentList && agentList.length && agentCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const rejected = (error) => {
        console.error(error);
        if (error.message !== cancelMessage) {
            setAgents({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setAgents({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };


    //Commented by two calls to the request

    //useEffect(() => {
    //    const source = cancelToken.source();
    //    const listPromise = helpers
    //        .search(SEARCH_URL, urlParams, source)
    //        .then(resolved)
    //        .catch(rejected);
    //
    //    return () => {
    //        helpers.cancel(listPromise, source);
    //    };
    //}, []);

    useEffect(() => {
        refreshList({ statusText: STATUS_LOADING });
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(SEARCH_URL, urlParams, source, getIdToken())
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    }, [updateOrg]);

    if (agents.error && agents.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }
    return (
        <React.Fragment>
            <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
                <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                        <AgentsContext.Provider value={setAgents}>

                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <SearchBar onSearchTermChange={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <ShowResults onListRefresh={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <AgentsList
                                status={agents.status}
                                list={agents.list}
                                listURL={SEARCH_URL}
                                totalCount={agents.count}
                                sortByColumn='firstName'
                                sortDirection='ascending'
                                error={agents.error}
                            />

                        </AgentsContext.Provider>
                    </PageContext.Provider>
                </ShowResultsContext.Provider>
            </SearchTermContext.Provider>
        </React.Fragment>
    );
};

export default AgentsProvider;
