import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import helpers, { cancelToken, STATUS_LOADING } from '../../services/helpers';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import {useAuth0} from "../../react-auth0-wrapper";


const options = [
    { key: 0, text: '10', value: 10 },
    { key: 1, text: '25', value: 25 },
    { key: 2, text: '50', value: 50 }
];

const ShowResults = (props) => {
    const [searchPromise, setSearchPromise] = useState({ promise: null, source: null });
    const { itemsPerPage, setItemsPerPage } = useContext(ShowResultsContext);
    const { searchTerm } = useContext(SearchTermContext);
    const { getIdToken } = useAuth0();

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            helpers.cancel(searchPromise.promise, searchPromise.source);
        };
    }, []);

    const handleChange = (event, { value }) => {
        setItemsPerPage(value);

        props.onListRefresh({ statusText: STATUS_LOADING });   // show loader

        if (searchPromise.source !== null) {
            helpers.cancel(searchPromise.promise, searchPromise.source);
        }
        const options = { ...props.urlParams, limit: value };
        if(props.limitQueryParameter) {
            delete options.limit;
            options[props.limitQueryParameter] = value;
        }
        if (searchTerm.length > 0 && searchTerm !== '*') {
            Object.assign(options, { keyword: `${searchTerm}` });
        }
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(props.listURL, options, source, getIdToken())
            .then(props.onListRefresh)
            .catch(props.onFetchError);
        setSearchPromise({ promise, source });
    };

    return (
        <div style={{ float: 'right', paddingLeft: 25 }}>
            {t('show_results_results_per_page')}&nbsp;&nbsp;
      <Dropdown
                onChange={handleChange}
                options={options}
                value={itemsPerPage}
                selection
                compact
            />
        </div>
    );
};

export default ShowResults;
