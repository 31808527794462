import React, { useState } from 'react';
import { Modal, Button, Confirm, Dimmer, Loader, Icon, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ModalConfirm = (props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const { t } = useTranslation();

  const resolve = (response) => {
    setLoading(false);
    setSuccessMessage(props.onResolve(response));
  };

  const reject = error => {
    setLoading(false);
    const errorMessage = error.response && error.response.data.Message;
    setErrorMessage(errorMessage || error.message);
    console.error(error);
  };

  const handleConfirm = async () => {
    setShowConfirm(false);
    setLoading(true);
    await props.onAction().then(resolve).catch(reject);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setSuccessMessage(null);
  };

  return (
    <React.Fragment>
      <Confirm
        open={showConfirm && !loading}
        content={props.content || `${t('popup_want_to_continue')}`}
        cancelButton={t('popup_cancel')}
        confirmButton={props.confirmButton || `${t('popup_ok')}`}
        onOpen={() => setShowConfirm(true)}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        trigger={props.trigger}
        size='tiny'
      />

      {(loading === true) ? (
        <Dimmer active={true} page>
          <Loader size='huge'>{t('popup_loading')}</Loader>
        </Dimmer>
      ) : (
          <React.Fragment>
            <Modal
              open={!!successMessage}
              onClose={handleClose}
              size='small'
            >
              <Modal.Header>{t('popup_operation_finished')}</Modal.Header>
              <Modal.Content>
                <p>{successMessage}</p>
              </Modal.Content>
              <Modal.Actions>
                <Button positive onClick={handleClose}>
                  <Icon name='checkmark' /> {t('popup_close')}
                </Button>
              </Modal.Actions>
            </Modal>

            <Modal
              open={!!errorMessage}
              onClose={() => setErrorMessage(null)}
              basic
              size='small'
            >
              <Header icon='warning circle' content={t('popup_operation_cancelled')} />
              <Modal.Content>
                <h3>{errorMessage}</h3>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' onClick={() => setErrorMessage(null)} inverted>
                  <Icon name='cancel' /> {t('popup_close')}
                </Button>
              </Modal.Actions>
            </Modal>
          </React.Fragment>
        )}

    </React.Fragment>
  );
};

export default ModalConfirm;
