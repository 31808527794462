//Env variables..
export let API_URL = process.env.REACT_APP_API_URL;
export let APP_AUTH0_CLIENT_ID = process.env.REACT_APP_APP_AUTH0_CLIENT_ID;
export let APP_AUTH0_DOMAIN = process.env.REACT_APP_APP_AUTH0_DOMAIN;
export let BLOCK_EXPLORER_EVERCHAIN_URL = process.env.REACT_APP_BLOCK_EXPLORER_EVERCHAIN_URL;
export let BLOCK_EXPLORER_IDCHAIN_URL = process.env.REACT_APP_BLOCK_EXPLORER_IDCHAIN_URL;
export let ETHSTATS_EVERCHAIN_URL = process.env.REACT_APP_ETHSTATS_EVERCHAIN_URL;
export let ETHSTATS_IDCHAIN_URL = process.env.REACT_APP_ETHSTATS_IDCHAIN_URL;
export let ORGNAME = process.env.REACT_APP_ORGNAME;
export let SECRET = process.env.REACT_APP_SECRET;
export let WALLET_TOKEN_ADDRESS = process.env.REACT_APP_WALLET_TOKEN_ADDRESS;
export let WALLET_DEPOSIT_ADDRESS = process.env.REACT_APP_WALLET_DEPOSIT_ADDRESS;
export let NETWORK = process.env.REACT_APP_NETWORK;