import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const OrganizationList = ({ handleClick, data }) => (
    <ListContainer onClick={evt => { evt.stopPropagation() }}>
        {
            !!data && (
                _.isArray(data)
                    ? data.map(item =>
                        <ul key={item} className="suborg" id="suborg" name="suborg">
                            <StyledTreeItem
                                key={item}
                                nodeId={item}
                                labelText={item}
                                onClick={() => {
                                    handleClick(item)
                                }}
                                labelIcon={LocalOfferIcon}
                                labelInfo=""
                                color="#30b0b6"
                                bgColor="#e6f4ea"
                                className="suborg"
                                id="suborg"
                                name="suborg"
                            />
                        </ul>
                    )
                    : Object.keys(data).map(prop =>
                        <ul key={'org-' + prop} className="org" id="org" name="org">
                            <StyledTreeItem
                                nodeId={prop}
                                labelText={prop}
                                key={'org_sub.' + prop}
                                onClick={() => {
                                    handleClick(prop, data[prop])
                                }}
                                labelIcon={LocalOfferIcon}
                                labelInfo=""
                                style={{ padding: 0 }}
                                color="#129eb6"
                                bgColor="#f3e8fd"
                                className="org"
                                id="org"
                                name="org"
                            >
                                <OrganizationList handleClick={handleClick} data={data[prop]} />
                            </StyledTreeItem>
                        </ul>
                    )
            )
        }
    </ListContainer>
);

const useTreeItemStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.secondary,
    },
    content: {
        marginLeft: '5px',
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
    },
    org: {
        padding: 0
    },
    expanded: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
        'padding-top': '5px',
        'padding-bottom': '5px'
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            key={labelText}
            label={
                <div className={classes.labelRoot}>
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 400,
    },
});

export default function OrgSelect(props) {
    if (!props.metaData) {
        return <></>;
    }

    useEffect(() => {
        if (props.metaData && props.metaData.role_name === 'SUPER_ADMIN' && !props.currentOrg) {
        }
    }, [props.metaData]);

    const handleClick = (element, childs) => {
        if (childs) {
            return false;
        }
        props.setOrganizacionSelected(element);
        props.setMessageModalConfirm(`Are you sure you want to send ${props.data.profile.firstName} ${props.data.profile.lastName} to the organization ${element}?`)
        props.setShowModalConfirm(true);
    };

    let showList;
    if (props.metaData.role_name === 'SUPER_ADMIN') {

        showList = props.metaData.organizations_tree;
    }
    if (props.metaData.role_name === 'ORGANIZATION_ADMIN') {
        showList = props.metaData.organizations_tree[props.metaData.org_name];
    }
    if (props.metaData.role_name === 'SUB_ORGANIZATION_ADMIN') {
        showList = props.metaData.sub_org_name;
    }
    const classes = useStyles();

    return (

        <div>
            <TreeView
                className={classes.root}
                defaultExpanded={['3']}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
            >
                <OrganizationList handleClick={handleClick} data={showList} />
            </TreeView>
        </div>
    );
}

const ListContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ul ul ul {
      padding : 0;
  }
  > li {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 42px;
    padding: .5em .7em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    cursor: pointer;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    + ul {
      height: 0;
      overflow: hidden;

      li {
        padding-left: 0px;
      }
    }

    &.visible {
      background-color: rgba(0, 0, 0, 0.07);
      + ul {
        height: initial;
        padding: 0; 
      }
    }
  }
`;