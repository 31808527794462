import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Confirm, Dimmer, Dropdown, Input, Loader, Modal } from 'semantic-ui-react';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelToken } from '../../services/helpers';
import OrgSelectCreate from './OrgSelectCreate';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function ModalCreateOrg(props) {
    const { t } = useTranslation();
    const { getIdToken } = useAuth0();
    const [organizationSelected, setOrganizationSelected] = useState('');
    const [nameOrganization, setNameOrganization] = useState('');
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [loading, setLoading] = useState(null);
    const [messageModalConfirm, setMessageModalConfirm] = useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const classes = useStyles();

    const handleConfirm = async () => {
        setLoading(true);
        const dataOrg = {
            masterOrg: organizationSelected,
            orgName: nameOrganization,
        };

        helpers.createOrganizacion(dataOrg).then(response => {
            props.setDataToast({ open: true, type: "info", message: `${nameOrganization} organization created successfully`, timeAutoHide: 6000 });
            const token = getIdToken();
            const source = cancelToken.source();
            helpers.auth0.getUser(token, source)
                .then(props.reloadINF);
            props.setShowModalCreateOrg(false);
            setLoading(false);
        }).catch(response => {
            setLoading(false);
            props.setShowModalCreateOrg(false);
        });
    };

    const confirmCreateOrganization = () => {
        if (!organizationSelected || !nameOrganization) {
            setOpenAlert(true);
        }
        else {
            setShowModalConfirm(true);
            setMessageModalConfirm(`Are you sure you want to create the organization: ${nameOrganization} ?`)
        }
    }
    return (
        <React.Fragment>
            <Modal.Header>
                {t('modal_create_organization')}
            </Modal.Header>
            <Modal.Content>
                <div className={classes.root}>
                    <Collapse in={openAlert}>
                        <div>
                            <b style={{ color: "red" }}><ErrorIcon style={{ marginBottom: '-5px' }} />{t('modal_create_organization_error')}</b>
                        </div>
                    </Collapse>
                </div>
                <Dropdown
                    className="selection color-letter-dropdown-white"
                    text={organizationSelected || 'Select Organization'}
                    style={{ backgroundColor: '#fa4261', color: 'white' }}>
                    <Dropdown.Menu>
                        <OrgSelectCreate
                            onClick={evt => { evt.stopPropagation() }}
                            metaData={props.metaData}
                            currentOrg={props.currentOrg}
                            setCurrentOrg={props.setCurrentOrg}
                            style={{ backgroundColor: '#fa4261', color: 'white' }}
                            setOrganizationSelected={setOrganizationSelected}
                        />
                    </Dropdown.Menu>
                </Dropdown>
                <Input type="text" onChange={event => setNameOrganization(event.target.value)} value={nameOrganization} placeholder="Name Organization" style={{ marginLeft: 10 }} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => confirmCreateOrganization()} style={{ backgroundColor: '#fa4261', color: 'white' }}>
                    Create organization
                    </Button>
                <Button onClick={() => props.setShowModalCreateOrg(false)} style={{ backgroundColor: '#fa4261', color: 'white' }}>
                    Close
                    </Button>
            </Modal.Actions>
            <Confirm
                header={t('popup_header_confirm_status')}
                open={showModalConfirm}
                content={messageModalConfirm}
                cancelButton={t('popup_cancel')}
                confirmButton={t('popup_ok')}
                onOpen={() => setShowModalConfirm(true)}
                onCancel={() => setShowModalConfirm(false)}
                onConfirm={handleConfirm}
                size='tiny'
            />
            {(loading === true) ? (
                <Dimmer active={true} page>
                    <Loader size='huge'>{t('popup_loading')}</Loader>
                </Dimmer>
            ) : ""}
        </React.Fragment>
    );
}