import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Button , Confirm, Dimmer, Dropdown, Grid, Icon, Image, Loader, Modal, Placeholder, Popup, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import helpers, { cancelToken } from '../../services/helpers';
import ModalConfirm from '../common/ModalConfirm';
import ModalKYC from '../common/ModalKYC';
import DetailsLine from '../common/DetailsLine';
import { withRouter } from "react-router-dom";


const Avatar = styled.div`
  background-image: url(data:image/png;base64,${props => props.photo});
  background-size: cover;
  background-position: center center;
  width: 140px;
  height: 140px;
  display: inline-block;
  vertical-align: top;
`;

const Thumb = styled.div`
  background-image: url(data:image/png;base64,${props => props.photo});
  background-size: cover;
  background-position: center center;
  width: 140px;
  height: 140px;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const CenterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const KYC = styled.div`
  margin-top: 40px;
`;

const isJSON = str => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}
async function openImage(address, filename, message) {
    const newTab = window.open();
    newTab.document.body.innerHTML = message;
    await helpers.getDocument(address, filename).then((response) => {
        newTab.document.body.innerHTML = '';
        const img = document.createElement('img');
        img.src = `data:image/png;base64,${response.data.file}`;
        newTab.document.body.appendChild(img);
    });
}

const AgentDetails = (props) => {
    const data = props.data;
    const [photos, setPhotos] = useState({ photoRaw: null, documents: null });
    const [allowDegrade, setAllowDegrade] = useState(true);
    const { t } = useTranslation();
    const [showModalConfirm, setShowModalConfirm] = useState(false);

    const { profile,account, wallet, idv } = data;
    const activeOrg = window.ADMIN_SUB_ORGNAME;
    let reportKYC;
    if (isJSON(profile.watchlistReport)) {
        try {
            reportKYC = JSON.parse(profile.watchlistReport).breakdown;
        } catch (e) {
            reportKYC = false;
        }
    }
    const DocumentImages = (photos.documents || []).map(({ filename, thumbnail }) => {
        return (
            <Popup
                key={filename}
                trigger={
                    <Thumb style={{ cursor: 'pointer' }}
                        className='thumb'
                        key={filename}
                        photo={thumbnail}
                        onClick={() => openImage(profile.uuid, filename, t('popup_loading'))}
                    />
                }
                content={t('popup_open_in_new_window')}
                position='top center'
                inverted />
        );
    });
    const capitalize = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const statusIcon = status => {
        switch (status) {
            case 'consider':
                return (
                    <Icon color='yellow' name='exclamation triangle' style={{ 'marginRight': '10px' }} />
                )
            case 'complete':
            case 'clear':
                return (
                    <Icon color='green' name='check circle' style={{ 'marginRight': '10px' }} />
                )
            default:
                return (
                    <Icon color='blue' name='question circle' style={{ 'marginRight': '10px' }} />
                )

        }
    }
    const resolve = (response) => {
        const { photoThumbnail, documents } = response.data;
        setPhotos({ photoThumbnail, documents });
    };

    useEffect(() => {
        const source = cancelToken.source();
        const promise = helpers
            .getThumbnails(account.address, source)
            .then(resolve)
            .catch(console.error);

        return () => {
            helpers.cancel(promise, source);
        };
    }, []);

    const handleConfirmResolve = (response) => {
        return `${profile.firstName} ${profile.lastName} ${t('popup_downgraded_to_member')}`;
    };

    const handleConfirmResolveAgentRemovedFromOrg = (response) => {
        return `${profile.firstName} ${profile.lastName} has been removed from ${activeOrg}.`;
    };

    return (
        <>
            <Modal.Header>
                <span>{profile.firstName} {profile.lastName}</span>
            </Modal.Header>
            <Modal.Content scrolling>
                <Segment vertical style={{ paddingTop: 0 }}>
                    {photos.photoThumbnail ? (
                        <Avatar photo={photos.photoThumbnail} />
                    ) : (
                            <Placeholder style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }} >
                                <Placeholder.Image />
                            </Placeholder>
                        )}

                    <div style={{ display: 'inline-block' }}>
                        <Segment basic compact>
                            <DetailsLine><span>{t('agent_details_created_at')}</span><strong>{moment(profile.createdAt).utc().format('L LTS')}</strong></DetailsLine>
                            <DetailsLine><span>{t('agent_details_ew_address')}</span><strong>{account.address}</strong></DetailsLine>
                            <DetailsLine><span>E-mail</span><strong>{profile.email || 'N/A'}</strong></DetailsLine>
                        </Segment>
                    </div>
                </Segment>

                {allowDegrade === true ? (
                    <Segment vertical>
                        <CenterButtons>
                            <ModalConfirm
                                trigger={<Button negative>{t('agent_details_degrade')}</Button>}
                                onAction={() => helpers.degrade(account.address)}
                                onResolve={handleConfirmResolve}
                                onClose={() => {
                                    setAllowDegrade(false);
                                    props.history.push("/members");
                                }}
                            />
                            {!!activeOrg && activeOrg !== 'INF' &&
                                <ModalConfirm
                                    trigger={<Button>Remove from {activeOrg}</Button>}
                                onAction={() => helpers.removeAgentFromOrg(account.address)}
                                    onResolve={handleConfirmResolveAgentRemovedFromOrg}
                                    onClose={() => setAllowDegrade(false)}
                                />
                            }
                        </CenterButtons>
                    </Segment>
                ) : ('')}

                <Segment vertical>
                    <DetailsLine width={154}><span>{t('agent_details_address')}</span><strong>{profile.address || '-'}</strong></DetailsLine>
                    <DetailsLine width={154}><span>{t('agent_details_phone_number')}</span><strong>{profile.countryCode && `(+${profile.countryCode})`} {profile.phoneNumber || 'N/A'}</strong></DetailsLine>
                </Segment>

                <Segment vertical>
                    <DetailsLine width={154}><span>{t('agent_details_sex')}</span><strong>{profile.gender}</strong></DetailsLine>
                    <DetailsLine width={154}><span>{t('agent_details_birth_date')}</span><strong>{moment(profile.birthDate.substr(0, 10)).format('L')}</strong></DetailsLine>
                    <DetailsLine width={154}><span>{t('agent_details_nationality')}</span><strong>{profile.nationality}</strong></DetailsLine>
                </Segment>

                <Segment vertical>
                    <p><strong>{t('agent_details_documents')}</strong></p>
                    <DetailsLine width={234}><span>{t('idv_type')}</span><strong>{profile.documentType || 'N/A'}</strong></DetailsLine>
                    <DetailsLine width={234}><span>{t('idv_exp_date')}</span><strong>{(profile.documentDateOfExpiry && moment(profile.documentDateOfExpiry, "DD.MM.YYYY").format('L')) || '-'}</strong></DetailsLine>
                    {(DocumentImages.length > 0) ? (
                        <Image.Group size='small'>
                            {DocumentImages}
                        </Image.Group>
                    ) : (
                            <Placeholder style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }} >
                                <Placeholder.Image />
                            </Placeholder>
                        )}
                </Segment>
                <Segment basic>
                    <p><strong>{t('member_details_dataKYC')}</strong></p>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column floated='left'>
                                <DetailsLine><span>{t('kyc_status_identity')}</span></DetailsLine>
                                <DetailsLine><span>{t('kyc_status_facial')}</span></DetailsLine>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                <DetailsLine><strong>{statusIcon(idv.idvStatusIdentity || 'N/A')} {capitalize(idv.idvStatusIdentity || 'N/A')} </strong></DetailsLine>
                                <DetailsLine><strong>{statusIcon(idv.idvStatusFacial || 'N/A')} {capitalize(idv.idvStatusFacial || 'N/A')} </strong></DetailsLine>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

                <Segment basic>
                <p><strong>{t('kyc_status')}</strong>{!!reportKYC ? statusIcon('consider') : statusIcon('clear')}</p>
                {!!reportKYC ? (
                        <KYC>
                            <DetailsLine><span>Adverse Media:</span><strong>{statusIcon(reportKYC.adverse_media.result || 'N/A')}<span>{capitalize(reportKYC.adverse_media.result || 'N/A')}</span></strong></DetailsLine>
                            <DetailsLine><span>Sanction Lists:</span><strong>{statusIcon(reportKYC.sanction.result || 'N/A')}<span>{capitalize(reportKYC.sanction.result || 'N/A')}</span></strong></DetailsLine>
                            <DetailsLine><span>Politically Exposed Person:</span><strong>{statusIcon(reportKYC.politically_exposed_person.result || 'N/A')}<span>{capitalize(reportKYC.politically_exposed_person.result || 'N/A')}</span></strong></DetailsLine>
                        </KYC>
                    ) : <KYC><pre><a href={profile.watchlistReport}>{profile.watchlistReport}</a></pre></KYC>}
                </Segment>
              
            </Modal.Content>
        </>
    );
};

export default withRouter(AgentDetails);
