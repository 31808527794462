import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { useAuth0 } from './react-auth0-wrapper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container, Button, Loader } from 'semantic-ui-react';

import MembersProvider from './components/members/MembersProvider';
import AgentsProvider from './components/agents/AgentsProvider';
import TransactionsProvider from './components/transactions/TransactionsProvider';
import ClTransactionsProvider from './components/centralLedgerTransactions/TransactionsProvider';
import ReportsProvider from './components/reports/ReportsProvider';
import RefCodesProvider from './components/referralCodes/RefCodesProvider';
import OrgProfile from './components/orgProfile';
import OrgProfileList from './components/orgProfileList';
import Partners from './components/partners/Provider';
import Dashboard from './components/dashboard';

import KinaBank from './components/kinabank/KinaBank';

import ErrorMessage from './components/common/ErrorMessage';
import Login from './components/common/Login';
import NavBar from './components/common/NavBar';

import helpers, { cancelToken } from './services/helpers';
import OrgAdmin from './components/admin/OrgAdmin';
import Footer from './components/common/Footer';
import { Function } from 'core-js';
import './App.css';

const App = () => {
    const { t } = useTranslation();
    const { isAuthenticated, logout, getIdToken } = useAuth0();
    const [metaData, setMetaData] = useState(null);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [auth0Error, setAuth0Error] = useState(null);
    const [updateOrg, setUpdateOrg] = useState(null);
    const [nameTitleModule, setNameTitleModule] = useState(null);
    const [dataToast, setDataToast] = useState({ open: false, type: '', message: '', timeAutoHide: 0 })

    function clearMetaData() {
        setMetaData(null);
    }
    const removeUnusedOrgs = (app_metadata) => {
        let subtree = app_metadata.organizations_tree;
        let newtree = {};
        let keysArray = Object.keys(subtree);
        console.log("keysArr", keysArray)
        keysArray.forEach((key) => {
            let suborgs = subtree[key];
            console.log(key, suborgs);
            let newsuborgs = []; 
            if (suborgs) {
                newsuborgs = suborgs.filter(o => ['BANK OF AMERICA','ENA','SCB','IMEX','MTO_AUS', 'MTO_AUS2', 'MTO_AUS4', 'MTO_WSM', 'MTO_WSM2', 'MTO_WSM3', 'ENM'].indexOf(o) >= 0); //'ALLIANZ-LKA',
                if (newsuborgs.length > 0) {
                    if (newsuborgs[0] === 'ALLIANZ-LKA'){
                        newtree[key] = ['ALLIANZ','ALLIANZ-LKA'];
                    }else{
                        newtree[key] = newsuborgs;
                    }
                }
            }
        });
        app_metadata.organizations_tree = newtree;
        return app_metadata;
    }

    function resolveUser(response) {
        console.log('response data:',response.data);
        const { app_metadata } = response.data.user;
        if (app_metadata.role_name === 'SUB_ORGANIZATION_ADMIN') {
            window.ADMIN_SUB_ORGNAME = app_metadata.sub_org_name;
            setCurrentOrg(app_metadata.sub_org_name);
        } else if(app_metadata.role_name === 'PARTNER_ADMIN') {
            setCurrentOrg(app_metadata.sub_org_name);
        }
        setMetaData(removeUnusedOrgs(app_metadata));
    }

    function rejectUser(error) {
        console.log('rejectUser:',error);
        setAuth0Error(error);
    }

    const reloadINF = (response) => {
        const { app_metadata } = response.data.user;
        setMetaData(removeUnusedOrgs(app_metadata));
    }

    useEffect(() => {
        if (!isAuthenticated) {
            delete window.ADMIN_SUB_ORGNAME;
            clearMetaData();
            return () => { };
        }

        const token = getIdToken();
        const source = cancelToken.source();
        console.log('token:',token);
        const promise = helpers.auth0.getUser(token, source)
            .then(resolveUser)
            .catch(rejectUser);

        return () => {
            helpers.cancel(promise, source);
        };
    }, [isAuthenticated]);

    useEffect(() => {
        window.ADMIN_SUB_ORGNAME = currentOrg;
        setUpdateOrg(new Date())
    }, [currentOrg]);


    if (auth0Error) {
        console.log('auth0 Error:',auth0Error)
        return (
            <CenterContainer>
                <ErrorMessage error={auth0Error} />
                <Login />
            </CenterContainer>
        );
    }

    if (isAuthenticated && !metaData) {
        return (
            <CenterContainer>
                <Loader active size='large' inline='centered'>{t`popup_loading`}</Loader>
            </CenterContainer>
        );
    }

    if (!isAuthenticated) {
        return <Login />;
    }

    return (
            <div>
                <NavBar
                    metaData={metaData}
                    currentOrg={currentOrg}
                    setCurrentOrg={setCurrentOrg}
                    nameTitleModule={nameTitleModule}
                    setNameTitleModule={setNameTitleModule}
                    dataToast={dataToast}
                    setDataToast={setDataToast}
                    reloadINF={reloadINF} />
                <Container>
                        <Route path='/referralCodes' render={() => <RefCodesProvider setNameTitleModule={setNameTitleModule} setDataToast={setDataToast} />} />
                        <Route path='/org-profile' render={() => <OrgProfile currentOrg={currentOrg} setNameTitleModule={setNameTitleModule} />} />
                        <Route path='/org-profile-list' render={() => <OrgProfileList setDataToast={setDataToast} />} />
                        {currentOrg === metaData.org_name && metaData.role_name === 'SUPER_ADMIN' && (
                            <>
                                <Route exact path='/' render={() => <Redirect to='/admin' />} />
                                <Route path="/admin" render={() => <OrgAdmin metaData={metaData} currentOrg={currentOrg} setCurrentOrg={setCurrentOrg} nameTitleModule={nameTitleModule} setNameTitleModule={setNameTitleModule} />} />
                                <Route path='/reports' component={ReportsProvider} />
                                <Route path='/agents' render={() => <AgentsProvider setNameTitleModule={setNameTitleModule} />} />
                                <Route path='/members' render={() => <MembersProvider
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    currentOrg={currentOrg}
                                    setNameTitleModule={setNameTitleModule}
                                    setCurrentOrg={setCurrentOrg}
                                    dataToast={dataToast}
                                    setDataToast={setDataToast} />} />
                                <Route path='/transactions' render={() => <TransactionsProvider
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    dataToast={dataToast}
                                    currentOrg={currentOrg}
                                    setCurrentOrg={setCurrentOrg}
                                    setDataToast={setDataToast} />} />
                                <Route path='/cl-transactions' render={() => <ClTransactionsProvider
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    dataToast={dataToast}
                                    currentOrg={currentOrg}
                                    setCurrentOrg={setCurrentOrg}
                                    setDataToast={setDataToast} />} />
                                <Route path='/partners' render={() => <Partners
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    currentOrg={currentOrg}
                                    setNameTitleModule={setNameTitleModule}
                                    setCurrentOrg={setCurrentOrg}
                                    dataToast={dataToast}
                                    setDataToast={setDataToast} />} />
                                {/* <Route path='/kinabank' render={() => <KinaBank setDataToast={setDataToast} />} /> */}

                            </>
                        )}
                        {currentOrg && currentOrg !== metaData.org_name && (
                            <>
                                <Route exact path='/' render={() => <Redirect to={'/agents'} />} />
                                <Route path='/admin' render={() => <Redirect to={'/agents'} />} />
                                <Route path='/reports' render={() => <ReportsProvider metaData={metaData} updateOrg={updateOrg} />} />
                                <Route path='/agents' render={() => <AgentsProvider metaData={metaData} updateOrg={updateOrg} setNameTitleModule={setNameTitleModule} />} />
                                <Route path='/members' render={() => <MembersProvider
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    currentOrg={currentOrg}
                                    setCurrentOrg={setCurrentOrg}
                                    setNameTitleModule={setNameTitleModule}
                                    dataToast={dataToast}
                                    setDataToast={setDataToast} />} />
                                <Route path='/transactions' render={() => <TransactionsProvider
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    dataToast={dataToast}
                                    currentOrg={currentOrg}
                                    setCurrentOrg={setCurrentOrg}
                                    setDataToast={setDataToast} />} />
                                <Route path='/cl-transactions' render={() => <ClTransactionsProvider
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    dataToast={dataToast}
                                    currentOrg={currentOrg}
                                    setCurrentOrg={setCurrentOrg}
                                    setDataToast={setDataToast} />} />
                                {/* <Route path='/kinabank' render={() => <KinaBank setDataToast={setDataToast} />} /> */}

                            </>
                        )}
                        {metaData.role_name === 'PARTNER_ADMIN' && (
                            <>
                                <Route exact path='/' render={() => <Redirect to={'/dashboard'} />} />
                                <Route path='/admin' render={() => <Redirect to={'/dashboard'} />} />
                                <Route exact path='/members' render={() => <Redirect to={'/dashboard'} />} />
                                <Route path='/transactions' render={() => <ClTransactionsProvider
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    dataToast={dataToast}
                                    currentOrg={currentOrg}
                                    setCurrentOrg={setCurrentOrg}
                                    setNameTitleModule={setNameTitleModule}
                                    setDataToast={setDataToast} />} />
                                <Route path='/dashboard' render={() => <Dashboard
                                    metaData={metaData}
                                    updateOrg={updateOrg}
                                    dataToast={dataToast}
                                    currentOrg={currentOrg}
                                    setCurrentOrg={setCurrentOrg}
                                    setNameTitleModule={setNameTitleModule}
                                    setDataToast={setDataToast} />} />

                            </>
                        )}
                        {metaData.role_name !== 'SUB_ORGANIZATION_ADMIN' && !currentOrg && (
                            <>
                                <Route exact path="/" render={() => <Redirect to='/admin' />} />
                                <Route path='/agents' render={() => <Redirect to='/admin' />} />
                                <Route path='/reports' render={() => <Redirect to='/admin' />} />
                                <Route path='/members' render={() => <Redirect to='/admin' />} />
                                <Route path='/transactions' render={() => <Redirect to='/admin' />} />
                                <Route path="/admin" render={() => <OrgAdmin metaData={metaData} currentOrg={currentOrg} setCurrentOrg={setCurrentOrg} nameTitleModule={nameTitleModule} setNameTitleModule={setNameTitleModule} />} />
                                {/* <Route path='/kinabank' render={() => <KinaBank setDataToast={setDataToast} />} /> */}
                            </>
                        )}
                </Container>
                <div>
                    <Footer />
                </div>
            </div>
    );
};

const CenterContainer = styled.div`
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `;

export default App;
