import React  from 'react';
import {Box, Typography, Button} from "@material-ui/core";
import NavBar from "./navBar"

const Terms = ({setShowScreen}) => {

    const typographyVariant = "body1";

    return (
        <>
            <NavBar title={"Terms of service"} backHandler={() => setShowScreen('intro')} setShowScreen={setShowScreen} />
            <Box className="wizard-box">
                <Box className="terms-and-conditions">
                    <Box className="info-text">
                        <Typography variant={typographyVariant}>Last Revised: 22 February 2022</Typography>

                        <Typography variant={typographyVariant}>Everest Network Limited is a blockchain application platform for the secure storage and verification of identification information and personal data as well as the tracking and recording of transactions. Everest is a duly licensed Class 2 Virtual Financial Service Provider under the Malta Virtual Financial Assets Act (Chapter 590 of the laws of Malta).</Typography>

                        <Typography variant={typographyVariant}>These Terms and Conditions (the “T&Cs” or “the “Terms”) constitute a legally binding agreement between you (“user”), whether personally or on behalf of an entity, and Everest (“Everest”, “the Company”, “us”, “we”, “our”) collectively referred to as the Parties to this agreement.</Typography>

                        <Typography variant={typographyVariant}>These T&Cs govern your use of applications, tools, services, products, content, technologies, processes and information offered by Everest, in its sole discretion, including, without limitation, the blockchain platform, web sites (including any subdomains thereof), mobile applications, mobile-web applications, biometric input, scanning and verification technologies, message boards, forums, web logs, chat rooms, personal chat, video chat, email, videos, articles, search engines and advertisements, (the “Applications”) owned by us and/or by our affiliates, including, but not limited to, our licensees, licensors, service providers, board members, employees, agents, advisors, contractors, subsidiaries, assignees, transferees, purchasers, partners, joint venturers, collaborators, advertisers, sponsors, marketing partners, writers and content providers (“Affiliates”) in relation to Everest. The Applications owned by Everest, are made available by Everest.</Typography>

                        <Typography variant={typographyVariant}>By accessing, using or clicking “I agree” to any of the services made available by Everest or one of its affiliates through the website <a href="https://www.everest.org/" target="_blank">https://www.everest.org/</a> , our mobile applications, or any other related services provided by the Company or its Affiliates you agree that you have read, understood, accepted and agree to be bound by these T&Cs, as well as our Privacy Policy found at https://www.everest.org/privacy-policy/. IF YOU DO NOT AGREE TO THESE TERMS, YOU SHALL NOT ACCESS OR USE OUR SERVICES AND APPLICATIONS.</Typography>

                        <Typography variant={typographyVariant}>Everest reserves the right to modify or change these Terms at any time and at its sole discretion. We will provide a notice of these changes by updating the revised Terms on our webpage <a href="https://www.everest.org/terms-of-use" target="_blank">https://www.everest.org/terms-of-use</a> and changing the “[Last revised]” date on this page. All modifications or changes to these T&Cs will be effective immediately upon being announced on our website or released to users. If you do not agree to any modification to these T&Cs, you must stop using our services, otherwise, your continued use will be deemed as acceptance to the amendments. We encourage you to frequently review these Terms to ensure you understand the terms and conditions that apply to your access to, and use of, the services.</Typography>

                        <Typography variant={typographyVariant}><strong>1. General</strong></Typography>

                        <Typography variant={typographyVariant}>1.1 Please read these Terms carefully as they govern your use of the services. As with any asset, the value of digital currencies can go up or down, and there can be substantial risk that you will lose money buying, selling, holding, or investing in digital currencies. By using the services, you acknowledge and agree that</Typography>

                        <Typography variant={typographyVariant}>a. You are aware of these risks associated with transaction in digital currencies;</Typography>

                        <Typography variant={typographyVariant}>b. That you assume all risks with respect to your use of the services and trading in digital currencies; and</Typography>

                        <Typography variant={typographyVariant}>c. Everest is not responsible or liable for any such risks or adverse outcomes.</Typography>

                        <Typography variant={typographyVariant}><strong>2. Eligibility</strong></Typography>

                        <Typography variant={typographyVariant}>1.2 By registering to use an Everest Account, you represent and warrant that you:</Typography>

                        <Typography variant={typographyVariant}>a. are at least 18 years old or of legal age to form a binding contract under applicable law;</Typography>

                        <Typography variant={typographyVariant}>b. are an individual, legal person or other organization with full legal capacity and authority to enter into these Terms;</Typography>

                        <Typography variant={typographyVariant}>c. have not previously been suspended or removed from using our services; and</Typography>

                        <Typography variant={typographyVariant}>d. you do not currently have an existing Everest Account. If you are entering into these Terms on behalf of a legal entity of which you are an employee or agent, you represent and warrant that you have all necessary rights and authority to bind such legal entity.</Typography>

                        <Typography variant={typographyVariant}><strong>3. Registration</strong></Typography>

                        <Typography variant={typographyVariant}>3.1 In order to access certain features of our Applications, we may ask you to provide certain demographic information including, but not limited to, your full name, gender, year of birth, place of birth, physical address, email, telephone number, proof of age, fingerprints, facial scan, retinal scan and other personal information to help you in obtaining a secure and verifiable digital identification (“Private Information”). </Typography>

                        <Typography variant={typographyVariant}>3.2 You agree to provide true, accurate, current and complete information about yourself as prompted by our Applications, and any applicable registration and information input forms. </Typography>

                        <Typography variant={typographyVariant}>3.3 If there is any reasonable doubt that any information provided by you inaccurate, untruthful, outdated or incomplete, Everest shall have the right to send you a notice to demand corrections, remove relevant information directly and, as the case may be, suspend or terminate your account and refuse any and all current or future use of our Applications (or any portion thereof). </Typography>

                        <Typography variant={typographyVariant}>3.4 You shall be solely and fully responsible for any loss or expenses incurred during the use of our Applications, if you cannot be reached through the contact information provided. You hereby acknowledge and agree that you have the obligation to keep all information provided up to date if there are any changes.</Typography>

                        <Typography variant={typographyVariant}>3.5 With registration of an account, you agree to share personal information requested for the purposes of identity verification. This information is used specifically for the detection of money laundering, terrorist financing, fraud and other financial crimes on the Everest platform. We will collect, use and share this information in accordance with our <a href="https://www.everest.org/privacy-policy/" target="_blank">Privacy Policy</a>. </Typography>

                        <Typography variant={typographyVariant}>3.6 In addition to providing this information, to facilitate compliance with global industry standards for data retention, you agree to permit us to keep a record of such information for the lifetime of your account plus 5 years beyond account closing. </Typography>

                        <Typography variant={typographyVariant}>3.7 In accordance with our <a href="https://www.everest.org/compliance" target="_blank">Anti-Money Laundering and Counter Terrorist Financing Policy</a>, you also authorise us to make inquiries, either directly or through third parties, that are deemed necessary to verify your identity or to protect you and/or us against financial crimes such as fraud.</Typography>

                        <Typography variant={typographyVariant}><strong>4. Account Usage Requirements</strong></Typography>

                        <Typography variant={typographyVariant}>4.1 Accounts can only be used by the person whose name they are registered under. </Typography>

                        <Typography variant={typographyVariant}>4.2 Everest reserves the right to suspend, freeze or cancel accounts that are used by persons other than the persons whose names they are registered under. </Typography>

                        <Typography variant={typographyVariant}>4.3 You shall immediately notify us if you suspect or become aware of unauthorized use of your username and password. Everest will not be liable for any loss or damage arising from any use of your account by you or by any third party (whether or not authorized by you).</Typography>

                        <Typography variant={typographyVariant}><strong>5. Account Security</strong></Typography>

                        <Typography variant={typographyVariant}>5.1 You agree to consider your access credentials such as username, password and/or numeric PIN, which you may receive through the registration process, as confidential information and not to disclose such information to any third party. You also agree that you alone are responsible for taking necessary safety precautions to protect your own account and personal information.</Typography>

                        <Typography variant={typographyVariant}>5.2 You shall be solely responsible for the safekeeping of your Everest account username, password and/or numeric PIN on your own, and you shall be responsible for all activities under account, and we shall not be responsible for any loss or consequences of unauthorised use of your account credentials.</Typography>

                        <Typography variant={typographyVariant}>5.3 By creating an account, you hereby agree that: </Typography>

                        <Typography variant={typographyVariant}>a. you will notify Everest immediately if you are aware of any unauthorised use of your Everest account and password by any person or any other violations to the security rules;</Typography>

                        <Typography variant={typographyVariant}>b. you will log out from the website by taking proper steps at the end of every visit.</Typography>

                        <Typography variant={typographyVariant}><strong>6. Prohibited User Conduct</strong></Typography>

                        <Typography variant={typographyVariant}>6.1 You warrant and agree that, while using the Applications and the various services and features offered on or through the Applications, you shall not: </Typography>

                        <Typography variant={typographyVariant}> a. impersonate any person or entity or misrepresent your affiliation with any other person or entity; </Typography>

                        <Typography variant={typographyVariant}> b. insert your own or a third party’s advertising, branding or other promotional content into any of the Application’s content, materials or services (for example, without limitation, in an RSS feed or a podcast received from Everest or otherwise through the Applications), or use, redistribute, republish or exploit such content or service for any further commercial or promotional purposes, or to make donations of any kind; </Typography>

                        <Typography variant={typographyVariant}>c. obtain or attempt to gain unauthorized access to other computer systems, materials or information through any means not intentionally made publicly available or provided for through the Applications;</Typography>

                        <Typography variant={typographyVariant}>d. engage in spidering, “screen scraping,” “database scraping,” harvesting of e-mail addresses, wireless addresses or other contact or personal information, or any other automatic means of obtaining lists of users or other information from or through the Applications or the services offered on or through the Applications, including without limitation any information residing on any server or database connected to the Applications or the services offered on or through the Applications;</Typography>

                        <Typography variant={typographyVariant}>e. use the Applications or the services made available on or through the Applications in any manner with the intent to interrupt, damage, disable, overburden, or impair the Applications or such services, including, without limitation, sending mass unsolicited messages or “flooding” servers with requests or interfere with any other party’s use and enjoyment of the Applications or any of its services;</Typography>

                        <Typography variant={typographyVariant}>f. use the Applications or the Applications’ services or features in violation of Everest’s or any third party’s intellectual property or other proprietary or legal rights; </Typography>

                        <Typography variant={typographyVariant}>g. use the Applications or the Applications’ services in violation of any applicable law;</Typography>

                        <Typography variant={typographyVariant}>h. use our Applications to post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, pornographic, profane or indecent information of any kind, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, national or international law.</Typography>

                        <Typography variant={typographyVariant}>6.2 You further agree that you shall not attempt (or encourage or support anyone else’s attempt) to circumvent, reverse engineer, decrypt, or otherwise alter or interfere with the Applications or the Applications’ services, or any content thereof, or make any unauthorized use thereof. </Typography>

                        <Typography variant={typographyVariant}><strong>7. Orders for Products, Goods and Services </strong></Typography>

                        <Typography variant={typographyVariant}>7.1 We may make certain products, goods, and services available to visitors and registrants of the Applications. You agree to pay in full the prices for any purchases you make either by credit/debit card concurrent with your online order or by other payment means acceptable to Everest. You agree to pay all applicable taxes. </Typography>

                        <Typography variant={typographyVariant}>7.2 If payment is not received by us from your financial institution of choice, or any of its agents, you agree to pay all amounts due upon demand by us. </Typography>

                        <Typography variant={typographyVariant}>7.3 Certain products that you purchase and/or download on or through the Applications may be subject to additional terms and conditions presented to you at the time of such purchase or download.</Typography>

                        <Typography variant={typographyVariant}><strong>8. Authorization for Charges and Recurring Payments</strong></Typography>

                        <Typography variant={typographyVariant}>8.1 You are hereby voluntarily subscribing and/or paying for goods, products and/or services of Everest and are hereby authorizing us to charge your bank account as inputted by you, in the amount and for the term (e.g., one time, weekly, monthly or annually) you agreed to. At the end of each term, you authorize the recurring subscription or continuity payments on your credit card in the amount you authorized on your subscription purchase form. </Typography>

                        <Typography variant={typographyVariant}>8.2 We may change the fees (provided in Section 11 of these Terms) in effect at our discretion from time to time but will give you advance notice of these changes via a text message to the mobile number associated with your account prior to renewal.</Typography>

                        <Typography variant={typographyVariant}><strong>9. Payment Methods and Billing Cycle</strong></Typography>

                        <Typography variant={typographyVariant}>9.1 In the event that you decide to purchase a membership, goods or services on a recurring billing cycle on Everest Applications or third-party Applications linked to, or sold through, Everest, you will be billed on the agreed billing cycle in the agreed amount. </Typography>

                        <Typography variant={typographyVariant}><strong>10. Updating Billing Information</strong></Typography>

                        <Typography variant={typographyVariant}>10.1 In case you want to add, edit, or delete payment information for your purchases, you should contact us at <a href="mailto:contact@everest.org">contact@Everest.org</a></Typography>

                        <Typography variant={typographyVariant}><strong>11. Fees</strong></Typography>

                        <Typography variant={typographyVariant}>11.1 You agree to pay Everest the fees available through the following link <a href="https://everest.org/fees/" target="_blank">https://everest.org/fees/</a> which may be updated from time to time in Everest’s sole discretion. Any such updated fees will apply prospectively to any transactions that take place following the effective date of such updated fees. You authorize us to remove any amounts from your account for any applicable fees owed by you under these T&Cs.</Typography>

                        <Typography variant={typographyVariant}><strong>12. Free Trials and Introductory Offers </strong></Typography>

                        <Typography variant={typographyVariant}>12.1 If you subscribed to receive goods or services on a free trial or introductory offer basis, you authorize your credit or debit card to be charged at the end of the free trial or introductory term pursuant to the Terms, conditions and price of the offer. The card you used to sign up for a free trial or introductory unless you cancel your subscription one day prior to the auto-renewal.</Typography>

                        <Typography variant={typographyVariant}><strong>13. Unrecognized Charges</strong></Typography>

                        <Typography variant={typographyVariant}>13.1 In case of unrecognized charges on your credit or debit card executed by Everest, you should follow the steps provided below: </Typography>

                        <Typography variant={typographyVariant}>a. Review past charges and print receipts or invoices from your billing history page;</Typography>

                        <Typography variant={typographyVariant}>b. After reviewing your subscription and payment information, if you feel that you have unrecognized charges, please contact us at support@everest.org and provide us with your name, address, phone number, account number, date of transaction, amount of transaction, credit or debit card number and transaction number and we will investigate your claim accordingly.</Typography>

                        <Typography variant={typographyVariant}>13.2 While we do not offer refunds, should you believe that your situation warrants an exception, and our review is in agreement with your assessment, we will credit back the charges in dispute.</Typography>

                        <Typography variant={typographyVariant}><strong>14. Cancellation</strong></Typography>

                        <Typography variant={typographyVariant}>14.1 If you sign up for a free trial and cancel, you won’t be eligible to sign up for another free trial for at least 12 months.</Typography>

                        <Typography variant={typographyVariant}>14.2 To cancel your Premium subscription contact contact@Everest.org with the word “Cancel” in the subject line.</Typography>

                        <Typography variant={typographyVariant}><strong>15. Turning off auto-renewal</strong></Typography>

                        <Typography variant={typographyVariant}>15.1 If you currently have a paid subscription and you want to make sure you’re not charged at the end of your term, you’ll need to turn off your auto-renewal. To do this, please visit your billing information page and send an email to contact@Everest.org with the word “Cancel” in the subject line and in the body, provide description of the auto-renewal charge, the transaction number, the date and the amount.</Typography>

                        <Typography variant={typographyVariant}><strong>16. Assignment of Rights and Severability </strong></Typography>

                        <Typography variant={typographyVariant}>16.1 You shall not assign any of your rights under these T&Cs, and any such attempt will be void. </Typography>

                        <Typography variant={typographyVariant}>16.2 We may assign our rights to any of our Affiliates or subsidiaries, or to any successor in interest of any business associated with the services and Applications.</Typography>

                        <Typography variant={typographyVariant}>16.3 In case any provision in these Terms shall be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby and such provision shall be ineffective only to the extent of such invalidity, illegality or unenforceability.</Typography>

                        <Typography variant={typographyVariant}><strong>17. Intellectual Property Rights </strong></Typography>

                        <Typography variant={typographyVariant}>17.1 As between you and Everest, Everest owns, solely and exclusively, all rights, title and interest in and to the Applications, including without limitation all the content (including, for example, audio, photographs, illustrations, graphics, other visuals, video, copy, text, software, titles, Shockwave files, etc.), code, data, processes, algorithms, and materials thereon, the look and feel, design and organization of the Applications, and the compilation of the content, code, data and materials on the Applications, including but not limited to any copyrights, trademark rights, patent rights, database rights, moral rights, sui generis rights and other intellectual property and proprietary rights therein. </Typography>

                        <Typography variant={typographyVariant}>17.2 Your use of the Applications does not grant to you any ownership, rights and/or interest in any Applications, Content, Publicly Submitted Materials or Intellectual Property of Everest. </Typography>

                        <Typography variant={typographyVariant}>17.3 Unless you have prior written permission from Everest’s authorized personnel, any commercial or promotional distribution, publishing or exploitation of the Applications or any Content is strictly prohibited. </Typography>

                        <Typography variant={typographyVariant}><strong>18. Trademarks </strong></Typography>

                        <Typography variant={typographyVariant}>18.1 The trademarks, logos, service marks and trade names (collectively the “Trademarks”) displayed on the Applications or on content available through the Applications are registered and unregistered Trademarks of Everest and others and may not be used in connection with products and/or services that are not related to, associated with, or sponsored by their rights holders that are likely to cause customer confusion, or in any manner that disparages or discredits their rights holders. </Typography>

                        <Typography variant={typographyVariant}>18.2 All Trademarks not owned by Everest that appear on the Applications or on or through the Applications’ services, if any, are the property of their respective owners. Nothing contained on the Applications should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on the Applications without the written permission of Everest or the third party that may own the applicable Trademark. </Typography>

                        <Typography variant={typographyVariant}>18.3 Your misuse of the Trademarks displayed on the Applications or on or through any of the Applications’ services is strictly prohibited.</Typography>

                        <Typography variant={typographyVariant}><strong>19. Linking and Framing</strong></Typography>

                        <Typography variant={typographyVariant}>19.1 Everest shall not be responsible for the content, policies or services of any other persons or websites linked to or accessible via our website. </Typography>

                        <Typography variant={typographyVariant}>19.2 The existence of any link to any other website does not constitute an endorsement of, or association with any such website or any person operating any such website. Any reliance on any content, policies or services of any other persons or websites are at your sole risk. </Typography>

                        <Typography variant={typographyVariant}>19.3 Any queries, concerns or complaints concerning such websites should be directed to the persons responsible for their operation.</Typography>

                        <Typography variant={typographyVariant}><strong>20. Disclaimer of Warranties</strong></Typography>

                        <Typography variant={typographyVariant}>20.1 TO THE EXTENT THAT WE OWN OR CONTROL THE CONTENT, WE TRY TO ENSURE, WITHOUT ANY OBLIGATION OR GUARANTEE ON OUR PART, THAT THE CONTENT POSTED ON THE APPLICATIONS IS CORRECT AND UP-TO-DATE. WE RESERVE THE RIGHT TO CHANGE OR MAKE CORRECTIONS TO ANY OF THE CONTENT PROVIDED ON THE APPLICATIONS AT ANY TIME AND WITHOUT ANY PRIOR WARNING. WE NEITHER ENDORSE NOR ARE RESPONSIBLE FOR THE ACCURACY OR RELIABILITY OF ANY CONTENT OR PUBLICLY SUBMITTED MATERIALS, OPINIONS, ADVICE OR STATEMENTS ON THE APPLICATIONS, NOR FOR ANY OFFENSIVE, DEFAMATORY, OBSCENE, INDECENT, UNLAWFUL, OR INFRINGING CONTENT OR STATEMENTS MADE THEREON BY ANYONE OTHER THAN OUR AUTHORIZED EMPLOYEES AND SPOKESPERSONS WHILE ACTING IN THEIR OFFICIAL CAPACITIES PURSUANT TO OUR POLICIES, PROCEDURES AND STANDARDS. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY CONTENT, PUBLICLY SUBMITTED MATERIALS, INFORMATION, OPINIONS OR ADVICE AVAILABLE THROUGH THE APPLICATIONS. YOU ARE ADVISED BY US, AND YOU AGREE TO, SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE, REGARDING THE EVALUATION OF ANY SPECIFIC CONTENT, PUBLICLY SUBMITTED MATERIALS, INFORMATION, OPINION OR ADVICE INCLUDING BUT NOT LIMITED TO THOSE RELATED TO YOUR HEALTH, FINANCIAL, PERSONAL AND/OR LIFESTYLE CONCERNS.</Typography>

                        <Typography variant={typographyVariant}>20.2 PRIOR TO THE EXECUTION OF A PURCHASE OR SALE OF ANY SECURITY OR INVESTMENT, YOU ARE ADVISED TO CONSULT WITH YOUR BROKER OR OTHER FINANCIAL ADVISOR TO VERIFY PRICING AND OTHER INFORMATION. WE SHALL HAVE NO LIABILITY FOR INVESTMENT DECISIONS BASED UPON, OR THE RESULTS OBTAINED FROM, THE CONTENT PROVIDED HEREIN. NOTHING CONTAINED IN THE APPLICATIONS SHALL BE CONSTRUED AS INVESTMENT ADVICE. WE ARE NOT A REGISTERED BROKER-DEALER OR INVESTMENT ADVISOR AND DOES NOT GIVE INVESTMENT ADVICE OR RECOMMEND ONE PRODUCT OVER ANOTHER.</Typography>

                        <Typography variant={typographyVariant}>20.3 WE CANNOT AND DO NOT GUARANTEE ANY CHANGE, POSITIVE OR OTHERWISE, IN YOUR FINANCIAL STATUS. WE DO NOT CONTROL ANY OF THE ENTITIES WE WORK WITH OTHER THAN THOSE THAT WE OWN AND OPERATE. SHOULD THE SERVICE, IN ALL OF ITS FORMS PROVIDED BY EVEREST, PROVE DEFECTIVE AND/OR CAUSE ANY DAMAGE TO YOUR COMPUTER OR INCONVENIENCE TO YOU; YOU, AND NOT EVEREST, SHALL ASSUME THE ENTIRE COST AND ALL DAMAGES WHICH MAY RESULT DIRECTLY AND INDIRECTLY FROM ANY AND ALL SUCH DEFECTS</Typography>

                        <Typography variant={typographyVariant}>20.4 EVEREST USES DATA AND INFORMATION PROVIDED TO US BY THIRD PARTIES IN ORDER TO COMPILE CERTAIN PORTIONS OF YOUR PROFILE AND TO PROVIDE CUSTOMIZATION. AS SUCH, EVEREST RELIES ON THE PROVIDERS OF THIS INFORMATION FOR ITS ACCURACY. EVEREST MAKES NO WARRANTY THAT THE SERVICE WILL MEET YOUR REQUIREMENTS,</Typography>

                        <Typography variant={typographyVariant}>20.5 WITHOUT LIMITATION OF THE ABOVE IN THIS SECTION, WE AND OUR AFFILIATES, SUPPLIERS AND LICENSORS MAKE NO WARRANTIES OR REPRESENTATIONS REGARDING ANY CONTENT, PRODUCTS, GOODS OR SERVICES ORDERED OR PROVIDED VIA THE APPLICATIONS, AND HEREBY DISCLAIM, AND YOU HEREBY WAIVE, ANY AND ALL WARRANTIES AND REPRESENTATIONS MADE IN PRODUCT OR SERVICES LITERATURE, FREQUENTLY ASKED QUESTIONS DOCUMENTS AND OTHERWISE ON THE APPLICATIONS OR IN CORRESPONDENCE WITH US OR ITS AGENTS. ANY PRODUCTS AND SERVICES ORDERED OR PROVIDED VIA THE APPLICATIONS ARE PROVIDED BY US “AS IS,” EXCEPT TO THE EXTENT, IF AT ALL, OTHERWISE SET FORTH IN A LICENSE OR SALE AGREEMENT SEPARATELY ENTERED INTO IN WRITING BETWEEN YOU AND US AND OUR AFFILIATES.</Typography>

                        <Typography variant={typographyVariant}><strong>21. Disclaimer of Damages and Limitation of Liability</strong></Typography>

                        <Typography variant={typographyVariant}>21.1 TO THE FULLEST EXTENT ALLOWED BY LAW, IN NO EVENT SHALL WE AND OUR AFFILIATES BE LIABLE FOR: (1) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR (2) ANY LOSS OF USE, DATA, BUSINESS, REPUTATION, EMOTIONAL DISTRESS, OR PROFITS, REGARDLESS OF LEGAL THEORY INCLUDING, WITHOUT LIMITATION, ANY RESULTS ARISING FROM, OR RELATED TO, THE DIRECT OR INDIRECT USE OF, OR THE INABILITY TO USE, OR THE RESULTS OF USING OR INABILITY TO USE, THE APPLICATIONS AND/OR THE CONTENT, PUBLICLY SUBMITTED MATERIALS AND FUNCTIONS RELATED THERETO, OR THE USE OF, OR INABILITY TO USE, YOUR PERSONAL INFORMATION, YOUR PROVISION OF INFORMATION VIA THE APPLICATIONS. THE AVAILABILITY OR ACCURACY OF CONTENT OR MATERIALS. IT IS UNDERSTOOD AND AGREED THAT ALL LIMITATIONS OF LIABILITY AND DISCLAIMERS STATED HEREIN AND THROUGHOUT THIS AGREEMENT SHALL BE VALID AND BINDING UPON YOU, EVEN IF WE, OUR AFFILIATES AND/OR ANY PROTECTED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT WE OR ANY OF OUR AFFILIATES OR OTHER PROTECTED PARTIES HAVE BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES. </Typography>

                        <Typography variant={typographyVariant}>21.2 WE, OUR AFFILIATES AND OTHER PROTECTED PARTIES HEREIN, AREN’T RESPONSIBLE FOR YOUR USE OF THE APPLICATIONS AND/OR THE CONDUCT, PUBLICLY SUBMITTED MATERIALS OR CONTENT OF YOU OR OTHERS IN ANY EVENT. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS. IN NO EVENT SHALL THE PROTECTED ENTITIES BE LIABLE FOR, OR IN CONNECTION WITH, ANY USE OF THE APPLICATIONS OR CONTENT POSTED, TRANSMITTED, EXCHANGED OR RECEIVED IN ANY AND ALL MEDIUMS BY OR ON BEHALF OF ANY USER OR OTHER PERSON ON OR THROUGH THE APPLICATIONS. IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF THE PROTECTED ENTITIES TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM THE TERMS AND CONDITIONS OR YOUR USE OF THE APPLICATIONS AND CONTENT EXCEED, IN THE AGGREGATE, THE AMOUNT, IF ANY, PAID BY YOU TO US FOR YOUR USE OF THE APPLICATIONS OR PURCHASE OF PRODUCTS VIA THE APPLICATIONS.</Typography>

                        <Typography variant={typographyVariant}><strong>22. Disclaimer of Responsibility and Endorsement of Third Party Applications </strong></Typography>

                        <Typography variant={typographyVariant}>22.1 Our Applications may provide links and pointers to other Applications maintained by third parties. Our linking to such third-party Applications does not imply an endorsement or sponsorship of such sites, or the information, products or services offered on or through the sites. In addition, neither we nor our Affiliates operate or control in any respect any information, products or services that third parties may provide on or through the Applications linked to by us through our Applications.</Typography>

                        <Typography variant={typographyVariant}>22.2 If applicable, any opinions, advice, statements, services, offers, or other information or content expressed or made available by third parties, including information providers, are those of the respective authors or distributors, and not Everest. Neither Everest nor any third-party provider of information guarantees the accuracy, completeness, or usefulness of any content. </Typography>

                        <Typography variant={typographyVariant}>22.3 Furthermore, Everest neither endorses nor is responsible for the accuracy and reliability of any opinion, advice, or statement made on any of our Applications by anyone other than an authorized Everest representative while acting in his/her official capacity.</Typography>

                        <Typography variant={typographyVariant}><strong>23. Indemnification</strong></Typography>

                        <Typography variant={typographyVariant}>23.1 You agree to defend, indemnify and hold Everest and Everest’s and its Affiliates’ directors, officers, employees and agents harmless from any and all claims, liabilities, costs and expenses, including reasonable attorneys’ fees, arising in any way from your use of the Applications, your placement or transmission of any message, content, information, software or other materials through the Applications, or your breach or violation of the law or of these T&Cs. </Typography>

                        <Typography variant={typographyVariant}>23.2 Everest reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with Everest’s defense of such claim, provided, however, that you shall remain liable for any such claim. </Typography>

                        <Typography variant={typographyVariant}>23.3 Everest shall have the right to immediately terminate or suspend any of your passwords, accounts or subscriptions in the event we consider, in our sole discretion, any of your conduct to be unacceptable, or in the event you breach this agreement. the terms and conditions contained this agreement will survive termination of service and use of the Applications.</Typography>

                        <Typography variant={typographyVariant}><strong>24. Complaints </strong></Typography>

                        <Typography variant={typographyVariant}>24.1 Should you not be satisfied with Everest’s services and/or Applications you may follow the procedure of making a complaint to us via the following link: <a href="https://www.everest.org/complaints/" target="_blank">https://www.everest.org/complaints/</a></Typography>

                        <Typography variant={typographyVariant}><strong>25. Termination</strong></Typography>

                        <Typography variant={typographyVariant}>25.1 You may to stop using our services at any time. Everest may terminate, change, suspend or discontinue any aspect of the Applications or the Applications’ services at any time. </Typography>

                        <Typography variant={typographyVariant}>25.2 Everest may restrict, suspend or terminate your access to the Applications and/or its services if we, at our sole discretion, believe you are in breach of our terms and conditions or applicable law. Everest maintains a policy that provides for the termination in appropriate circumstances of the Applications use privileges of users who are repeat infringers of intellectual property rights. </Typography>

                        <Typography variant={typographyVariant}>25.3 If, at our sole discretion, we deem any of your activities, behavior content or Publicly Submitted Materials inappropriate, harmful or dangerous, we reserve all rights to suspend or terminate your account. Depending upon the circumstances, at our discretion, we may provide you with reasonable advance notice via the messaging address associated with your account to remedy the activity that prompted us to contact you. If after such notice, you fail to take the steps we ask of you, we will terminate or suspend your access to the services and Applications.</Typography>

                        <Typography variant={typographyVariant}>25.4 We may, at our discretion, decide to discontinue offering services and access to our Applications in response to unforeseen circumstances beyond our control or to comply with a legal requirement. If we discontinue offering the services to which your subscribed before the end of any fixed or minimum term you have paid us for, we will refund the portion of the fees you have pre-paid but for which you have not yet received services.</Typography>

                        <Typography variant={typographyVariant}><strong>26. Disputes and Governing Law</strong></Typography>

                        <Typography variant={typographyVariant}>26.1 Everest will act in accordance with its obligations as set out by the MFSA together with the applicable legislation in terms of Maltese law. In lieu of this, the governing law for these T&Cs shall be Maltese law. We will adhere to this procedure in case of any dispute or complaint arising or relating to these T&Cs, the Parties shall make best endeavours to amicably solve it, by way of direct negotiation. </Typography>

                        <Typography variant={typographyVariant}>26.2 Everest binds itself to acknowledge receipt of any complaint in writing upon receipt. Where a complaint is made orally, Everest shall compile a summary of the complaint and request the complainant to confirm in writing said provided summary. We will then seek to gather and investigate all relevant evidence and information regarding the complaint.</Typography>

                        <Typography variant={typographyVariant}>26.3 Everest will provide a response by not later than fifteen working days from when the complaint is registered. Where the investigation surrounding said complaint is not completed within fifteen days, we will inform the complainant about the causes of the delay and provide an indication as to when the investigation is likely to be completed. When providing a final decision, Everest shall provide a thorough explanation of its position on the complaint. If unsatisfied the user may refer the complaint to the Office of the Arbiter for Financial Services as established under the Arbiter for Financial Services Act. </Typography>

                        <Typography variant={typographyVariant}>26.4 Under no circumstances shall any dispute brought by either Party related in any way to the website be commenced more than three years after the cause of action arose. If this provision is found to be illegal or unenforceable, then neither Party will elect to negotiate any dispute falling within that portion of this provision found to be illegal or unenforceable and such dispute shall be decided by a court of competent jurisdiction with the Courts of Malta as established above, and the Parties agree to submit to the personal jurisdiction of that court. </Typography>

                        <Typography variant={typographyVariant}>26.5 The aggregate liability accepted by the Company in any circumstances whatsoever, whether in contract, tort or otherwise and howsoever caused, including negligence, for loss or damage arising from or in connection with any act or omission through the use of our Site shall be limited to the maximum sum of 1x fees provided that this limitation shall not apply in the event of fraud.</Typography>

                        <Typography variant={typographyVariant}>26.6 You shall only resolve disputes with us on an individual basis and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations are void, except where it is prohibited by law or deemed by a court of law to be against public policy.</Typography>

                        <Typography variant={typographyVariant}><strong>27. Entire Agreement</strong></Typography>

                        <Typography variant={typographyVariant}>27.1 These Terms constitute the entire agreement between the Parties regarding use of the Applications and will supersede all prior agreements between the Parties whether, written or oral. </Typography>

                        <Typography variant={typographyVariant}>27.2 No usage of trade or other regular practice or method of dealing between the Parties will be used to modify, interpret, supplement, or alter the terms of these T&Cs.</Typography>

                        <Typography variant={typographyVariant}><strong>28. Force Majeure </strong></Typography>

                        <Typography variant={typographyVariant}>28.1 In no event Everest shall be liable for any delays or failures in performance of its obligations resulting from unforeseeable acts beyond Everest’s reasonable control. </Typography>

                        <Typography variant={typographyVariant}><strong>29. Contact Information</strong></Typography>

                        <Typography variant={typographyVariant}>29.1 For more information on Everest and its Applications, you can refer to the information found on our website. </Typography>

                        <Typography variant={typographyVariant}>29.2 If you have questions regarding this agreement, please feel free to contact us for clarification via our Customer Support team at support@everest.org.</Typography>
                    </Box>
                </Box>

                <Button className="wizard-btn primary font-bold mt-20" variant="contained" onClick={() => setShowScreen('page1')} fullWidth>Next</Button>
            </Box>
        </>
    );
};

export default Terms;