import React  from 'react';
import {Box, Typography, Button} from "@material-ui/core";
import NavBar from "./navBar"

const Intro = ({setShowScreen}) => {

    return (
        <>
            <NavBar title={"OrgEverID Setup"} backHandler={() => setShowScreen('setup')} setShowScreen={setShowScreen} />
            <Box className="wizard-box">
                <Typography variant="body2" className="info-text">Hello and thank you for your interest in Setting Up an Organization on  the Everest platform. Your request will go into a queue that will be reviewed  and when your organization is approved, you will be notified at the email address specified. Please ensure this a good contact address for you.</Typography>
                <br />
                <Typography variant="body2" className="info-text">You will be contacted to clarify any issue with your Request, or to update  you on the status periodically. Please add  “Update@Evererest.org”  to any Spam or Content filter you are using.</Typography>
                <br />
                <Typography variant="body2" className="info-text link-text font-bold">Checklist of supporting documents needed for each type of organization.</Typography>
                <br />
                <Typography variant="body2" className="info-text primary-color font-bold">Thank you from Team Everest.</Typography>
                <br />
                <Button className="wizard-btn primary font-bold" variant="contained" onClick={() => setShowScreen('terms')} fullWidth>Next</Button>
            </Box>
        </>
    );
};

export default Intro;