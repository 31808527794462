import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import TransactionsContext from '../../contexts/TransactionsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import TransactionsList from './TransactionsList';

const TransactionsProvider = (updateOrg) => {
    const [transactions, setTransactions] = useState({ list: undefined, count: 0, status: STATUS_INIT });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [transactionSelected, setTransactionSelected] = useState([]);
    const [enableButtonReport, setEnableButtonReport] = useState(true);
    const { getIdToken, logout } = useAuth0();
    const SEARCH_URL = '/v3/admin/restricted/central-ledger/transactions';
    const urlParams = { page: 1, size: itemsPerPage }; //, from: '', to: '', sort: 'timestamp', direction: "DESC"

    const resolved = (response) => {
        const { data, success } = response.data;
        if (success) {
            setTransactions({
                list: data.results,
                count: data.count,
                status: (data.results && data.results.length > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const rejected = (error) => {
        if (error.message !== cancelMessage) {
            setTransactions({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setTransactions({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    useEffect(() => {
        refreshList({ statusText: STATUS_LOADING });
        setEnableButtonReport(true);
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(SEARCH_URL, urlParams, source, getIdToken())
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    }, [updateOrg]);

    if (transactions.error && transactions.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }

    return (
        <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
            <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                    <TransactionsContext.Provider value={setTransactions}>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <SearchBar onSearchTermChange={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} searchQueryParameter={"trx_id"} />
                                </Grid.Column>
                                <Grid.Column textAlign='right'>
                                    <ShowResults onListRefresh={refreshList} onFetchError={rejected} listURL={SEARCH_URL}urlParams={urlParams} limitQueryParameter={"size"}  />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <TransactionsList
                            status={transactions.status}
                            list={transactions.list}
                            listURL={SEARCH_URL}
                            totalCount={transactions.count}
                            error={transactions.error}
                            transactionSelected={transactionSelected}
                            setTransactionSelected={setTransactionSelected}
                            setDataToast={updateOrg.setDataToast}
                            setEnableButtonReport={setEnableButtonReport}
                            enableButtonReport={enableButtonReport}
                            metaData={updateOrg.metaData}
                            currentOrg={updateOrg.currentOrg} />
                    </TransactionsContext.Provider>
                </PageContext.Provider>
            </ShowResultsContext.Provider>
        </SearchTermContext.Provider>
    );
};

export default TransactionsProvider;
