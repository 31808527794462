import React, {useState, useRef}  from 'react';
import {Box, Button, Grid, TextField, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import NavBar from "./navBar";
import Step from "./step";

const Page3 = ({orgData, setShowScreen, setOrgData, setDataToast}) => {

    const inputRefs = useRef([]);
    const regStatusRef = useRef(null);
    const [submitClick, setSubmitClick] = useState(false);

    const changeData = (e) => {
        const { name, value } = e.target;
        setOrgData(prevData => ({...prevData, [name]: value}));
    };

    const changeFileData = (e) => {
        const { name, files } = e.target;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setOrgData(prevData => ({...prevData, [name]: e.target.result}));
        };
    };

    const nextStep = () => {
        setSubmitClick(true);
        let error = false;

        if(!orgData.registrationStatus) {
            inputRefs.current["registrationStatus"].focus();
            error = true;
            return false;
        }

        if(!orgData.registrationStatusFile) {
            setDataToast({ open: true, variant: "error", message: "Please upload the Registration Status File", timeAutoHide: 6000 });
            error = true;
            return false;
        }

        if(!error) {
            setSubmitClick(false);
            setShowScreen("page5");
        }
    };

    return (
        <Box>
            <NavBar title={"OrgEverID Setup"} backHandler={() => setShowScreen('page3')} setShowScreen={setShowScreen} />
            <Box className="wizard-box">
                <Box className=" text-center">
                    <Step totalSteps={5} currentStep={4} />
                </Box>
                <Grid container spacing={2}>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="associatedEntity"
                            variant="outlined"
                            label="Associated Entity (optional)"
                            placeholder="Type here"
                            value={orgData.associatedEntity}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="entityStatus"
                            variant="outlined"
                            label="Entity Status (optional)"
                            placeholder="Type here"
                            value={orgData.entityStatus}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="entityExpirationDate"
                            variant="outlined"
                            label="Entity Expiration Date"
                            type="date"
                            value={orgData.entityExpirationDate}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="entityExpirationReason"
                            variant="outlined"
                            label="Entity Expiration Reason"
                            placeholder="Type here"
                            value={orgData.entityExpirationReason}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="successorEntity"
                            variant="outlined"
                            label="Successor Entity"
                            placeholder="Type here"
                            value={orgData.successorEntity}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="initialRegistrationDate"
                            variant="outlined"
                            label="Initial Registration Date"
                            type="date"
                            value={orgData.initialRegistrationDate}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="lastUpdateDate"
                            variant="outlined"
                            label="Last Update Date"
                            type="date"
                            value={orgData.lastUpdateDate}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel>Registration Status (certificate of good standing)</InputLabel>
                            <OutlinedInput
                                name="registrationStatus"
                                variant="outlined"
                                placeholder="Upload a document"
                                value={orgData.registrationStatus}
                                onChange={changeData}
                                inputRef={el => el && (inputRefs.current[el.name] = el)}
                                error={submitClick && !orgData.registrationStatus}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => regStatusRef.current.click()}
                                            edge="end"
                                        >
                                            <CloudUploadIcon />
                                            <input ref={regStatusRef} name="registrationStatusFile" type="file" onChange={changeFileData} accept="application/pdf, image/*" hidden />
                                        </IconButton>
                                        {orgData.registrationStatusFile && <IconButton onClick={() => window.open(orgData.registrationStatusFile)} edge="end"><CloudDownloadIcon /></IconButton>}
                                    </InputAdornment>
                                }
                                labelWidth={350}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="nextRenewalDate"
                            variant="outlined"
                            label="Next Renewal Date"
                            type="date"
                            value={orgData.nextRenewalDate}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="parentOrgs"
                            variant="outlined"
                            label="Parent Org(s)"
                            placeholder="Type here"
                            value={orgData.parentOrgs}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="childOrgs"
                            variant="outlined"
                            label="Child Org(s)"
                            placeholder="Type here"
                            value={orgData.childOrgs}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Button className="wizard-btn primary font-bold mt-20" variant="contained" onClick={nextStep} fullWidth>Save & continue</Button>
            </Box>
        </Box>
    );
};

export default Page3;