import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import MembersContext from '../../contexts/MembersContext';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import MembersList from './MembersList';


const MembersProvider = (updateOrg) => {
    const [members, setMembers] = useState({ list: undefined, count: 0, status: STATUS_INIT });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const { getIdToken, logout } = useAuth0();
    const DEFAULT_SEARCH_URL = '/v3/admin/restricted/organization/search_index';
    const SEARCH_URL = DEFAULT_SEARCH_URL;
    //const UPDATE_URL = '/v3/admin/organization/update_index';
    const urlParams = { page: 1, limit: itemsPerPage, sort: 'createdAt' };
    updateOrg.setNameTitleModule('sidebar_members');
    const resolved = (response) => {
        const { memberList, memberCount, success } = response.data;
        if (success === true) {
            setMembers({
                list: memberList,
                count: memberCount,
                status: (memberList && memberList.length && memberCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        };
    };

    const rejected = (error) => {
        if (error.message !== cancelMessage) {
            setMembers({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setMembers({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    //Commented by two calls to the request

    //useEffect(() => { 
    //  const source = cancelToken.source();
    //  const promise = helpers
    //    .search(SEARCH_URL, urlParams, source)
    //    .then(resolved)
    //    .catch(rejected);
    //  return () => {
    //    helpers.cancel(promise, source);
    //  };
    //}, []);

    useEffect(() => {
        refreshList({ statusText: STATUS_LOADING });
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(SEARCH_URL, urlParams, source, getIdToken())
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    }, [updateOrg]);

    if (members.error && members.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }
    return (
        <React.Fragment>
            <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
                <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                        <MembersContext.Provider value={setMembers}>

                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <SearchBar onSearchTermChange={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} from={"member"} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <ShowResults onListRefresh={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} from={"member"} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {
                                <MembersList
                                    status={members.status}
                                    list={members.list}
                                    listURL={SEARCH_URL}
                                    totalCount={members.count}
                                    sortByColumn='lastName'
                                    sortDirection='ascending'
                                    error={members.error}
                                    currentOrg={updateOrg.currentOrg}
                                    setCurrentOrg={updateOrg.setCurrentOrg}
                                    metaData={updateOrg.metaData}
                                    nameTitleModule={updateOrg.nameTitleModule}
                                    setNameTitleModule={updateOrg.setNameTitleModule}
                                    setDataToast={updateOrg.setDataToast}
                                    role_name={updateOrg.metaData.role_name}
                                />
                            }
                            {/* <TanksDistribution /> */}
                        </MembersContext.Provider>
                    </PageContext.Provider>
                </ShowResultsContext.Provider>
            </SearchTermContext.Provider>
        </React.Fragment>
    );
};

export default MembersProvider;
