import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const langOptions = [
    { key: 0, text: 'English', value: 'en' },
    { key: 1, text: 'Indonesian', value: 'id' }
];

const LanguageSelect = (props) => {
    const { t, i18n } = useTranslation();

    const changeLang = () => {
        let setLang = (i18n.language === 'en') ? 'id' : 'en';
        localStorage.setItem('language', setLang);
        i18n.changeLanguage(setLang);
    };

    return (
        <React.Fragment>
            <div>
                <span style={{ marginRight: 10 }}>{t('sidebar_change_language')}</span>
                <Dropdown
                    onChange={changeLang}
                    options={langOptions}
                    value={i18n.language}
                    selection
                />
            </div>
        </React.Fragment>
    )
};

export default LanguageSelect;
