import { sortBy } from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Modal, Message, Pagination, Table, Grid, Segment, Loader } from 'semantic-ui-react';
import helpers, { cancelToken, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_ERROR, STATUS_OK } from '../../services/helpers';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import RefCodesContext from '../../contexts/RefCodesContext';
import PageContext from '../../contexts/PageContext';
import PageSelect from '../common/PageSelect';
import ErrorMessage from '../common/ErrorMessage';
import {useAuth0} from "../../react-auth0-wrapper";
import Web3 from "web3";
import moment from "moment-timezone";
import RefCodeDetails from './RefCodeDetails';


const RefCodesList = (props) => {
    const [selectedRefCode, setSelectedRefCode] = useState(null);
    const [column, setColumn] = useState(props.sortByColumn);
    const [direction, setDirection] = useState(props.sortDirection);
    const [showModal, setShowModal] = useState(false);
    const [listPromise, setListPromise] = useState({ promise: null, source: null });

    const setReferralCodes = useContext(RefCodesContext);
    const { searchTerm } = useContext(SearchTermContext);
    const { itemsPerPage } = useContext(ShowResultsContext);
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const { getIdToken } = useAuth0();

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            if (listPromise.source !== null) {
                helpers.cancel(listPromise.promise, listPromise.source);
            }
        }
    }, [listPromise]);

    if (props.status === STATUS_INIT) {
        return (
            <Segment>
                <Loader active size='large' inline='centered'>{t('list_message_loading_members')}</Loader>
            </Segment>
        );
    }

    if (props.status === STATUS_ERROR) {
        return <ErrorMessage error={props.error} />;
    }

    if (props.status === STATUS_NO_RESULTS) {
        return (
            <Message>
                <Message.Header>{t('list_message_no_results_title')}</Message.Header>
                <p>{t('list_message_no_results_text')}</p>
            </Message>);
    }

    const loadingContent = (
        <Table.Row>
            <Table.Cell colSpan='8'>
                <Segment basic>
                    <Loader active size='large' inline='centered'>{t('list_message_loading_ref_codes')}</Loader>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );


    // pagination
    const pageNumbers = [];
    for (let x = 0; x < Math.ceil(props.totalCount / itemsPerPage); x++) {
        pageNumbers.push(x);
    }

    const resolved = (response) => {
        const { memberList, memberCount, success } = response.data;
        if (success === true) {
            setListPromise({ promise: null, source: null });
            setReferralCodes({
                list: memberList,
                count: memberCount,
                status: (memberList && memberList.length && memberCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const handlePaginationChange = (event, { activePage }) => {
        setCurrentPage(activePage);
        setReferralCodes({
            list: -1,
            count: props.totalCount,
            status: STATUS_LOADING
        });

        if (listPromise.source !== null) {
            helpers.cancel(listPromise.promise, listPromise.source);
        }

        const options = { keyword: `${searchTerm}`, page: activePage, limit: itemsPerPage, sort: 'createdAt' };
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(props.listURL, options, source, getIdToken())
            .then(resolved)
            .catch(console.error);
        setListPromise({ promise, source });
    };


    // column sort
    const sortByColumn = (clickedColumn) => () => {
        if (column !== clickedColumn) {
            setColumn(clickedColumn);
            setDirection('ascending');
            return;
        }
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    };

    function handleSort(list, clickedColumn) {
        const sortList = sortBy(list, (obj) => {
            switch (clickedColumn) {
                case 'rewardForRegistered':
                    return obj[clickedColumn];
                case 'address':
                case 'balance':
                    return obj.wallet[clickedColumn] && obj.wallet[clickedColumn].toLowerCase();
                default:
                    return obj[clickedColumn] && obj[clickedColumn].toLowerCase();
            }
        });

        if (direction === 'descending') {
            return sortList.reverse();
        }
        return sortList;
    };

     
    // modal: member details
    const onMemberInfoClick = (event) => {
        event.preventDefault();
        if (event.target.classList.contains('edit')) {
            const key = event.target.dataset.key;
            const selectedMemberData = props.list.filter(refCode => {
                return refCode.code === key;
            });
            setSelectedRefCode(selectedMemberData[0]);
            setShowModal(true);
        }
    };

    const onCloseModal = () => {
        setShowModal(false);
        setSelectedRefCode(null);
    };

    const web3 = new Web3();
    const tableRows = handleSort(props.list, column).map(refCode => {
        return (
            <Table.Row key={refCode.code}>
                <Table.Cell textAlign="center">{refCode.code}</Table.Cell>
                <Table.Cell textAlign="center">{refCode.walletAddress}</Table.Cell>
                <Table.Cell>{web3.utils.fromWei(refCode.rewardForRegistered+"", "ether")}</Table.Cell>
                <Table.Cell textAlign="center">{moment(refCode.validFrom).format('MMM Do, YYYY')}</Table.Cell>
                <Table.Cell textAlign="center">{moment(refCode.validTo).format('MMM Do, YYYY')}</Table.Cell>
                <Table.Cell textAlign='center'>
                    <Icon name='edit' color='blue' data-key={refCode.code} className='icon-button' style={{fontSize: "18px"}} />
                </Table.Cell>
            </Table.Row>
        );
    });

    return (
        <Grid padded='vertically'>
            <Grid.Column>
                <Table sortable celled singleLine compact onClick={onMemberInfoClick}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                textAlign="center"
                                style={{ width: 100 }}
                                sorted={column === 'code' ? direction : null}
                                onClick={sortByColumn('code')}>
                                {t('code')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="center"
                                style={{ width: 200 }}
                                sorted={column === 'walletAddress' ? direction : null}
                                onClick={sortByColumn('walletAddress')}>
                                {t('wallet_address')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                style={{ width: 100 }}
                                sorted={column === 'rewardForRegistered' ? direction : null}
                                onClick={sortByColumn('rewardForRegistered')}>
                                {t('ref_reward')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="center"
                                style={{ width: 130 }}
                                sorted={column === 'validFrom' ? direction : null}
                                onClick={sortByColumn('validFrom')}>
                                {t('validFrom')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="center"
                                style={{ width: 130 }}
                                sorted={column === 'validTo' ? direction : null}
                                onClick={sortByColumn('validTo')}>
                                {t('validTo')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}>{t('member_list_details')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(props.status === STATUS_LOADING) ? loadingContent : tableRows}
                    </Table.Body>
                    {/*<Table.Footer>*/}
                        {/*<Table.Row>*/}
                            {/*<Table.HeaderCell colSpan='6' textAlign='center' style={{ position: 'relative' }}>*/}
                                {/*<Pagination*/}
                                    {/*activePage={currentPage}*/}
                                    {/*totalPages={pageNumbers.length}*/}
                                    {/*onPageChange={handlePaginationChange}*/}
                                    {/*siblingRange={1}*/}
                                    {/*boundaryRange={1}*/}
                                    {/*ellipsisItem={undefined}*/}
                                    {/*firstItem={undefined}*/}
                                    {/*lastItem={undefined}*/}
                                    {/*prevItem={undefined}*/}
                                    {/*nextItem={undefined}*/}
                                    {/*disabled={pageNumbers.length === 1}*/}
                                {/*/>*/}
                                {/*<PageSelect activePage={currentPage}*/}
                                    {/*pagesTotal={pageNumbers.length}*/}
                                    {/*onPageChange={handlePaginationChange}*/}
                                {/*/>*/}
                            {/*</Table.HeaderCell>*/}
                        {/*</Table.Row>*/}
                    {/*</Table.Footer>*/}
                </Table>
                <Modal open={showModal} onClose={onCloseModal}>
                    <RefCodeDetails
                        data={selectedRefCode}
                        currentOrg={props.currentOrg}
                        setCurrentOrg={props.setCurrentOrg}
                        metaData={props.metaData}
                        setShowModal={setShowModal}
                        setDataToast={props.setDataToast}
                        role_name={props.role_name}
                        getList={props.getList}
                    />
                </Modal>
            </Grid.Column>
        </Grid>
    );
};

export default RefCodesList;
