import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

const PageSelect = (props) => {
  const [timeoutPage, setTimeoutPage] = useState(null);
  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);

  const {t} = useTranslation();

  const {activePage, pagesTotal} = props;
  const PAGE_TIMEOUT = 1000;

  const onInputChange = (event) => {
    clearTimeout(timeoutPage);
    const page = { activePage: event.target.value };

    const BAD_VALUE = page.activePage > pagesTotal;
    const BAD_CHAR = /^[1-9]\d*$/.test(page.activePage) === false;

    const isError = (page.activePage.length > 0) && (BAD_VALUE || BAD_CHAR);

    setValue(page.activePage);
    setIsError(isError);

    switch (true) {
      case (isError):
      case (page.activePage === activePage):
      case (page.activePage.length === 0):
        return false;
      default:
        setTimeoutPage(setTimeout(() => {
          props.onPageChange(event, page);
          setValue('');
        }, PAGE_TIMEOUT));
    }
  };

  return (
    <React.Fragment>
      <Input style={{position: 'absolute', right: 13, top: 13, width: 200}}
        className='page-select'
        type='number'
        icon='search'
        placeholder={t('member_list_page_select')}
        min={1}
        max={props.pagesTotal}
        value={value}
        onChange={onInputChange}
        error={isError}
      />
    </React.Fragment>
  )
};

export default PageSelect;
