import React, {useState} from 'react';
import { Modal, Segment, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DetailsLine from './DetailsLine';
import styled from 'styled-components';

const KYC = styled.div`
  margin-top: 40px;
`;

const isJSON = str => {
  try {
      return (JSON.parse(str) && !!str);
  } catch (e) {
      return false;
  }
}

const ModalIDV = ({idv, report}) => {

  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  let reportKYC;
  
  if(isJSON(report)) {
    try {
      reportKYC = JSON.parse(report).breakdown;
    } catch(e) {
      reportKYC = false;
    }
  }

  const capitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const statusIcon = status => {
    switch (status) {
      case 'consider':
        return (
          <Icon color='yellow' name='exclamation triangle' style={{'margin-right' : '10px'}} />
        )   
      case 'complete':
      case 'clear':
        return (
          <Icon color='green' name='check circle' style={{'margin-right' : '10px'}} />
        )
      default:
        return (
          <Icon color='blue' name='question circle' style={{'margin-right' : '10px'}} />
        )

    }
  }


  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size="small"
      trigger={ <Button style={{position: 'absolute', right: '1.5rem', top: '0.85rem', margin: 0}}>{t`kyc_report`}</Button> }
      >
      <Modal.Header>{t`kyc_report`}</Modal.Header>
      <Modal.Content>
        <Segment basic compact>
          <DetailsLine><strong style={{'width': '200px'}}>{t('kyc_status')}</strong>{statusIcon(idv.idvStatus || 'N/A')}<span>{capitalize(idv.idvStatus || 'N/A')}</span></DetailsLine>
          <DetailsLine><strong style={{'width': '200px'}}>{t('kyc_status_facial')}</strong>{statusIcon(idv.idvStatusFacial || 'N/A')}<span>{capitalize(idv.idvStatusFacial || 'N/A')}</span></DetailsLine>
          <DetailsLine><strong style={{'width': '200px'}}>{t('kyc_status_identity')}</strong>{statusIcon(idv.idvStatusIdentity  || 'N/A')}<span>{capitalize(idv.idvStatusIdentity || 'N/A')}</span></DetailsLine>
          <DetailsLine><strong style={{'width': '200px'}}>{t('kyc_status_watchlist')}</strong>{statusIcon(idv.idvStatusWatchlist || 'N/A')}<span>{capitalize(idv.idvStatusWatchlist || 'N/A')}</span></DetailsLine>
          
          { !!reportKYC ? (
            <KYC>
              <DetailsLine><strong style={{'width': '200px'}}>Adverse Media</strong>{statusIcon(reportKYC.adverse_media.result || 'N/A')}<span>{capitalize(reportKYC.adverse_media.result || 'N/A')}</span></DetailsLine>
              <DetailsLine><strong style={{'width': '200px'}}>Sanction</strong>{statusIcon(reportKYC.sanction.result || 'N/A')}<span>{capitalize(reportKYC.sanction.result || 'N/A')}</span></DetailsLine>
              <DetailsLine><strong style={{'width': '200px'}}>Politically Exposed Person</strong>{statusIcon(reportKYC.politically_exposed_person.result || 'N/A')}<span>{capitalize(reportKYC.politically_exposed_person.result || 'N/A')}</span></DetailsLine>
            </KYC>
          ) : <KYC><pre>No KYC report data is available for this profile yet.</pre></KYC>}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button icon='check' content={t`popup_close`} onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalIDV;
