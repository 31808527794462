import React, { useEffect } from 'react';
import { Card } from 'semantic-ui-react';


const OrganizationList = ({ data, handleClick }) => (
  !!data && (
    Object.keys(data).map(prop =>
      !data[prop] ?
        <React.Fragment key={prop}>
          <div className="ui card">
            <div className="content">
              <div className="header">{prop}</div>
              <div className="description">
                <button
                  className="ui button"
                  onClick={() => { handleClick(prop) }}
                  style={{ width: '100%', marginBottom: 5, backgroundColor: '#fa4261', color: 'white' }}>
                  {prop}
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
        : <React.Fragment key={prop}>
          <div className="ui card">
            <div className="content">
              <div className="header">{prop}</div>
              <div className="description"><SubOrganizationButton data={data[prop]} handleClick={handleClick} /></div>
            </div>
          </div>
        </React.Fragment>
    )
  )
);

const SubOrganizationButton = ({ data, handleClick }) => (
  !!data && (
    data.map(item =>
      <button
        key={item}
        onClick={() => { handleClick(item) }}
        className="ui button"
        style={{ width: '100%', marginBottom: 5, backgroundColor: '#fa4261', color: 'white' }}>
        {item}
      </button>
    )
  )
);

export default function OrgAdmin(props) {
  if (!props.metaData) {
    return <></>;
  }

  useEffect(() => {
    if (props.metaData && props.metaData.role_name === 'SUPER_ADMIN' && !props.currentOrg) {
      props.setCurrentOrg(props.metaData.org_name);
    }
  }, [props.metaData]);

  const breadcrumbs = {
    agents: 'sidebar_agents',
    members: 'sidebar_members',
    transactions: 'sidebar_transactions',
    reports: 'sidebar_reports',
    kinabank: 'sidebar_kina_bank'
  };

  const handleClick = (event) => {
    props.setCurrentOrg(event);
    if (!props.nameTitleModule) {
      let localStorageSection = localStorage.getItem('nameSection');
      if (localStorageSection) {
        props.setNameTitleModule(localStorageSection);
      }
      else {
        props.setNameTitleModule('sidebar_agents');
      }
    }
  };

  let showList;
  if (props.metaData.role_name === 'SUPER_ADMIN') {
    showList = props.metaData.organizations_tree;
  }
  if (props.metaData.role_name === 'ORGANIZATION_ADMIN') {
    showList = props.metaData.organizations_tree[props.metaData.org_name];
  }
  if (props.metaData.role_name === 'SUB_ORGANIZATION_ADMIN') {
    showList = props.metaData.sub_org_name;
  }

  return (
    <Card.Group centered>
      <OrganizationList data={showList} handleClick={handleClick} />
    </Card.Group>
  );
};
