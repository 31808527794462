export const transactionTypeLabel = (type) => {
    switch(type) {
        case "fiat_to_token":
            return "Fiat to Token";
        case "token_to_token":
            return "Token to Token";
        case "token_to_fiat":
            return "Token to Fiat";
        case "fiat_to_everid":
            return "Fiat to EverID";
        case "remittance":
            return "Remittance";
        default:
            return type;
    }
};

export const fiatTokenInAmount = (trx) => {
    switch(trx.transaction_type.type) {
        case "token_to_token":
        case "token_to_fiat":
            return trx.amount+' '+trx.token_in;
        case "withdraw":
            return trx.amount+' '+trx.currency_code_out+".c";
        default:
            return trx.currency_in_amount+' '+trx.currency_code_in;
    }
};

export const fiatTokenOutAmount = (trx) => {
    switch(trx.transaction_type.type) {
        case "remittance":
            return "-";
        case "token_to_fiat":
            return trx.currency_out_amount+' '+trx.currency_code_out+".c";
        case "withdraw":
            return trx.currency_out_amount+' '+trx.currency_code_out;
        default:
            return (trx.token_out_amount * 1).toFixed(5)+' '+trx.token_out;
    }
};