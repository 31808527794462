import React from "react";
import {AppBar, Toolbar, CssBaseline,} from "@material-ui/core";
import Logo from "../../assets/img/logo-new.png";
import BodyLogo from "../../assets/img/body-logo.png";


const Header = () => {

    return (
        <AppBar position="static">
            <CssBaseline />
            <Toolbar style={{justifyContent: "center", background: "#FFFFFF"}}>
                <img src={Logo} alt="logo" />
            </Toolbar>
            <img src={BodyLogo} style={{position: "fixed"}} />
        </AppBar>
    );
};

export default Header;