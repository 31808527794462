import React from 'react';
import TopPriority from '@material-ui/icons/VerticalAlignTop';
import BottomPriority from '@material-ui/icons/VerticalAlignBottom';
import MedPriority from '@material-ui/icons/VerticalAlignCenter';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';

import '../../App.css';
export default function TransactionRiskIcon(props) {
    const { t } = useTranslation();
    if (!props.risk || props.risk === -1) {
        return "-"
    } else if (props.risk <= 30) {
        return (<Tooltip title={t("transaction_details_risk_low") + ' (' + props.risk + ')'} placement="top"><BottomPriority fontSize={props.iconSize} style={{color: "#93c47d"}} /></Tooltip>)
    } else if (props.risk > 30 && props.risk <= 60) {
        return (<Tooltip title={t("transaction_details_risk_medium") + ' (' + props.risk + ')'} placement="top"><MedPriority fontSize={props.iconSize} style={{color: "#f1c231"}} /></Tooltip>)
    } else if (props.risk > 60 && props.risk <= 80) {
        return (<Tooltip title={t("transaction_details_risk_high") + ' (' + props.risk + ')'} placement="top"><TopPriority fontSize={props.iconSize} style={{color: "#ff0000"}} /></Tooltip>)
    } else if (props.risk > 80 ) {
        return (<Tooltip title={t("transaction_details_risk_very_high") + ' (' + props.risk + ')'} placement="top"><TopPriority fontSize={props.iconSize} style={{color: "#fe03ff"}} /></Tooltip>)
    }
}

