import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Checkbox, Select, Input, Button, Modal, Segment, Icon} from 'semantic-ui-react';
import DetailsLine from "../common/DetailsLine";
import helpers, {cancelToken} from "../../services/helpers";
import {useAuth0} from "../../react-auth0-wrapper";

export default function AddPartner(props) {

    const partnerData = props.data;
    const id = partnerData.id || 0;
    const [name, setName] = useState(partnerData.name || "");
    const [appKey, setAppKey] = useState(partnerData.app_key || "");
    const [organization, setOrganization] = useState(partnerData.organization || "");
    const [identity, setIdentity] = useState(typeof partnerData.identity_service_active !== "undefined" ? partnerData.identity_service_active : true);
    const [payer, setPayer] = useState(partnerData.payer_service_active || false);
    const [exchange, setExchange] = useState(partnerData.exchange_service_active || false);
    const [buyFeature, setBuyFeature] = useState(partnerData.buy_feature_active || false);
    const [sellFeature, setSellFeature] = useState(partnerData.sell_feature_active || false);
    const [isActive, setIsActive] = useState(typeof partnerData.is_active !== "undefined" ? partnerData.is_active : true);
    const [appKeyLoader, setAppKeyLoader] = useState(false);

    const orgsList = [];
    const organizations = props.organizations;
    if(organizations.length > 0) {
        for(let i = 0; i < organizations.length; i++) {
            orgsList.push({ key: organizations[i], value: organizations[i], text: organizations[i] })
        }
    }

    const [nameErr, setNameErr] = useState(false);
    const [appKeyErr, setAppKeyErr] = useState(false);
    const [organizationErr, setOrganizationErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const { getIdToken } = useAuth0();
    const { t } = useTranslation();

    useEffect(() => {
        if(id === 0) {
            getAppKey();
        }
    }, []);

    const isValidName = () => {
        setNameErr(false);
        if(!name) {
            setNameErr(true);
            return false;
        }
        return true;
    };

    const isValidAppKey = () => {
        setAppKeyErr(false);
        if(!appKey) {
            setAppKeyErr(true);
            return false;
        }
        if(appKey.length !== 15) {
            setAppKeyErr(true);
            return false;
        }
        return true;
    };

    const isValidOrganization = () => {
        setOrganizationErr(false);
        if(!organization) {
            setOrganizationErr(true);
            return false;
        }
        return true;
    };

    const updatePartner = () => {
        const validName = isValidName();
        const validAppKey = isValidAppKey();
        const validOrganization = isValidOrganization();
        if(!validName || !validAppKey || !validOrganization) {
            return;
        }

        setLoading(true);
        const data = {
            app_key: appKey,
            name: name,
            organization: organization,
            identity_service_active: identity,
            payer_service_active: payer,
            exchange_service_active: exchange,
            buy_feature_active: buyFeature,
            sell_feature_active: sellFeature,
            is_active: isActive
        };
        const helperAction = id  > 0 ? 'updatePartner' : 'createPartner';
        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0[helperAction](token, source, data, id).then(response => {
            setLoading(false);
            if(response.data.success) {
                const msg = `Partner has been ${id  > 0 ? "updated" : "added"} successfully.`;
                props.setDataToast({ open: true, type: "info", message: msg, timeAutoHide: 6000 });
                props.setRefreshData(prev => !prev);
                props.onCloseModal();
            } else {
                window.alert("Something went wrong");
            }
        }).catch(error => {
            setLoading(false);
            const resp = JSON.parse(error.request.response);
            const msg = resp.error ? resp.error : resp.message;
            props.setDataToast({ open: true, variant: "error", message: msg, timeAutoHide: 6000 });
        });
    };

    const getAppKey = () => {
        if(!appKeyLoader) {
            const token = getIdToken();
            const source = cancelToken.source();
            setAppKeyLoader(true);
            helpers.auth0.getUniqueAppKey(token, source)
                .then((resp) => {
                    setAppKeyLoader(false);
                    setAppKey(resp.data.data);
                })
                .catch((error) => {
                    setAppKeyLoader(false);
                    console.log(error);
                });
        }
    };

    return (
        <React.Fragment>
            <Modal.Header>
                {id > 0 ? "Update Partner" : "Add Partner"}
            </Modal.Header>
            <Modal.Content>
                <Segment vertical>
                    <DetailsLine>
                        <span className="ref-add-label">APP Key</span>
                        <Input
                            error={appKeyErr}
                            onBlur={isValidAppKey}
                            value={appKey}
                            style={{width: '350px'}}
                            onChange={(e) => setAppKey(e.target.value)}
                            disabled={true}
                        />
                        <Icon name='refresh' loading={appKeyLoader} color='blue' onClick={getAppKey} data-key={id} className='icon-button' style={{fontSize: "18px", marginLeft: "15px"}} />
                    </DetailsLine>
                    <DetailsLine>
                        <span className="ref-add-label">Partner Name</span>
                        <Input
                            error={nameErr}
                            onBlur={isValidName}
                            value={name}
                            style={{width: '400px'}}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </DetailsLine>
                    <DetailsLine>
                        <span className="ref-add-label">Organization</span>
                        <Select
                            error={organizationErr}
                            onBlur={isValidOrganization}
                            value={organization}
                            placeholder='Select partner Organization'
                            style={{width: '400px'}}
                            options={orgsList}
                            onChange={(e, data) => setOrganization(data.value)}
                        />
                    </DetailsLine>
                    <DetailsLine>
                        <span className="ref-add-label">Active</span>
                        <Checkbox defaultChecked={isActive} onChange={() => setIsActive(!isActive)} toggle />
                    </DetailsLine>
                    <h1>Services</h1>
                    <DetailsLine>
                        <span className="ref-add-label">Identity Service</span>
                        <Checkbox defaultChecked={identity} onChange={() => setIdentity(!identity)} toggle />
                    </DetailsLine>
                    <DetailsLine>
                        <span className="ref-add-label">Payer Service</span>
                        <Checkbox defaultChecked={payer} onChange={() => setPayer(!payer)} toggle />
                    </DetailsLine>
                    <DetailsLine>
                        <span className="ref-add-label">Exchange Service</span>
                        <Checkbox defaultChecked={exchange} onChange={() => setExchange(!exchange)} toggle />
                        {
                            exchange &&
                            <div className="partner-feature">
                                <div style={{display: 'inline-block'}}>
                                    <span className="ref-add-label">Buy Feature</span>
                                    <Checkbox defaultChecked={buyFeature} onChange={() => setBuyFeature(!buyFeature)} toggle />
                                </div>
                                <div style={{marginLeft: '20px', display: 'inline-block'}}>
                                    <span className="ref-add-label">Sell Feature</span>
                                    <Checkbox defaultChecked={sellFeature} onChange={() => setSellFeature(!sellFeature)} toggle />
                                </div>
                            </div>
                        }
                    </DetailsLine>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button positive disabled={loading} loading={loading} onClick={updatePartner}>
                    Save
                </Button>
                <Button onClick={() => props.onCloseModal()} style={{ backgroundColor: '#fa4261', color: 'white' }}>
                    Close
                </Button>
            </Modal.Actions>
        </React.Fragment>
    );
}