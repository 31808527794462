import React, { useState, useEffect } from 'react';
import {Container, Box, createMuiTheme, ThemeProvider} from "@material-ui/core";
import Header from "./header";
import Setup from "./setup";
import Intro from "./intro";
import Terms from "./terms";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import Page4 from "./page4";
import Page5 from "./page5";
import Toast from "../common/Toast";

const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {
                    'scrollbar-width': 'thin',
                },
                '*::-webkit-scrollbar': {
                    width: '4px',
                    height: '4px',
                }
            }
        },
        MuiCheckbox: {
            root: {
                color: '#16359D',
            },
            colorSecondary: {
                '&$checked': {
                    color: '#16359D',
                },
            },
        },
    },
    breakpoints: {
        values: {
            sm: 670,
        }
    },
    typography: {
        fontFamily: [
            "Spartan",
            "sans-serif"
        ].join(",")
    },
    palette: {
        action: {
            disabled: '#000000',
        }
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#db3131",
                    "&$error": {
                        color: "#db3131",
                    },
                },
            },
        },
    },
});

const OrgWizardNew = () => {

    const orgObject = {
        adminSet: "",
        adminSet2: "",
        name: "",
        otherName: "",
        legalAddressStreet: "",
        legalAddressCity: "",
        legalAddressState: "",
        legalAddressPostal: "",
        legalAddressCountry: "",
        hqAddressStreet: "",
        hqAddressCity: "",
        hqAddressState: "",
        hqAddressPostal: "",
        hqAddressCountry: "",
        otherAddressStreet: "",
        otherAddressCity: "",
        otherAddressState: "",
        otherAddressPostal: "",
        otherAddressCountry: "",
        transAddressStreet: "",
        transAddressCity: "",
        transAddressState: "",
        transAddressPostal: "",
        transAddressCountry: "",
        url: "",
        phone: "",
        fax: "",
        accountNumber: "",
        iban: "",
        bic: "",
        routingNumber: "",
        clabe: "",
        bsb: "",
        category: "",
        services: "",
        businessModel: "",
        lei: "",
        incorporationDate: "",
        registrationNumber: "",
        registrationNumberFile: "",
        taxIdentificationNumber: "",
        taxIdentificationNumberFile: "",
        ubos: "",
        ubosFile: "",
        type: "",
        legalJurisdiction: "",
        legalRegistration: "",
        license: "",
        registrationBody: "",
        registrationBodyLink: "",
        associatedEntity: "",
        entityStatus: "",
        entityExpirationDate: "",
        entityExpirationReason: "",
        successorEntity: "",
        initialRegistrationDate: "",
        lastUpdateDate: "",
        registrationStatus: "",
        registrationStatusFile: "",
        nextRenewalDate: "",
        parentOrgs: "",
        childOrgs: "",
        kybScreening: "",
        kybScreeningFile: "",
    };

    const [showScreen, setShowScreen] = useState('setup');
    const [orgData, setOrgData] = useState(orgObject);
    const [dataToast, setDataToast] = useState({ open: false, type: '', message: '', timeAutoHide: 0 });


    useEffect(() => {
        document.body.classList.add("wizard-body");
        document.body.classList.remove("default-body");
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box className="everest-body">
                <Header />
                <Container component="main" maxWidth="sm" className={showScreen === "setup" || showScreen === "intro" || showScreen === "terms" ? "wizard-container" : "wizard-container2"}>
                    {
                        {
                            'setup': <Setup setShowScreen={setShowScreen} />,
                            'intro': <Intro setShowScreen={setShowScreen} />,
                            'terms': <Terms setShowScreen={setShowScreen} />,
                            'page1': <Page1 setShowScreen={setShowScreen} orgData={orgData} setOrgData={setOrgData} />,
                            'page2': <Page2 setShowScreen={setShowScreen} orgData={orgData} setOrgData={setOrgData} />,
                            'page3': <Page3 setShowScreen={setShowScreen} orgData={orgData} setOrgData={setOrgData} setDataToast={setDataToast} />,
                            'page4': <Page4 setShowScreen={setShowScreen} orgData={orgData} setOrgData={setOrgData} setDataToast={setDataToast} />,
                            'page5': <Page5 setShowScreen={setShowScreen} orgData={orgData} setOrgData={setOrgData} setDataToast={setDataToast} orgObject={orgObject} />,
                        }[showScreen]
                    }
                </Container>
                <Toast dataToast={dataToast} setDataToast={setDataToast} />
            </Box>
        </ThemeProvider>
    );
};

export default OrgWizardNew;