import React, { useState } from 'react'
import { Grid, Input, Button } from 'semantic-ui-react'
import { useAuth0 } from '../../react-auth0-wrapper';
import { DateInput } from 'semantic-ui-calendar-react';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';



const MultipleFilter = () => {
    const [dateFilter, setDateFilter] = useState('');
    const [agentFilter, setAgentFilter] = useState('');
    const [senderFilter, setSenderFilter] = useState('');
    const [receiverFilter, setReceiverFilter] = useState('');
    const [amountFilter, setAmountFilter] = useState('');

    const handleDateChange = (event, { name, value }) => {
        setDateFilter(value)
    };

    const clearFilters = (event) => {
        setDateFilter('')
        setAgentFilter('')
        setSenderFilter('')
        setReceiverFilter('')
        setAmountFilter('')
    }
    const applyFilters = (event) => {
       window.alert("Apply");
       this.getlists();
       
    }





    const [reports, setReports] = useState({ list: undefined, count: 0, status: STATUS_INIT });

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const { logout } = useAuth0();
    const SEARCH_URL = '/v3/admin/organization/search_report_index';
    const urlParams = { page: 1, limit: itemsPerPage, sort: 'createdAt' };
    const resolved = (response) => {
        const { reportList, reportCount, success } = response.data;
        if (success === true) {
            console.log("RepList",reportList);
            setReports({
                list: reportList,
                count: reportCount,
                status: (reportList && reportList.length && reportCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const rejected = (error) => {
        console.log("Reject",error);

        if (error.message !== cancelMessage) {
            setReports({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setReports({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    const getlists= async () => {
        refreshList({ statusText: STATUS_LOADING });
        const source = cancelToken.source();
        const payload={
            agent:agentFilter,
            sender:senderFilter,
            receiver:receiverFilter,
            amount:amountFilter,
            date: dateFilter

        };
        const promise = helpers
            .reportsFiltering(payload, source)
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    if (reports.error && reports.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }






    return (
        <>
            <Grid columns={6}>
                <Grid.Row>
                    < Grid.Column >
                        <Input placeholder='Agent' value={agentFilter} onChange={(evt) => setAgentFilter(evt.target.value)} />
                    </Grid.Column>
                    < Grid.Column >
                        <Input placeholder='Sender' value={senderFilter} onChange={(evt) => setSenderFilter(evt.target.value)} />
                    </Grid.Column>
                    < Grid.Column >
                        <Input placeholder='Receiver' value={receiverFilter} onChange={(evt) => setReceiverFilter(evt.target.value)} />
                    </Grid.Column>
                    < Grid.Column >
                        <Input placeholder='Amount' value={amountFilter} onChange={(evt) => setAmountFilter(evt.target.value)} />
                    </Grid.Column>
                    < Grid.Column >
                        <DateInput
                            fluid
                            name="date"
                            placeholder="Date"
                            value={dateFilter}
                            iconPosition="left"
                            onChange={handleDateChange}
                        />
                    </Grid.Column>
                    < Grid.Column>
                        <div style={{ display: 'inline-flex' }}>
                            <Button size='mini' onClick={clearFilters}>Clear</Button>
                            <Button size='mini' className='ui primary button'>Apply</Button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default MultipleFilter