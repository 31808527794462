import React, {useState, useRef}  from 'react';
import {Box, Typography, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton, OutlinedInput, DialogTitle, Divider, DialogContent, Dialog} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Close from '@material-ui/icons/Close';
import NavBar from "./navBar";
import Step from "./step";

const Page3 = ({orgData, setShowScreen, setOrgData, setDataToast}) => {
    const catOptions = ["Family", "Neighborhood", "Community", "Sole Proprietorship", "Partnership (SubType: LP, LLP, LLLP)", "Company",
        "Corporation (SubType: LLC, LTD, C, S, Nonprofit)", "Co-op", "Trust", "Government", "NGO", "Educational"];

    const inputRefs = useRef([]);
    const regNumberRef = useRef(null);
    const taxNumberRef = useRef(null);
    const ubosRef = useRef(null);
    const [submitClick, setSubmitClick] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [fileModal, setFileModal] = useState(false);

    const changeData = (e) => {
        const { name, value } = e.target;
        setOrgData(prevData => ({...prevData, [name]: value}));
    };

    const changeFileData = (e) => {
        const { name, files } = e.target;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setOrgData(prevData => ({...prevData, [name]: e.target.result}));
        };
    };

    const downloadFile = (file) => {
        setSelectedFile(orgData[file]);
        setFileModal(true);
        // window.open(orgData[file]);
    };

    const nextStep = () => {
        setSubmitClick(true);
        let error = false;
        const rInputs = ["category", "services", "businessModel", "incorporationDate", "registrationNumber", "taxIdentificationNumber", "ubos", "type", "legalJurisdiction", "legalRegistration"];

        for(let i = 0; i < rInputs.length; i++) {
            if(!orgData[rInputs[i]]) {
                inputRefs.current[rInputs[i]].focus();
                error = true;
                return false;
            }
        }

        if(orgData.license && !orgData.registrationBody) {
            inputRefs.current["registrationBody"].focus();
            error = true;
            return false;
        }

        if(orgData.license && !orgData.registrationBodyLink) {
            inputRefs.current["registrationBodyLink"].focus();
            error = true;
            return false;
        }

        if(!orgData.registrationNumberFile) {
            setDataToast({ open: true, variant: "error", message: "Please upload the Registration Number File", timeAutoHide: 6000 });
            error = true;
            return false;
        }

        if(!orgData.taxIdentificationNumberFile) {
            setDataToast({ open: true, variant: "error", message: "Please upload the Tax Number File", timeAutoHide: 6000 });
            error = true;
            return false;
        }

        if(!orgData.ubosFile) {
            setDataToast({ open: true, variant: "error", message: "Please upload the ubos File", timeAutoHide: 6000 });
            error = true;
            return false;
        }

        if(!error) {
            setSubmitClick(false);
            setShowScreen("page4");
        }
    };

    return (
        <Box>
            <NavBar title={"OrgEverID Setup"} backHandler={() => setShowScreen('page2')} setShowScreen={setShowScreen} />
            <Box className="wizard-box">
                <Box className=" text-center">
                    <Step totalSteps={5} currentStep={3} />
                </Box>
                <Grid container spacing={2}>

                    <Grid item={true} xs={12}>
                        <FormControl required variant="outlined" fullWidth>
                            <InputLabel>Legal organization Categorization</InputLabel>
                            <Select
                                name="category"
                                value={orgData.category}
                                label="Legal organization Categorization"
                                onChange={changeData}
                                inputProps={{ref: el =>  el && (inputRefs.current["category"] = el)}}
                                error={submitClick && !orgData.category}
                            >
                                {catOptions.map((val, index) =>
                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="services"
                            variant="outlined"
                            label="Org Description of Services"
                            placeholder="Type here"
                            value={orgData.services}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.services}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <FormControl required variant="outlined" fullWidth>
                            <InputLabel>Org Business model</InputLabel>
                            <Select
                                name="businessModel"
                                value={orgData.businessModel}
                                label="Org Business model"
                                onChange={changeData}
                                inputProps={{ref: el =>  el && (inputRefs.current["businessModel"] = el)}}
                                error={submitClick && !orgData.businessModel}
                            >
                                {["d2c", "b2b", "both"].map((val, index) =>
                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="lei"
                            variant="outlined"
                            label="Organization LEI (optional)"
                            placeholder="Type here"
                            value={orgData.lei}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        /><br />
                        <Typography variant="body2" className="info-text link-text font-bold text-right">View GLEIF document</Typography>
                    </Grid>

                    {/*<Grid item={true} xs={12}>*/}
                        {/*<Typography variant="h6" className="font-bold">Org Date of Incorporation</Typography>*/}
                    {/*</Grid>*/}

                    {/*<Grid item={true} xs={4}>*/}
                        {/*<FormControl required variant="outlined" fullWidth>*/}
                            {/*<InputLabel>Date</InputLabel>*/}
                            {/*<Select*/}
                                {/*name="incorporationDate"*/}
                                {/*value={orgData.incorporationDate}*/}
                                {/*label="Date"*/}
                                {/*onChange={changeData}*/}
                                {/*inputProps={{ref: el =>  el && (inputRefs.current["incorporationDate"] = el)}}*/}
                                {/*error={submitClick && !orgData.incorporationDate}*/}
                            {/*>*/}
                                {/*{dateArr.map((val, index) =>*/}
                                    {/*<MenuItem key={index} value={val}>{val}</MenuItem>*/}
                                {/*)}*/}
                            {/*</Select>*/}
                        {/*</FormControl>*/}
                    {/*</Grid>*/}


                    <Grid item={true} xs={12}>
                        <TextField
                            name="incorporationDate"
                            variant="outlined"
                            label="Org Date of Incorporation"
                            type="date"
                            value={orgData.incorporationDate}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.incorporationDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel>Organization Unique Identifier (Registration #)</InputLabel>
                            <OutlinedInput
                                name="registrationNumber"
                                variant="outlined"
                                placeholder="Upload a document"
                                value={orgData.registrationNumber}
                                onChange={changeData}
                                inputRef={el => el && (inputRefs.current[el.name] = el)}
                                error={submitClick && !orgData.registrationNumber}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => regNumberRef.current.click()}
                                            edge="end"
                                        >
                                            <CloudUploadIcon />
                                            <input ref={regNumberRef} name="registrationNumberFile" type="file" onChange={changeFileData} accept="application/pdf, image/*" hidden />
                                        </IconButton>
                                        {orgData.registrationNumberFile && <IconButton onClick={() => downloadFile("registrationNumberFile")} edge="end"><CloudDownloadIcon /></IconButton>}
                                    </InputAdornment>
                                }
                                labelWidth={340}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel>Organization Unique Identifier (TIN)</InputLabel>
                            <OutlinedInput
                                name="taxIdentificationNumber"
                                variant="outlined"
                                placeholder="Upload a document"
                                value={orgData.taxIdentificationNumber}
                                onChange={changeData}
                                inputRef={el => el && (inputRefs.current[el.name] = el)}
                                error={submitClick && !orgData.taxIdentificationNumber}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => taxNumberRef.current.click()}
                                            edge="end"
                                        >
                                            <CloudUploadIcon />
                                            <input ref={taxNumberRef} name="taxIdentificationNumberFile" type="file" onChange={changeFileData} accept="application/pdf, image/*" hidden />
                                        </IconButton>
                                        {orgData.taxIdentificationNumberFile && <IconButton onClick={() => downloadFile("taxIdentificationNumberFile")} edge="end"><CloudDownloadIcon /></IconButton>}
                                    </InputAdornment>
                                }
                                labelWidth={340}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel>Register of Directors and/or Members/UBOs</InputLabel>
                            <OutlinedInput
                                name="ubos"
                                variant="outlined"
                                placeholder="Upload a document"
                                value={orgData.ubos}
                                onChange={changeData}
                                inputRef={el => el && (inputRefs.current[el.name] = el)}
                                error={submitClick && !orgData.ubos}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => ubosRef.current.click()}
                                            edge="end"
                                        >
                                            <CloudUploadIcon />
                                            <input ref={ubosRef} name="ubosFile" type="file" onChange={changeFileData} accept="application/pdf, image/*" hidden />
                                        </IconButton>
                                        {orgData.ubosFile && <IconButton onClick={() => downloadFile("ubosFile")} edge="end"><CloudDownloadIcon /></IconButton>}
                                    </InputAdornment>
                                }
                                labelWidth={340}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="type"
                            variant="outlined"
                            label="Organization Type"
                            placeholder="Type here"
                            value={orgData.type}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.type}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="legalJurisdiction"
                            variant="outlined"
                            label="Legal Jurisdiction"
                            placeholder="Type here"
                            value={orgData.legalJurisdiction}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.legalJurisdiction}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="legalRegistration"
                            variant="outlined"
                            label="Legal Registration"
                            placeholder="Type here"
                            value={orgData.legalRegistration}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && !orgData.legalRegistration}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="license"
                            variant="outlined"
                            label="License (if financial institution, link to license)"
                            placeholder="Type here"
                            value={orgData.license}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="registrationBody"
                            variant="outlined"
                            label="Org Reg Body/ Authority"
                            placeholder="Type here"
                            value={orgData.registrationBody}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && orgData.license && !orgData.registrationBody}
                            required={!!orgData.license}
                            fullWidth
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            name="registrationBodyLink"
                            variant="outlined"
                            label="Link to Auth Body database / directory / registry (to look up entities)"
                            placeholder="Type here"
                            value={orgData.registrationBodyLink}
                            onChange={changeData}
                            inputRef={el => el && (inputRefs.current[el.name] = el)}
                            error={submitClick && orgData.license && !orgData.registrationBodyLink}
                            required={!!orgData.license}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Button className="wizard-btn primary font-bold mt-20" variant="contained" onClick={nextStep} fullWidth>Save & continue</Button>
                <Dialog open={fileModal} fullWidth={true} onClose={() => setFileModal(false)}>
                    <DialogTitle style={{padding: "20px", color: '#16359D'}}>
                        <IconButton
                            aria-label="close"
                            onClick={() => setFileModal(false)}
                            style={{position: 'absolute', right: 8, top: 8, color: '#16359D'}}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Divider style={{marginTop: "15px"}} />
                    <DialogContent>
                        <object data={selectedFile} width="100%" height="600px" />
                    </DialogContent>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Page3;