import React from 'react';
import LanguageSelect from './LanguageSelect';
import packageJson from '../../../package.json';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
    const { version } = packageJson;
    const { t } = useTranslation();

    var style = {
        backgroundColor: "#F8F8F8",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        padding: "20px",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
    }

    var phantom = {
        display: 'block',
        padding: '20px',
        height: '60px',
        width: '100%',
    }

    return (
        <React.Fragment>
            <div>
                <div style={phantom} />
                <div style={style}>
                    <div className="styleVersionFooter">
                    <div><span>{t`admin_console_version`}{version}</span></div>
                    </div>
                    <div className="styleLanguageFooter">
                        <LanguageSelect />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default Footer