import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './react-auth0-wrapper';
import {APP_AUTH0_DOMAIN, APP_AUTH0_CLIENT_ID} from "./js/variables";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import OrgWizard from "./components/orgWizard";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};
ReactDOM.render(

  <Router basename='/'>

    <Switch>
      <Route path='/organization-profile' render={() => <OrgWizard />} />
      <Auth0Provider
        domain={APP_AUTH0_DOMAIN}
        client_id={APP_AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin}
        audience="everestmain"
        scope="openid profile email phone"
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
