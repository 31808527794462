import React, { useEffect, useRef, useState } from 'react';
import {Grid, Dropdown, Button} from 'semantic-ui-react';
import Context from '../../contexts/PartnersContext';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import List from './List';

const Provider = (props) => {
    const [partners, setPartners] = useState({ list: undefined, count: 0, status: STATUS_INIT });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [refreshData, setRefreshData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { getIdToken, logout } = useAuth0();
    const SEARCH_URL = '/v3/admin/restricted/partner';
    const urlParams = { page: 1, limit: itemsPerPage };
    props.setNameTitleModule('partners');
    const resolved = (response) => {
        const { data, success } = response.data;
        const { partnerRecords, partnerCount } = data;
        if (success) {
            setPartners({
                list: partnerRecords,
                count: partnerCount,
                status: (partnerRecords && partnerRecords.length && partnerCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const rejected = (error) => {
        if (error.message !== cancelMessage) {
            setPartners({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setPartners({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    useEffect(() => {
        refreshList({ statusText: STATUS_LOADING });
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(SEARCH_URL, urlParams, source, getIdToken())
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    }, [refreshData]);

    if (partners.error && partners.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }
    return (
        <React.Fragment>
            <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
                <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                        <Context.Provider value={setPartners}>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <SearchBar onSearchTermChange={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} searchBy={"uuid"} placeholder={"Search By userID..."} />
                                    </Grid.Column>
                                    {/*<Grid.Column textAlign='center'>*/}
                                        {/*<ShowResults onListRefresh={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} searchBy={"uuid"} />*/}
                                    {/*</Grid.Column>*/}
                                    <Grid.Column textAlign='right'>
                                        <Button type="button" onClick={() => setShowModal(true)} style={{backgroundColor: '#fa4261', color: 'white', padding: 8}}>
                                            Add Partner
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {
                                <List
                                    status={partners.status}
                                    list={partners.list}
                                    listURL={SEARCH_URL}
                                    totalCount={partners.count}
                                    error={partners.error}
                                    metaData={props.metaData}
                                    nameTitleModule={props.nameTitleModule}
                                    setNameTitleModule={props.setNameTitleModule}
                                    setDataToast={props.setDataToast}
                                    role_name={props.metaData.role_name}
                                    setRefreshData={setRefreshData}
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                />
                            }
                        </Context.Provider>
                    </PageContext.Provider>
                </ShowResultsContext.Provider>
            </SearchTermContext.Provider>
        </React.Fragment>
    );
};

export default Provider;