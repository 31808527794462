import React, { useEffect, useState } from 'react';
import {Icon, Modal, Table, Grid, Segment, Loader} from 'semantic-ui-react';
import TransactionStatusIcon from "../common/TransactionStatusIcon";
import OrgDetail from "../orgProfileList/orgDetail";
import {useAuth0} from "../../react-auth0-wrapper";
import {cancelToken, STATUS_LOADING} from "../../services/helpers";
import helpers from "../../services/helpers";

const OrgProfileList = ({setDataToast}) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [refreshList, setRefreshList] = useState(false);
    const { getIdToken } = useAuth0();

    useEffect(() => {
        getOrgProfiles();
        // const orgProfileList = JSON.parse(localStorage.getItem("orgProfileList"));
        // if(orgProfileList)
        //     setList(orgProfileList);
    }, [refreshList]);

    const getOrgProfiles = () => {
        setLoading(true);
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getOrgProfiles(token, source)
            .then(resp => {
                setLoading(false);
                if(resp.data.success && resp.data.data && resp.data.data.length > 0) {
                    setList(resp.data.data);
                }
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const orgDetail = (orgData) => {
        setSelectedOrg(orgData);
        setShowModal(true);
    };

    const onCloseModal = () => {
        setShowModal(false);
        setSelectedOrg(null);
    };

    const loadingContent = (
        <Table.Row>
            <Table.Cell colSpan='8'>
                <Segment basic>
                    <Loader active size='large' inline='centered'>Loading Organization Profiles...</Loader>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );

    const NoData = (
        <Table.Row>
            <Table.Cell colSpan='8'>
                <Segment basic>
                    <h3 style={{textAlign: "center"}}>No Organization Profiles</h3>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );

    const tableRows = list.map((orgData, index) => {
        return (
            <Table.Row key={index}>
                <Table.Cell>{orgData.name}</Table.Cell>
                <Table.Cell>{orgData.phone}</Table.Cell>
                <Table.Cell>{orgData.category}</Table.Cell>
                <Table.Cell>{orgData.registrationNumber}</Table.Cell>
                <Table.Cell textAlign="center"><TransactionStatusIcon status={orgData.status ? orgData.status : "needs_review"} /></Table.Cell>
                <Table.Cell textAlign='center' onClick={() => orgDetail(orgData)}>
                    <Icon circular inverted name='info' color='blue' className='icon-button' />
                </Table.Cell>
            </Table.Row>
        );
    });


    return (
        <Grid padded='vertically'>
            <Grid.Column>
                <Table sortable celled singleLine compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: 200 }}>Organization Name</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>Phone</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>Category</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>Registration #</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Details</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {loading ? loadingContent : list.length === 0 ? NoData : tableRows}
                    </Table.Body>
                </Table>
                <Modal style={{ marginTop: '25px', width: '80%' }}
                       open={showModal}
                       onClose={onCloseModal}>
                    <OrgDetail
                        data={selectedOrg}
                        setDataToast={setDataToast}
                        setShowModal={setShowModal}
                        setRefreshList={setRefreshList}
                    />
                </Modal>
            </Grid.Column>
        </Grid>
    );
};

export default OrgProfileList;