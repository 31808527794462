import React from "react";
import {Box, Grid, IconButton, Typography} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";


const NavBar = ({title, backHandler, setShowScreen}) => {

    return (
        <Box style={{background: "#FFFFFF", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"}}>
            <Box style={{padding: "15px"}}>
                <Grid container>
                    <Grid item={true} xs={1}>
                        <IconButton
                            className="icon-color"
                            onClick={backHandler}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item={true} xs={10}>
                        <Typography variant="h6" style={{color: "#16359D", marginTop: "10px"}}>
                            <strong>{title}</strong>
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={1}>
                        <IconButton
                            className="icon-color"
                            onClick={() => setShowScreen('setup')}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default NavBar;