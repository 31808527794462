import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Button, Input, Modal, Placeholder, Segment} from 'semantic-ui-react';
import styled from 'styled-components';
import helpers, { cancelToken } from '../../services/helpers';
import DetailsLine from '../common/DetailsLine';
import { useAuth0 } from '../../react-auth0-wrapper';
import { withRouter } from 'react-router-dom';
import Web3 from "web3";
import {DatesRangeInput} from "semantic-ui-calendar-react";

const Avatar = styled.div`
  background-image: url(data:image/png;base64,${props => props.photo});
  background-size: cover;
  background-position: center center;
  width: 140px;
  height: 140px;
  display: inline-block;
  vertical-align: top;
`;

const RefCodeDetails = (props) => {

    const getValidity = () => {
        const today = new Date(validFrom);
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        const validF = day+"-"+(month+1)+"-"+year;

        const todayPlusYears = new Date(validTo);
        const validT = todayPlusYears.getDate()+"-"+(todayPlusYears.getMonth()+1)+"-"+todayPlusYears.getFullYear();
        return validF+" - "+validT;
    };

    const web3 = new Web3();
    const [photoThumbnail, setPhotoThumbnail] = useState(null);
    const { t } = useTranslation();
    const { owner, code, rewardForRegistered, ownerReferredTxPercentReward, walletAddress, validFrom, validTo } = props.data;
    const [profile, setProfile] = useState({});
    const [refCount, setRefCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const [validityErr, setValidityErr] = useState(false);
    const [validity, setValidity] = useState(getValidity());
    const [refRewardErr, setRefRewardErr] = useState(false);
    const [agentRewardErr, setAgentRewardErr] = useState(false);
    const [refReward, setRefReward] = useState(web3.utils.fromWei(rewardForRegistered+"", "ether"));
    const [agentReward, setAgentReward] = useState(ownerReferredTxPercentReward);

    const { getIdToken } = useAuth0();

    useEffect(() => {
        loadProfile();
        getCount();
    }, []);

    const handleValidity = (event, {value}) => {
        setValidity(value);
    };

    const isValidValidity = () => {
        setValidityErr(false);
        if(!validity) {
            setValidityErr(true);
            return false;
        } else {
            const validityArr = validity.split(" - ");
            if(!validityArr[0] || !validityArr[1]) {
                setValidityErr(true);
                return false;
            }
        }
        return true;
    };

    const isValidRefReward = () => {
        setRefRewardErr(false);
        if(!refReward) {
            setRefRewardErr(true);
            return false;
        }
        const refCheck = refReward * 1;
        if(refCheck <= 0 || refCheck > 100) {
            setRefRewardErr(true);
            return false;
        }
        return true;
    };

    const isValidAgentReward = () => {
        setAgentRewardErr(false);
        if(!agentReward || agentReward > 5) {
            setAgentRewardErr(true);
            return false;
        }
        return true;
    };

    const updateRefCode = () => {
        const validValidity = isValidValidity();
        const validRefReward = isValidRefReward();
        const validAgentReward = isValidAgentReward();
        if(!validValidity || !validRefReward || !validAgentReward) {
            return;
        }
        setLoading(true);
        const validityArr = validity.split(" - ");
        let validFrom = validityArr[0];
        let validTo = validityArr[1];

        const validFromArr = validFrom.split("-");
        validFrom = new Date(validFromArr[2]+"-"+validFromArr[1]+"-"+validFromArr[0]).toISOString();
        validFrom = validFrom.replace(".000", "");
        const validToArr = validTo.split("-");
        validTo = new Date(validToArr[2]+"-"+validToArr[1]+"-"+validToArr[0]).toISOString();
        validTo = validTo.replace(".000", "");

        const web3 = new Web3();
        const req = {
            code,
            walletAddress,
            ownerReferredTxPercentReward: agentReward,
            rewardForRegistered: web3.utils.toWei(refReward, "ether"),
            currency: "ID",
            // properties: [],
            owner,
            validFrom,
            validTo
        };

        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0.updateRefCode(req, token, source).then(response => {
            setLoading(false);
            if(response.data.success) {
                props.setDataToast({ open: true, type: "info", message: "Referral Code has been updated successfully.", timeAutoHide: 6000 });
                props.getList();
                props.setShowModal(false);
            } else {
                window.alert("Something went wrong");
            }
        }).catch(error => {
            setLoading(false);
            const resp = JSON.parse(error.request.response);
            if(resp.code.message === "ENTRY_ALREADY_EXISTS") {
                props.setDataToast({ open: true, type: "error", message: "Referral code for this user is already existed.", timeAutoHide: 6000 });
            } else {
                window.alert(resp.code.message);
            }
        });
    };


    const loadProfile = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getProfile(token, owner, source)
            .then(response=>{
                setLoading(false);
                setProfile(response.data.users);
                setPhotoThumbnail(response.data.photoThumbnail);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const getCount = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getRefCodeUseCount(code, token, source)
            .then(response=>{
                setRefCount(response.data.data);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    return (
        <>
            <Modal.Header>
                <span>Code: {code}</span>
            </Modal.Header>
            <Modal.Content scrolling>
                <Segment vertical style={{ paddingTop: 0 }}>
                    {photoThumbnail ? (
                        <Avatar photo={photoThumbnail} />
                    ) : (
                            <Placeholder style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }} >
                                <Placeholder.Image />
                            </Placeholder>
                        )}
                    <div style={{ display: 'inline-block' }}>
                        {!loading &&
                            <Segment basic compact>
                                <DetailsLine><span>Name:</span><strong>{profile.firstName} {profile.lastName}</strong></DetailsLine>
                                <DetailsLine><span>{t('member_details_phone_number')}</span><strong>{profile.countryCode && `(+${profile.countryCode})`} {profile.phoneNumber || 'N/A'}</strong></DetailsLine>
                                <DetailsLine><span>{t('member_details_ew_address')}</span><strong>{walletAddress}</strong></DetailsLine>
                                <DetailsLine><span>Referral Link:</span><strong>https://wallet.everest.org/signup-referral/{code}</strong></DetailsLine>
                                <DetailsLine><span>Referral Code Uses:</span><strong>{refCount}</strong></DetailsLine>
                            </Segment>
                        }
                    </div>
                </Segment>

                <Segment vertical>
                    <DetailsLine className="code-generate-date-range">
                        <span className="ref-update-label">{t('validity')}</span>
                        <DatesRangeInput
                            error={validityErr}
                            name="datesRange"
                            placeholder="From - To"
                            pickerStyle={{display: "inline"}}
                            value={validity}
                            iconPosition="left"
                            clearable={true}
                            markColor={"bad"}
                            onChange={handleValidity}
                            onBlur={isValidValidity}
                        />
                    </DetailsLine>
                </Segment>

                <Segment vertical>
                    <DetailsLine className="code-generate-date-range">
                        <span className="ref-update-label">{t('ref_reward')}</span>
                        <Input
                            error={refRewardErr}
                            value={refReward}
                            label={{ basic: true, content: 'ID' }}
                            labelPosition='right'
                            onChange={(event, {value}) => { +value === +value && value <= 100 && setRefReward(value) }}
                            onBlur={isValidRefReward}
                            title={"Maximum Value of Referral Reward is 100 ID"}
                        />
                        <span className="ref-update-label" style={{marginLeft: '20px'}}>{t('user_reward')}</span>
                        <Input
                            error={agentRewardErr}
                            value={agentReward}
                            label={{ basic: true, content: '%' }}
                            labelPosition='right'
                            onChange={(event, {value}) => { +value === +value && value <= 5 && setAgentReward(value) }}
                            onBlur={isValidAgentReward}
                            title={"Maximum Value of User's Reward is 5%"}
                        />
                    </DetailsLine>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button positive disabled={loading} loading={loading} onClick={updateRefCode}>
                    Update
                </Button>
                <Button onClick={() => props.setShowModal(false)} style={{ backgroundColor: '#fa4261', color: 'white' }}>
                    Close
                </Button>
            </Modal.Actions>
        </>
    );
};

export default withRouter(RefCodeDetails);
