import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import BlockIcon from '@material-ui/icons/Block';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

import '../../App.css';

export default function TransactionStatusIcon(props) {
    const { t } = useTranslation();
    switch (props.status) {
        case null:
            return "-";
        case 'approved':
            return (<Tooltip title={t("transaction_details_status_completed")} placement="top">
                <CheckCircleIcon className="text-success"></CheckCircleIcon>
            </Tooltip>)
        case "cancelled":
            return (<Tooltip title={t("transaction_details_status_rejected")} placement="top"><BlockIcon fontSize={props.iconSize} className="text-danger"></BlockIcon></Tooltip>)
        case "incomplete":
            return (<Tooltip title={t("transaction_details_status_incomplete")} placement="top"><InfoIcon fontSize={props.iconSize} className="text-info"></InfoIcon></Tooltip>)
        case "needs_review":
            return (<Tooltip title={t("transaction_details_status_review")} placement="top"><ReportProblemIcon fontSize={props.iconSize} className="text-warning"></ReportProblemIcon></Tooltip>)
        default:
            return (<Tooltip title={t("transaction_details_status_incomplete")} placement="top"><InfoIcon fontSize={props.iconSize} className="text-info"></InfoIcon></Tooltip>)
    }
}

