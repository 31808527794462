import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Confirm, Divider, Grid, Modal, Placeholder, Segment } from 'semantic-ui-react'
import styled from 'styled-components'
import helpers, { cancelToken } from '../../services/helpers'
import DetailsLine from '../common/DetailsLine'
import TransactionRiskIcon from '../common/TransactionRiskIcon'
import TransactionStatusIcon from '../common/TransactionStatusIcon'

const Avatar = styled.div`
	background-image: url(data:image/png;base64,${(props) => props.photo});
                background-size: cover;
                background-position: center center;
                width: 140px;
                height: 140px;
                display: inline-block;
                vertical-align: top;
            `

const GridLabel = styled.h3`
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(32px, -15px, 0);
                background-color: #fff;
                padding: 0 4px;
                font-size: 18px;
            `

const TransactionDetails = (props) => {
    const [
        txDetails,
        setTxDetails
    ] = useState({})
    const iconSize = "large";
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [messageModalConfirm, setMessageModalConfirm] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const { t } = useTranslation()
    const {
        amountCRDT,
        amountFrom,
        amountProvision,
        amountTo,
        currencyFrom,
        currencyProvision,
        currencyTo,
        timestamp,
        Id,
        phone,
        additionalData,
    } = props.data

    const resolveDetails = (response) => {
        const { from, to, agent, agentTo } = response.data
        setTxDetails({ from, to, agent, agentTo })
    }

    useEffect(() => {
        if(phone==""||phone==null||phone== undefined){
            const sourceDetails = cancelToken.source()
            const promiseDetails = helpers
                .getTransactionDetails(Id, sourceDetails)
                .then(resolveDetails)
                .catch(console.error)

            return () => {
                helpers.cancel(promiseDetails, sourceDetails)
            }
        }else{
            const sourceDetails = cancelToken.source()
            const promiseDetails = helpers
                .getTempTransactionDetails(Id, sourceDetails)
                .then(resolveDetails)
                .catch(console.error)

            return () => {
                helpers.cancel(promiseDetails, sourceDetails)
            }
        }
    }, [])

    const actionClickStatus = (statusData) => {
        setNewStatus(statusData);
        setMessageModalConfirm(t('transaction_change_status_confirm', {txID:Id, status:t('transaction_status_' + additionalData.status), newStatus:t('transaction_status_'+ statusData)}))
        setShowModalConfirm(true);
    }

    const handleConfirm = () => {
        helpers.changeTransactionStatus(Id, newStatus == "approved" ? "approve" : "cancel");
        props.setDataToast({ open: true, type: "info", message: t('transaction_change_status_success', {txID:Id, newStatus:t('transaction_status_'+ newStatus)}), timeAutoHide: 6000 });
        props.setShowModal(false);
        setShowModalConfirm(false);
    };
    return (
        <React.Fragment>
            <Modal.Header>
                <Grid columns={3}>
                    <Grid.Row>
                        <Grid.Column>
                            {t('transaction_details_title')}
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            {additionalData && (
                                <>
                                    {additionalData.status === "needs_review" && (
                                        <>
                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                <Button onClick={() => actionClickStatus('approved')}>
                                                    <CheckCircleIcon fontSize="small" className="text-success">
                                                    </CheckCircleIcon>
                                                    {t('transaction_change_status_approve')}
                                        </Button>
                                                <Button onClick={() => actionClickStatus('cancelled')}>
                                                    <BlockIcon fontSize="small" className="text-danger">
                                                    </BlockIcon>
                                                    {t('transaction_change_status_cancel')}
                                        </Button>
                                            </ButtonGroup>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid.Column>
                        <Confirm
                            header={t('popup_header_confirm_status')}
                            open={showModalConfirm}
                            content={messageModalConfirm}
                            cancelButton={t('popup_cancel')}
                            confirmButton={t('popup_ok')}
                            onOpen={() => setShowModalConfirm(true)}
                            onCancel={() => setShowModalConfirm(false)}
                            onConfirm={handleConfirm}
                            size='tiny'
                        />
                        <Grid.Column textAlign='right'>
                            <div style={{ fontSize: 14, fontWeight: 500, float: 'right' }}><TransactionRiskIcon iconSize={iconSize} risk={additionalData ? additionalData.risk : -1}></TransactionRiskIcon></div>
                            <div style={{ fontSize: 14, fontWeight: 500 }}><TransactionStatusIcon iconSize={iconSize} status={additionalData ? additionalData.status : null}></TransactionStatusIcon></div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div style={{ fontSize: 14, fontWeight: 500 }}>ID: {Id}</div>
            </Modal.Header>
            <Modal.Content scrolling>
                <Segment basic>
                    <Grid columns={2}>
                        <Grid.Column>
                            <h3>{props.data.sendingSubOrgName} {!!props.data.countryFrom ? (<small>({props.data.countryFrom})</small>) : ''} </h3>
                            <p>{props.data.addressSendingMTO}</p>
                        </Grid.Column>
                        <Grid.Column>
                            <h3>{props.data.receivingSubOrgName} {!!props.data.countryTo ? (<small>({props.data.countryTo})</small>) : ''} </h3>
                            <p>{props.data.addressReceivingMTO}</p>
                        </Grid.Column>
                    </Grid>
                    <Divider section />
                </Segment>
                <Segment>
                    <Grid columns={2} relaxed='very'>
                        <Grid.Column>
                            <GridLabel>
                                {txDetails.from && `${txDetails.from.firstName} ${txDetails.from.lastName}`}
                            </GridLabel>
                            <p>
                                <strong>{txDetails.from && txDetails.from.walletAddress}</strong>
                            </p>
                            {txDetails.from && txDetails.from.photo.length ? (
                                <Avatar photo={txDetails.from.photo} />
                            ) : (
                                    <Placeholder
                                        style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }}>
                                        <Placeholder.Image />
                                    </Placeholder>
                                )}
                        </Grid.Column>
                        <Grid.Column>
                            <GridLabel>
                                {txDetails.to && `${txDetails.to.firstName} ${txDetails.to.lastName}`}
                            </GridLabel>
                            <p>
                                <strong>{txDetails.to && txDetails.to.walletAddress}</strong>
                            </p>
                            {txDetails.to && txDetails.to.photo.length ? (
                                <Avatar photo={txDetails.to.photo} />
                            ) : (
                                    <Placeholder
                                        style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }}>
                                        <Placeholder.Image />
                                    </Placeholder>
                                )}
                        </Grid.Column>
                    </Grid>
                    <Divider vertical>{t('transaction_details_to')}</Divider>
                </Segment>
                <Segment vertical>
                    <DetailsLine width={170}>
                        <span>{t('transaction_details_data_and_time')}</span>
                        <strong>{moment(timestamp).utc().format('L LTS')}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Amount sent</span>
                        <strong>{amountFrom && currencyFrom ? `${amountFrom} ${currencyFrom}` : '-'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Fee</span>
                        <strong>
                            {amountProvision && currencyProvision ? `${amountProvision} ${currencyProvision}` : '-'}
                        </strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Amount received</span>
                        <strong>{amountTo && currencyTo ? `${amountTo} ${currencyTo}` : '-'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>{t('transaction_details_amount_crdt')}</span>
                        <strong>{amountCRDT}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Purpose of Transaction</span>
                        <strong>{props.data.reasonForSending || '-'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Source of Funds</span>
                        <strong>{props.data.sourceOfMoney || '-'}</strong>
                    </DetailsLine>
                    <Divider section />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <div style={{ display: 'block', width: '50%' }}>
                            <h3>Sending MTO Agent</h3>
                            <p>
                                <strong>
                                    {txDetails.agent && `${txDetails.agent.firstName} ${txDetails.agent.lastName}`}
                                </strong>
                            </p>
                            <p>
                                <strong>{txDetails.agent && txDetails.agent.walletAddress}</strong>
                            </p>
                            {txDetails.agent && txDetails.agent.photo.length ? (
                                <Avatar photo={txDetails.agent.photo} />
                            ) : (
                                    <Placeholder
                                        style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }}>
                                        <Placeholder.Image />
                                    </Placeholder>
                                )}
                        </div>
                        <div style={{ display: 'block', width: '50%' }}>
                            <h3>Receiving MTO Agent</h3>
                            {txDetails && txDetails.agentTo && (txDetails.agentTo.walletAddress || false) ? (
                                <React.Fragment>
                                    <p>
                                        <strong>
                                            {txDetails.agentTo &&
                                                `${txDetails.agentTo.firstName} ${txDetails.agentTo.lastName}`}
                                        </strong>
                                    </p>
                                    <p>
                                        <strong>{txDetails.agentTo && txDetails.agentTo.walletAddress}</strong>
                                    </p>
                                    {txDetails.agentTo.photo.length && <Avatar photo={txDetails.agentTo.photo} />}
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        {txDetails.agent && <em>{t('transaction_remittance_not_yet_received')}</em>}
                                        {!txDetails.agent && (
                                            <Placeholder
                                                style={{
                                                    width: 140,
                                                    height: 140,
                                                    display: 'inline-block',
                                                    verticalAlign: 'top'
                                                }}>
                                                <Placeholder.Image />
                                            </Placeholder>
                                        )}
                                    </React.Fragment>
                                )}
                        </div>
                    </div>
                </Segment>
            </Modal.Content>
        </React.Fragment>
    )
}

export default TransactionDetails
