import React  from 'react';
import {Box, Typography, Button} from "@material-ui/core";

const Setup = ({setShowScreen}) => {

    return (
        <Box className="wizard-box text-center">
            <Typography variant="h4" className="primary-color">
                <strong>OrgEverID Setup</strong>
            </Typography>
            <Typography variant="subtitle1" style={{color: "#A1A1A1", marginTop: "25px"}}>
                <strong>New Organisation Creation Process <span className="link-text">Click here to read</span></strong>
            </Typography>
            <Box style={{marginTop: "25px"}}>
                <Button className="wizard-btn primary" variant="contained" onClick={() => setShowScreen('intro')}>
                    <strong>Organization Setup</strong>
                </Button>

                <Button className="wizard-btn secondary ml-20" variant="contained" onClick={() => window.location = "/"}>
                    <strong>Sign in</strong>
                </Button>
            </Box>
        </Box>
    );
};

export default Setup;