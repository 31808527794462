import { isArray } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Icon, Loader, Message, Modal, Pagination, Segment, Table } from 'semantic-ui-react';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import TransactionsContext from '../../contexts/TransactionsContext';
import helpers, { cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import ErrorMessage from '../common/ErrorMessage';
import PageSelect from '../common/PageSelect';
import TransactionDetails from './TransactionDetails';
import {useAuth0} from "../../react-auth0-wrapper";
import {fiatTokenInAmount, fiatTokenOutAmount, transactionTypeLabel} from "../../js/common";

const TransactionsList = (props) => {
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [listPromise, setListPromise] = useState({ promise: null, source: null });
    const setTransactions = useContext(TransactionsContext);
    const { searchTerm } = useContext(SearchTermContext);
    const { itemsPerPage } = useContext(ShowResultsContext);
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const { t } = useTranslation();
    const { getIdToken } = useAuth0();
    useEffect(() => {
        return () => {
            if (listPromise.source !== null) {
                helpers.cancel(listPromise.promise, listPromise.source);
            }
        }
    }, [listPromise]);

    if (props.status === STATUS_INIT) {
        return (
            <Segment>
                <Loader active size='large' inline='centered'>{t('list_message_loading_transactions')}</Loader>
            </Segment>
        );
    }

    if (props.status === STATUS_ERROR) {
        return <ErrorMessage error={props.error} />;
    }

    if (props.status === STATUS_NO_RESULTS) {
        return (
            <Message>
                <Message.Header>{t('list_message_no_results_title')}</Message.Header>
                <p>{t('list_message_no_results_text')}</p>
            </Message>
        );
    }

    const loadingContent = (
        <Table.Row>
            <Table.Cell colSpan='9'>
                <Segment basic>
                    <Loader active size='large' inline='centered'>{t('list_message_loading_transactions')}</Loader>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );

    // pagination
    var pageNumbers = [];
    for (let x = 0; x < Math.ceil(props.totalCount / itemsPerPage); x++) {
        pageNumbers.push(x);
    }

    const resolved = (response) => {
        const { data, success } = response.data;
        if (success) {
            setTransactions({
                list: data.results,
                count: data.count,
                status: (data.results && data.results.length > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const handlePaginationChange = (event, { activePage }) => {
        setCurrentPage(activePage);
        setTransactions({
            list: -1,
            count: props.totalCount,
            status: STATUS_LOADING
        });

        if (listPromise.source !== null) {
            helpers.cancel(listPromise.promise, listPromise.source);
        }

        const options = { page: activePage, size: itemsPerPage };
        if(searchTerm) {
            options.trx_id = searchTerm;
        }
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(props.listURL, options, source, getIdToken())
            .then(resolved)
            .catch(console.error);
        setListPromise({ promise, source });
    };

    // modal: transaction details
    const onTransactionInfoClick = (event) => {
        event.preventDefault();
        if (event.target.classList.contains('info')) {
            const key = event.target.dataset.key * 1;
            const selectedTransactionData = props.list.filter(transaction =>  transaction.id === key);
            setSelectedTransaction(selectedTransactionData[0]);
            setShowModal(true);
        }
    };

    const onCloseModal = () => {
        setShowModal(false);
        setSelectedTransaction(null);
    };

    const displayWalletAddress = (value) => {
        if(!value) {
            return "";
        }
        const start = value.substring(0, 7);
        const end = value.substring(value.length - 6);
        return start+"..."+end;
    };

    const tableRows = (isArray(props.list) ? props.list : []).map((trx) => {
        return <Table.Row key={trx.id}>
            <Table.Cell textAlign="center">{trx.trx_uuid}</Table.Cell>
            <Table.Cell textAlign="center">{moment(trx.created_at).format("DD/MM/yyyy h:mm a")}</Table.Cell>
            <Table.Cell textAlign="center">{transactionTypeLabel(trx.transaction_type.type)}</Table.Cell>
            <Table.Cell textAlign="center">{fiatTokenInAmount(trx)}</Table.Cell>
            <Table.Cell textAlign="center">{fiatTokenOutAmount(trx)}</Table.Cell>
            <Table.Cell textAlign="center" title={trx.wallet_address}>{displayWalletAddress(trx.wallet_address) || "-"}</Table.Cell>
            <Table.Cell textAlign="center">{trx.transaction_status}</Table.Cell>
            <Table.Cell textAlign='center'>
                <Icon circular inverted name='info' color='blue' data-key={trx.id} className='icon-button' />
            </Table.Cell>
        </Table.Row>
    });

    return (
        <Grid padded='vertically'>
            <Grid.Column>
                <Table celled fixed singleLine compact onClick={onTransactionInfoClick}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center" width={5}>Transaction ID</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={3}>Created</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={2}>Type</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={2}>Fiat/Token In Amount</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={2}>Token/Fiat Out Amount</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={3}>Wallet Address</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={2}>Status</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={1}>{t('transaction_list_details')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {props.status === STATUS_LOADING ? loadingContent : tableRows}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='9' textAlign='center' style={{ position: 'relative' }}>
                                <Pagination
                                    activePage={currentPage}
                                    totalPages={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                    siblingRange={1}
                                    boundaryRange={1}
                                    ellipsisItem={undefined}
                                    firstItem={undefined}
                                    lastItem={undefined}
                                    prevItem={undefined}
                                    nextItem={undefined}
                                    disabled={pageNumbers.length === 1}
                                />
                                <PageSelect activePage={currentPage}
                                    pagesTotal={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Modal style={{ marginTop: '25px' }}
                    open={showModal}
                    onClose={onCloseModal}>
                    <TransactionDetails
                        metaData={props.metaData}
                        transactionSelected={props.transactionSelected}
                        setTransactionSelected={props.setTransactionSelected}
                        setEnableButtonReport={props.setEnableButtonReport}
                        data={selectedTransaction}
                        currentOrg={props.currentOrg}
                        setDataToast={props.setDataToast}
                        setShowModal={setShowModal} />
                </Modal>
            </Grid.Column>
        </Grid>
    );
};

export default TransactionsList;
