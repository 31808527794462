import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Search, Label, Input, Button, Modal, Segment} from 'semantic-ui-react';
import DetailsLine from "../common/DetailsLine";
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import helpers, {cancelToken} from "../../services/helpers";
import {useAuth0} from "../../react-auth0-wrapper";
import Web3 from "web3";

let memberSource;
export default function AddRefCode(props) {
    const getDefaultValidity = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        const validFrom = day+"-"+(month+1)+"-"+year;

        const todayPlusYears = new Date(year + 5, month, day);
        const validTo = todayPlusYears.getDate()+"-"+(todayPlusYears.getMonth()+1)+"-"+todayPlusYears.getFullYear();
        return validFrom+" - "+validTo;
    };

    const [loading, setLoading] = useState(false);
    const [agentErr, setAgentErr] = useState(false);
    const [validityErr, setValidityErr] = useState(false);
    const [refRewardErr, setRefRewardErr] = useState(false);
    const [agentRewardErr, setAgentRewardErr] = useState(false);
    const [validity, setValidity] = useState(getDefaultValidity());
    const [agentWalletAddress, setAgentWalletAddress] = useState("");
    const [user, setUser] = useState({});
    const [code, setCode] = useState("");
    const [refReward, setRefReward] = useState("");
    const [agentReward, setAgentReward] = useState("");
    const { t } = useTranslation();

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const { getIdToken } = useAuth0();

    const handleValidity = (event, {value}) => {
        setValidity(value);
    };

    const handleAgentChange = (event, value) => {
        const walletAddress = value;
        let code = "";
        if(walletAddress) {
            code = walletAddress.substr(walletAddress.length - 10);
            code = (parseInt(code, 16) * 124300.74).toString(16);
            code = code.substr(0, 10).toUpperCase();
        }
        setAgentWalletAddress(walletAddress);
        setCode(code);
    };

    const isValidAgent = () => {
        setAgentErr(false);
        if(!agentWalletAddress) {
            setAgentErr(true);
            return false;
        }
        return true;
    };

    const isValidValidity = () => {
        setValidityErr(false);
        if(!validity) {
            setValidityErr(true);
            return false;
        } else {
            const validityArr = validity.split(" - ");
            if(!validityArr[0] || !validityArr[1]) {
                setValidityErr(true);
                return false;
            }
        }
        return true;
    };

    const isValidRefReward = () => {
        setRefRewardErr(false);
        if(!refReward) {
            setRefRewardErr(true);
            return false;
        }
        const refCheck = refReward * 1;
        if(refCheck <= 0 || refCheck > 100) {
            setRefRewardErr(true);
            return false;
        }
        return true;
    };

    const isValidAgentReward = () => {
        setAgentRewardErr(false);
        if(!agentReward || agentReward > 5) {
            setAgentRewardErr(true);
            return false;
        }
        return true;
    };

    const addRefCode = () => {
        const validAgent = isValidAgent();
        const validValidity = isValidValidity();
        const validRefReward = isValidRefReward();
        const validAgentReward = isValidAgentReward();
        if(!validAgent || !validValidity || !validRefReward || !validAgentReward) {
            return;
        }
        setLoading(true);
        const validityArr = validity.split(" - ");
        let validFrom = validityArr[0];
        let validTo = validityArr[1];

        const validFromArr = validFrom.split("-");
        validFrom = new Date(validFromArr[2]+"-"+validFromArr[1]+"-"+validFromArr[0]).toISOString();
        validFrom = validFrom.replace(".000", "");
        const validToArr = validTo.split("-");
        validTo = new Date(validToArr[2]+"-"+validToArr[1]+"-"+validToArr[0]).toISOString();
        validTo = validTo.replace(".000", "");

        const owner = user.uuid;
        const web3 = new Web3();
        const req = {
            code,
            walletAddress: agentWalletAddress,
            ownerReferredTxPercentReward: agentReward,
            rewardForRegistered: web3.utils.toWei(refReward, "ether"),
            currency: "ID",
            // properties: [],
            owner,
            validFrom,
            validTo
        };

        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0.createRefCode(req, token, source).then(response => {
            setLoading(false);
            if(response.data.success) {
                props.setDataToast({ open: true, type: "info", message: "Referral Code has been created successfully.", timeAutoHide: 6000 });
                props.getList();
                props.setShowModal(false);
            } else {
                window.alert("Something went wrong");
            }
        }).catch(error => {
            setLoading(false);
            const resp = JSON.parse(error.request.response);
            if(resp.code.message === "ENTRY_ALREADY_EXISTS") {
                props.setDataToast({ open: true, type: "error", message: "Referral code for this user is already existed.", timeAutoHide: 6000 });
            } else {
                window.alert(resp.code.message);
            }
        });
    };

    const handleSearchChange = (e, data) => {
        setSearchValue(data.value);
        if (data.value.length === 0) {
            setSearchLoading(false);
            setSearchResults([]);
            setSearchValue("");
            handleAgentChange(e, "");
            return;
        } else if(data.value.length <= 2) {
            return;
        }

        setSearchLoading(true);
        if (typeof memberSource !== "undefined") {
            memberSource.cancel('Operation canceled by the user.')
        }
        const token = getIdToken();
        memberSource = cancelToken.source();
        helpers.auth0.getMembers(token, memberSource, data.value)
            .then((resp) => {
                if(resp.data.success) {
                    let memberArr = [];
                    const memberList = resp.data.memberList;
                    for(let i = 0; i < memberList.length; i++) {
                        const currentMember = memberList[i];
                        memberArr.push({
                            address: currentMember.account.address,
                            uuid: currentMember.profile.uuid,
                            title: currentMember.profile.firstName+' '+currentMember.profile.lastName
                        });
                    }
                    setSearchLoading(false);
                    setSearchResults(memberArr);
                }
            })
            .catch(console.error);
    };

    const resultRenderer = ({ title }) => <Label content={title} />

    return (
        <React.Fragment>
            <Modal.Header>
                {t('add_referral_code')}
            </Modal.Header>
            <Modal.Content>
                <Segment vertical>
                    <DetailsLine>
                        <span className="ref-add-label">{t('select_user')}</span>
                        <Search
                            className={`member-search ${agentErr && " error"}`}
                            loading={searchLoading}
                            onResultSelect={(e, data) => {
                                setSearchValue(data.result.title);
                                setUser(data.result);
                                handleAgentChange(e, data.result.address);
                            }}
                            onSearchChange={handleSearchChange}
                            resultRenderer={resultRenderer}
                            results={searchResults}
                            value={searchValue}
                            minCharacters={3}
                            onSelectionChange={() => alert("change")}
                            style={{display: "inline"}}
                            onBlur={isValidAgent}
                        />
                    </DetailsLine>
                </Segment>
                <Segment vertical>
                    <DetailsLine>
                        <span className="ref-add-label">{t('code')}</span>
                        <Input
                            value={code}
                            disabled
                        />
                    </DetailsLine>
                    <DetailsLine>
                        <span className="ref-add-label">{t('user_wallet_address')}</span>
                        <Input
                            value={agentWalletAddress}
                            style={{width: '400px'}}
                            disabled
                        />
                    </DetailsLine>
                </Segment>
                <Segment vertical>
                    <DetailsLine className="code-generate-date-range">
                        <span className="ref-add-label">{t('validity')}</span>
                        <DatesRangeInput
                            error={validityErr}
                            name="datesRange"
                            placeholder="From - To"
                            pickerStyle={{display: "inline"}}
                            value={validity}
                            iconPosition="left"
                            clearable={true}
                            markColor={"bad"}
                            onChange={handleValidity}
                            onBlur={isValidValidity}
                        />
                    </DetailsLine>
                </Segment>
                <Segment vertical>
                    <DetailsLine className="code-generate-date-range">
                        <span className="ref-add-label">{t('ref_reward')}</span>
                        <Input
                            error={refRewardErr}
                            value={refReward}
                            label={{ basic: true, content: 'ID' }}
                            labelPosition='right'
                            onChange={(event, {value}) => { +value === +value && value <= 100 && setRefReward(value) }}
                            onBlur={isValidRefReward}
                            title={"Maximum Value of Referral Reward is 100 ID"}
                        />
                        <span className="ref-add-label" style={{marginLeft: '10px'}}>{t('user_reward')}</span>
                        <Input
                            error={agentRewardErr}
                            value={agentReward}
                            label={{ basic: true, content: '%' }}
                            labelPosition='right'
                            onChange={(event, {value}) => { +value === +value && value <= 5 && setAgentReward(value) }}
                            onBlur={isValidAgentReward}
                            title={"Maximum Value of User's Reward is 5%"}
                        />
                    </DetailsLine>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button positive disabled={loading} loading={loading} onClick={addRefCode}>
                    Add
                </Button>
                <Button onClick={() => props.setShowModal(false)} style={{ backgroundColor: '#fa4261', color: 'white' }}>
                    Close
                </Button>
            </Modal.Actions>
        </React.Fragment>
    );
}