import React from 'react';
import { useAuth0 } from '../../react-auth0-wrapper';
import { Button, Segment, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import packageJson from '../../../package.json';

export default function Login() {
    const { t } = useTranslation();
    const { loginWithRedirect, loading } = useAuth0();
    const { version } = packageJson;

    return (
        <Container>
            {loading ? (
                <div>
                    <Segment basic>
                        <Loader active size='large' inline='centered'>{t`popup_loading`}</Loader>
                    </Segment>
                </div>
            ) : (
                    <div className="tftl-01-out-more">
                        <div className="out-more" data-anchor="#find-out-more">
                            <div className="subblock out-more__subblock">
                                <div className="subblock__content">
                                    <div className="subblock__h2"><p>To use Admin Console, you need<br />to login to your Everest account.</p></div>
                                    <div className="subblock__info" data-split-lines=""><span className="line">Everest is the world’s only device-free globally </span><span className="line">accessible, digital transaction protocol with built-in </span><span className="line">identity. </span></div>
                                </div>
                            </div>
                            <Button className='btn-login' size='big' onClick={() => loginWithRedirect({ redirect_uri: window.location.href })}>Log in</Button>
                        </div>
                        <div className="cube">
                            <div className="cube__small">
                                <img src={require('../../assets/img/cube-small.svg')} />
                                <img src={require('../../assets/img/cube-small.svg')} />
                                <img src={require('../../assets/img/cube-small.svg')} />
                            </div>
                            <img src={require('../../assets/img/new_CUBE_BIG_new2.svg')} />
                        </div>
                        <div className="footer">
                            <div className="subblock__info" data-split-lines=""><span style={{ color: 'white' }}>{t`admin_console_version`}{version}</span></div>
                        </div>
                    </div>
                )}
        </Container>
    );
}

const Container = styled.div`

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;      

  .loading {
  }

  .tftl-01-out-more {
    background: linear-gradient(360deg, #1b2950 0, #293f66 46.51%, #293f66 67.89%);
    position: relative;
    z-index: 20
    padding-top: 200px;
    height: 100vh;
    width: 100vw;      
  }

 .cube {
    position: absolute;
    right: 0;
    top:-250px;
    height: 100vh;
    width: 60%;
    text-align: center
  }

 .cube > img {
    -webkit-animation: fly 4s infinite;
    animation: fly 4s infinite
  }

 .cube__small img:nth-child(1) {
    position: absolute;
    top: 600px;
    left: 20px;
    -webkit-animation: fly2 6s infinite;
    animation: fly2 6s infinite
  }

 .cube__small img:nth-child(2) {
    position: absolute;
    top: 600px;
    right: 0;
    -webkit-animation: fly2 4s infinite;
    animation: fly2 4s infinite
  }

 .cube__small img:nth-child(3) {
    position: absolute;
    bottom: 300px;
    left: 20px;
    -webkit-animation: fly2 8s infinite;
    animation: fly2 8s infinite
  }

 .out-more {
    background: rgba(43, 68, 107, .7);
    box-shadow: 0 10px 12px rgba(0, 0, 0, .1);
    border-radius: 8px;
    width: 45vw;
    height: 45vh;
    position: relative;
    padding: 70px 103px 72px 326px;
    font-size: 0;
    z-index: 20;
    padding-bottom: 50px;
    padding-left: 113px
  }

 .out-more:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #2b446b;
    border-radius: 8px;
    z-index: 0
  }

 .out-more:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(27, 43, 80, 0) 0, #22e0ce 100%);
    border-radius: 8px;
    z-index: -1
  }

 .out-more__button {
    width: 292px;
    height: 48px;
    background: #fa4261;
    border-radius: 6px;
    line-height: 23px;
    font-size: 15px;
    text-align: center;
    letter-spacing: .02em;
    color: #fff;
    display: inline-block;
    padding-top: 12px;
    margin-bottom: 40px;
    position: relative;
    z-index: 2
  }

 .out-more__button:last-child {
    margin-right: 0
  }

 .subblock {
    max-width: 1216px;
    height: auto;
    text-align: left;
    margin: auto;
    margin-bottom: 60px;
    padding-top: 0
  }

 .subblock__content {
    min-width: 697px;
    margin: auto 0;
    display: inline-block;
    position: relative;
    z-index: 2
  }

 .subblock__h2 {
    line-height: 35px;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: .02em;
    color: #fff;
    max-width: 45vw;
    text-align: left;
    margin-bottom: 16px;
    position: relative;
    z-index: 2
  }

 .subblock__info {
    line-height: 201%;
    font-size: 16px;
    letter-spacing: .02em;
    color: #fff;
    font-weight: 300;
    max-width: 386px;
    text-align: left;
    position: relative;
    z-index: 2
  }

  .cube > img {
    margin-top: 200px;
    height: 100vh;
  }

  .btn-login, .btn-login:hover {
    background: #fa4261;
    color: #fff;
    display: inline-block;
    position: relative;
    z-index: 2
  }
 
.footer {
position: absolute;
margin-top: 145px;
left: 15px;
    width: 100%;
}

`;
