import React, {useState, useRef, useEffect}  from 'react';
import {Box, Typography, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, CircularProgress, Checkbox} from "@material-ui/core";
import NavBar from "./navBar";
import Step from "./step";
import {cancelToken} from "../../services/helpers";
import helpers from "../../services/helpers";

const Page5 = ({orgData, setShowScreen, setDataToast, setOrgData, orgObject}) => {

    const countries = [
        { code: 'AU', code3: 'AUS', label: 'Australia' },
        { code: 'GE', code3: 'GEO', label: 'Georgia' },
        { code: 'PK', code3: 'PAK', label: 'Pakistan' },
        { code: 'PL', code3: 'POL', label: 'Poland' },
        { code: 'US', code3: 'USA', label: 'United States' },
    ];

    const[acceptInfoConfirm, setAcceptInfoConfirm] = useState(false);
    const[loading, setLoading] = useState(false);

    const submitData = () => {
        const storeObj = orgData;

        storeObj.address = {
            street: orgData.legalAddressStreet,
            city: orgData.legalAddressCity,
            state: orgData.legalAddressState,
            postal: orgData.legalAddressPostal,
            country: orgData.legalAddressCountry,
        };

        storeObj.hqAddress = {
            street: orgData.hqAddressStreet,
            city: orgData.hqAddressCity,
            state: orgData.hqAddressState,
            postal: orgData.hqAddressPostal,
            country: orgData.hqAddressCountry,
        };

        if(orgData.otherAddressStreet) {
            storeObj.otherAddress = {
                street: orgData.otherAddressStreet,
                city: orgData.otherAddressCity,
                state: orgData.otherAddressState,
                postal: orgData.otherAddressPostal,
                country: orgData.otherAddressCountry,
            };
        }

        if(orgData.transAddressStreet) {
            storeObj.transAddress = {
                street: orgData.transAddressStreet,
                city: orgData.transAddressCity,
                state: orgData.transAddressState,
                postal: orgData.transAddressPostal,
                country: orgData.transAddressCountry,
            };
        }

        const source = cancelToken.source();
        setLoading(true);
        helpers.createOrgProfile(source, storeObj)
        .then(resp => {
            setLoading(false);
            if(resp.data.success) {
                setShowScreen("setup");
                setDataToast({ open: true, variant: "success", message: "Organization has been saved successfully.", timeAutoHide: 6000 });
            } else {
                setDataToast({ open: true, variant: "error", message: resp.data.error, timeAutoHide: 6000 });
            }
        }).catch(error => {
            setLoading(false);
            const resp = JSON.parse(error.request.response);
            const msg = resp.error ? resp.error : resp.message;
            setDataToast({ open: true, variant: "error", message: msg, timeAutoHide: 6000 });
        });
    };

    return (
        <Box>
            <NavBar title={"OrgEverID Setup"} backHandler={() => setShowScreen('page4')} setShowScreen={setShowScreen} />
            <Box className="wizard-box">
                <Box className="text-center">
                    <Step totalSteps={5} currentStep={5} />
                </Box>
                <Box className="terms-and-conditions">
                    <Grid container spacing={2} style={{marginTop: "5px"}}>
                        <Grid item={true} xs={12}>
                            <TextField
                                name="adminSet"
                                variant="outlined"
                                label="Admin Set"
                                placeholder="EverID address"
                                value={orgData.adminSet}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="adminSet2"
                                variant="outlined"
                                label="Admin Set 2"
                                placeholder="EverID address"
                                value={orgData.adminSet2}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="name"
                                variant="outlined"
                                label="Org Name"
                                placeholder="Organization Legal Name"
                                value={orgData.name}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="otherName"
                                variant="outlined"
                                label="Org Other Name (optional)"
                                placeholder="Organization Nickname or AKA"
                                value={orgData.otherName}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <Typography variant="h6" className="font-bold">Org Legal Address</Typography>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="legalAddressStreet"
                                variant="outlined"
                                label="Street Number Street Name"
                                placeholder="Street Number Street Name"
                                value={orgData.legalAddressStreet}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="legalAddressState"
                                variant="outlined"
                                label="State"
                                placeholder="State / Province"
                                value={orgData.legalAddressState}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="legalAddressPostal"
                                variant="outlined"
                                label="Postal"
                                placeholder="Postal Code"
                                value={orgData.legalAddressPostal}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Select country</InputLabel>
                                <Select
                                    name="legalAddressCountry"
                                    value={orgData.legalAddressCountry}
                                    label="Select country"
                                    disabled={true}
                                >
                                    {countries.map((val, index) =>
                                        <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>




                        <Grid item={true} xs={6} style={{marginTop: "5px"}}>
                            <Typography variant="h6" className="font-bold">Org HQ Address</Typography>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="hqAddressStreet"
                                variant="outlined"
                                label="Street Number Street Name"
                                placeholder="Street Number Street Name"
                                value={orgData.hqAddressStreet}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="hqAddressState"
                                variant="outlined"
                                label="State"
                                placeholder="State / Province"
                                value={orgData.hqAddressState}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="hqAddressPostal"
                                variant="outlined"
                                label="Postal"
                                placeholder="Postal Code"
                                value={orgData.hqAddressPostal}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Select country</InputLabel>
                                <Select
                                    name="hqAddressCountry"
                                    value={orgData.hqAddressCountry}
                                    label="Select country"
                                    disabled={true}
                                >
                                    {countries.map((val, index) =>
                                        <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item={true} xs={6} style={{marginTop: "5px"}}>
                            <Typography variant="h6" className="font-bold">Org Other Address (optional)</Typography>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="otherAddressStreet"
                                variant="outlined"
                                label="Street Number Street Name"
                                placeholder="Street Number Street Name"
                                value={orgData.otherAddressStreet}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="otherAddressState"
                                variant="outlined"
                                label="State"
                                placeholder="State / Province"
                                value={orgData.otherAddressState}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="otherAddressPostal"
                                variant="outlined"
                                label="Postal"
                                placeholder="Postal Code"
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Select country</InputLabel>
                                <Select
                                    name="otherAddressCountry"
                                    value={orgData.otherAddressCountry}
                                    label="Select country"
                                    disabled={true}
                                >
                                    {countries.map((val, index) =>
                                        <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item={true} xs={6} style={{marginTop: "5px"}}>
                            <Typography variant="h6" className="font-bold">Transliterated Other Address (optional)</Typography>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="transAddressStreet"
                                variant="outlined"
                                label="Street Number Street Name"
                                placeholder="Street Number Street Name"
                                value={orgData.transAddressStreet}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="transAddressState"
                                variant="outlined"
                                label="State"
                                placeholder="State / Province"
                                value={orgData.transAddressState}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                name="transAddressPostal"
                                variant="outlined"
                                label="Postal"
                                placeholder="Postal Code"
                                value={orgData.transAddressPostal}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Select country</InputLabel>
                                <Select
                                    name="transAddressCountry"
                                    value={orgData.otherAddressCountry}
                                    label="Select country"
                                    disabled={true}
                                >
                                    {countries.map((val, index) =>
                                        <MenuItem key={index} value={val.label}>{val.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>



                        <Grid item={true} xs={12}>
                            <TextField
                                name="url"
                                variant="outlined"
                                label="Organization URL"
                                placeholder="Organization URL"
                                value={orgData.url}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="phone"
                                variant="outlined"
                                label="Organization Telephone"
                                placeholder="Org Full International Phone"
                                value={orgData.phone}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <TextField
                                name="fax"
                                variant="outlined"
                                label="Organization Fax (optional)"
                                placeholder="Org Full International Fax"
                                value={orgData.fax}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                </Box>

                <Box className="text-center">
                    <FormControlLabel style={{color: "#00B171"}} control={<Checkbox onChange={() => setAcceptInfoConfirm(!acceptInfoConfirm)} style={{color: "#00B171"}} />} label={<Typography variant="caption" className="font-bold" style={{color: "#A1A1A1"}}>
                        I confirm that the information provided is complete and accurate.
                    </Typography>} />
                </Box>
                <Button className="wizard-btn secondary font-bold mt-20" variant="contained" onClick={() => setShowScreen('page1')} fullWidth>Edit Details</Button>
                <Button className="wizard-btn primary font-bold mt-20" variant="contained" onClick={submitData} disabled={!acceptInfoConfirm} fullWidth>
                    {loading ? <><CircularProgress size={15} color="inherit" />&nbsp;Submitting...</> : "Submit"}
                </Button>
            </Box>
        </Box>
    );
};

export default Page5;