import React from 'react'
import CashIoDetail from './CashIoDetail'
import RemittanceDetail from './RemittanceDetail'

const TransactionDetails = (props) => {
    const { additionalData } = props.data;

    if(additionalData.type === "cashio") {
        return (<CashIoDetail {...props}/>)
    } else {
        return (<RemittanceDetail {...props}/>)
    }
};

export default TransactionDetails
