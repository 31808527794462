import React, { useEffect, useState } from 'react';
import {Button, Grid, Modal} from 'semantic-ui-react';
import RefCodesContext from '../../contexts/RefCodesContext';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import RefCodesList from './RefCodesList';
import AddRefCode from './AddRefCode';
import {useTranslation} from "react-i18next";
import MembersList from "../members/MembersList";


const RefCodesProvider = (updateOrg) => {
    const [referralCodes, setReferralCodes] = useState({ list: undefined, count: 0, status: STATUS_INIT });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const { getIdToken, logout } = useAuth0();
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    const SEARCH_URL = '/v3/admin/restricted/organization/search_referral_code_index';
    const urlParams = { page: 1, limit: itemsPerPage, sort: 'createdAt' };
    const resolved = (response) => {
        const { data, success } = response.data;
        if (success) {
            setReferralCodes({
                list: data,
                count: data.length,
                status: (data && data.length && data.length > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        };
    };

    const rejected = (error) => {
        if (error.message !== cancelMessage) {
            setReferralCodes({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setReferralCodes({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    const onCloseModal = () => {
        setShowModal(false);
    };

    const referralCodeModal = () => {
        setShowModal(true);
    };

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        refreshList({ statusText: STATUS_LOADING });
        const source = cancelToken.source();
        const promise = helpers.auth0
            .refCodeList(getIdToken(), source)
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    if (referralCodes.error && referralCodes.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }
    return (
        <React.Fragment>
            <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
                <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                        <RefCodesContext.Provider value={setReferralCodes}>

                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        {/*<SearchBar onSearchTermChange={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} from={"refCode"} />*/}
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <Button type="button" onClick={referralCodeModal} style={{backgroundColor: '#fa4261', color: 'white', padding: 8}}>
                                            {t('add_referral_code')}
                                        </Button>
                                        {/*<ShowResults onListRefresh={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} from={"refCode"} />*/}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {
                                <RefCodesList
                                    status={referralCodes.status}
                                    list={referralCodes.list}
                                    listURL={SEARCH_URL}
                                    totalCount={referralCodes.count}
                                    sortByColumn='code'
                                    sortDirection='ascending'
                                    error={referralCodes.error}
                                    currentOrg={updateOrg.currentOrg}
                                    setCurrentOrg={updateOrg.setCurrentOrg}
                                    setShowModal={setShowModal}
                                    setDataToast={updateOrg.setDataToast}
                                    getList={getList}
                                />
                            }
                            <Modal open={showModal} onClose={onCloseModal}>
                                <AddRefCode
                                    setShowModal={setShowModal}
                                    setDataToast={updateOrg.setDataToast}
                                    getList={getList}
                                />
                            </Modal>
                        </RefCodesContext.Provider>
                    </PageContext.Provider>
                </ShowResultsContext.Provider>
            </SearchTermContext.Provider>
        </React.Fragment>
    );
};

export default RefCodesProvider;
