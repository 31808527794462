import React, { useEffect, useState } from 'react';
import { Grid, Dropdown } from 'semantic-ui-react';
import ReportsContext from '../../contexts/ReportsContext';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import ReportsList from './ReportsList';
import MultipleFilter from '../common/MultipleFilter';

const ReportsProvider = (updateOrg) => {
    const [reports, setReports] = useState({ list: undefined, count: 0, status: STATUS_INIT });

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const { logout } = useAuth0();
    const SEARCH_URL = '/v3/admin/organization/search_report_index';
    const urlParams = { page: 1, limit: itemsPerPage, sort: 'createdAt' };
    const resolved = (response) => {
        const { reportList, reportCount, success } = response.data;
        if (success === true) {
            console.log("RepList",reportList);
            setReports({
                list: reportList,
                count: reportCount,
                status: (reportList && reportList.length && reportCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const rejected = (error) => {
        console.log("Reject",error);

        if (error.message !== cancelMessage) {
            setReports({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setReports({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    useEffect(() => {
        refreshList({ statusText: STATUS_LOADING });
        const source = cancelToken.source();
        const promise = helpers
            .search(SEARCH_URL, urlParams, source)
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    }, [updateOrg]);

    if (reports.error && reports.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }
    return (
        <>
            <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
                <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                        <ReportsContext.Provider value={setReports}>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={13}>
                                        <MultipleFilter
                                            rejected={rejected}
                                            searchUrl={SEARCH_URL}
                                            urlParams={urlParams}
                                            refreshList={refreshList} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='right' width={3}>
                                        <ShowResults onListRefresh={refreshList}
                                         onFetchError={rejected}
                                          listURL={SEARCH_URL}
                                           urlParams={urlParams} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <ReportsList
                                status={reports.status}
                                list={reports.list}
                                listURL={SEARCH_URL}
                                totalCount={reports.count}
                                error={reports.error}
                            />

                        </ReportsContext.Provider>
                    </PageContext.Provider>
                </ShowResultsContext.Provider>
            </SearchTermContext.Provider>
        </>
    );
};

export default ReportsProvider;
