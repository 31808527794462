import React, { useEffect, useState } from 'react';
import {Container, Box, Grid, Typography} from "@material-ui/core";

const ProfileRow = ({label, value}) => {
    // style={{padding: "10px"}}
    return (
        <>
            <Grid item={true} lg={6}>{label}</Grid>
            <Grid item={true} xs={6}>{value}</Grid>
        </>
    );
};

const OrgProfile = ({currentOrg, setNameTitleModule}) => {

    setNameTitleModule('org-profile');
    useEffect(() => {
        const orgData = getSelectedOrgData(currentOrg);
        setData(orgData);
    }, [currentOrg]);
    const [data, setData] = useState({});

    const getSelectedOrgData = (orgName) => {
        let orgData = {
            everWallet: "", name: "Identity Network Foundation", otherName: "INF", legalAddress: "9245 Twin Trails Dr. #721963   San Diego, CA 92129 USA",
            hqAddress: "9245 Twin Trails Dr. #721963   San Diego, CA 92129 USA", otherAddress: "n/a", transOtherAddress: "n/a", url: "https://identitynetworkfoundation.org/",
            phone: "+1-858-333-7784", fax: " n/a", category: "NGO", lei: "n/a", type: "n/a", legalJuri: "SC", legalReg: "SC", associatedEntity: "Everest Network Foundation",
            entityStatus: "Active", entityExpDate: "n/a", entityExpReason: "n/a", sucEntity: "n/a", initialRegDate: "n/a", lastUpdateDate: "n/a", regStatus: "Active",
            nextRenewalDate: "n/a", parentOrgs: "n/a", childOrgs: "n/a", adminSet: "0x9bd52f490ac5f34824b52cddd8c4d9b83973114b; 0x1d8dc55697caa545241fa411533b9efde03562ea",
        };
        if(orgName === "ENA") {
            orgData.name = "Everest Network Pty Ltd";
            orgData.otherName = "Everest";
            orgData.addrlegalAddressess = "UNIT 301 27-39 ABERCROMBIE ST, CHIPPENDALE NSW 2008 , AUSTRALIA";
            orgData.hqAddress = "Level G, (Office 1/1165), Quantum House, 75, Abate Rigord Street, Ta’Xbiex XBX  1120   Malta ";
            orgData.otherAddress = "PO Box 721963  San Diego, CA 92172";
            orgData.url = "www.everest.org";
            orgData.phone = " +1-858-333-7784";
            orgData.category = "LTD";
            orgData.lei = "984500C3A855AC77FE74";
            orgData.type = "Q82Q";
            orgData.legalJuri = "AU";
            orgData.legalReg = "AU";
            orgData.associatedEntity = "Everest Network Ltd";
            orgData.entityExpDate = "10 March 2024";
            orgData.initialRegDate = "9 March 2019";
            orgData.lastUpdateDate = "9 March 2022";
            orgData.nextRenewalDate = "10 March 2024";
            orgData.childOrgs = "Everest Network Pty Ltd";
        } else if(orgName === "BANK OF AMERICA") {
            orgData.adminSet = "0xd23b5b4e74c8d4d650e5635526707a110959b92f; 0x87282674684a375ef7b0ee02ad694fdcb5ee3ad9";
            orgData.name = "Bank of America";
            orgData.otherName = "B of A";
            orgData.legalAddress = "100 North Tryon Street Charlotte, NC 28255";
            orgData.hqAddress = "100 North Tryon Street Charlotte, NC 28255";
            orgData.url = "https://bankofamerica.com";
            orgData.phone = " +1-800-432-1000";
            orgData.category = "C Corporation";
            orgData.lei = "9DJT3UXIJIZJI4WXO774";
            orgData.type = "XTIQ";
            orgData.legalJuri = "US";
            orgData.legalReg = "US";
            orgData.associatedEntity = "Banc of America";
            orgData.entityExpDate = "10 March 2024";
            orgData.initialRegDate = "6 June 2012";
            orgData.lastUpdateDate = "15 October 2021";
            orgData.nextRenewalDate = "8 September 2022";
            orgData.childOrgs = "Banc of America Securities";
        } else if(orgName === "ENM") {
            orgData.adminSet = "0x9bd52f490ac5f34824b52cddd8c4d9b83973114b; 0x1d8dc55697caa545241fa411533b9efde03562ea";
            orgData.name = "Everest Network Ltd";
            orgData.otherName = "Everest";
            orgData.legalAddress = "Level G, (Office 1/1165), Quantum House, 75, Abate Rigord Street, Ta’Xbiex XBX  1120   Malta ";
            orgData.hqAddress = "Level G, (Office 1/1165), Quantum House, 75, Abate Rigord Street, Ta’Xbiex XBX  1120   Malta ";
            orgData.url = "www.everest.org";
            orgData.phone = "  +1-858-333-7784";
            orgData.category = "LTD";
            orgData.lei = "984500C3A855AC77FE74";
            orgData.type = "V89C";
            orgData.legalJuri = "MT";
            orgData.legalReg = "MT";
            orgData.associatedEntity = "Everest Network Pty Ltd";
            orgData.entityExpDate = "10 March 2024";
            orgData.initialRegDate = "9 March 2019";
            orgData.lastUpdateDate = "9 March 2022";
            orgData.nextRenewalDate = "10 March 2024";
            orgData.childOrgs = "Everest Network Pty Ltd";
        }
        return orgData;
    };

    return (
        <Container component="main" maxWidth="lg" style={{background: 'white', marginTop: '60px'}}>
            <Box style={{padding: '30px 0px'}}>
                <Typography variant="h4" style={{textAlign: 'center'}}>Profile</Typography>
                <Grid style={{paddingTop: '18px', fontSize: '22px'}} container>
                    <ProfileRow label={"Org EverWallet:"} value={data.everWallet} />
                    <ProfileRow label={"Admin Set: (≥2 EverID addresses)"} value={data.adminSet} />
                    <ProfileRow label={"Org Name:"} value={data.name} />
                    <ProfileRow label={"Org Other Name:"} value={data.otherName} />
                    <ProfileRow label={"Org Legal Address:"} value={data.legalAddress} />
                    <ProfileRow label={"Org HQ Address:"} value={data.hqAddress} />
                    <ProfileRow label={"Org Other Address:"} value={data.otherAddress} />
                    <ProfileRow label={"Transliterated Other Address:"} value={data.transOtherAddress} />
                    <ProfileRow label={"Organization URL:"} value={data.url} />
                    <ProfileRow label={"Organization Telephone:"} value={data.phone} />
                    <ProfileRow label={"Organization Fax:"} value={data.fax} />
                    <ProfileRow label={"Organization Categorization: [Family, Neighborhood, Community, Sole Proprietorship, Partnership (SubType: LP, LLP, LLLP), Company, Corporation (SubType: LLC, LTD, C, S, Nonprofit), Co-op, Trust, Government, NGO, Educational]"} value={data.category} />
                    <ProfileRow label={"Organization LEI: [from GLEIF]"} value={data.lei} />
                    <ProfileRow label={"Organization Type: [entity legal form in LEI] see This"} value={data.type} />
                    <ProfileRow label={"Legal Jurisdiction: See This and This"} value={data.legalJuri} />
                    <ProfileRow label={"Legal Registration: See This  and This"} value={data.legalReg} />
                    <ProfileRow label={"Associated Entity:"} value={data.associatedEntity} />
                    <ProfileRow label={"Entity Status:"} value={data.entityStatus} />
                    <ProfileRow label={"Entity Expiration Date:"} value={data.entityExpDate} />
                    <ProfileRow label={"Entity Expiration Reason:"} value={data.entityExpReason} />
                    <ProfileRow label={"Successor Entity:"} value={data.sucEntity} />
                    <ProfileRow label={"Initial Registration Date:"} value={data.initialRegDate} />
                    <ProfileRow label={"Last Update Date:"} value={data.lastUpdateDate} />
                    <ProfileRow label={"Registration Status:"} value={data.regStatus} />
                    <ProfileRow label={"Next Renewal Date:"} value={data.nextRenewalDate} />
                    <ProfileRow label={"Parent Org(s):"} value={data.parentOrgs} />
                    <ProfileRow label={"Child Org(s):"} value={data.childOrgs} />
                </Grid>
            </Box>
        </Container>
    );
};

export default OrgProfile;