import React, { useEffect, useState } from 'react';
import {Container, Box, CircularProgress, Typography, TextField, Button, Grid} from "@material-ui/core";
import {cancelToken} from "../../services/helpers";
import helpers from "../../services/helpers";
import {useAuth0} from "../../react-auth0-wrapper";



const Dashboard = ({currentOrg, setDataToast, setNameTitleModule}) => {

    setNameTitleModule('Dashboard');
    const [userLoader, setUserLoader] = useState(true);
    const [searchLoader, setSearchLoader] = useState(false);
    const [searchResult, setSearchResult] = useState({userFound: false, isKyc: false});
    const [userCount, setUserCount] = useState(true);
    const [search, setSearch] = useState('');
    const { getIdToken } = useAuth0();

    useEffect(() => {
        getUserCount();
    }, []);

    const getUserCount = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .partnerUserCount(token, source)
            .then(resp => {
                setUserLoader(false);
                setUserCount(resp.data.data);
            })
            .catch((error) => {
                setUserLoader(false);
                console.log(error);
            });
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const userSearch = () => {
        const data = {userFound: false, isKyc: false};
        setSearchResult(data);
        if(!search) {
            setDataToast({ open: true, variant: "error", message: "Please provide wallet address in search bar", timeAutoHide: 6000 });
            return false;
        }
        setSearchLoader(true);
        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0
            .partnerUserSearch(token, source, search)
            .then(resp => {
                setSearchLoader(false);
                if(resp.data.success) {
                    const data = {userFound: resp.data.data.is_user_found, isKyc: resp.data.data.is_kyc_user};
                    setSearchResult(data);
                } else {
                    alert("Something went wrong");
                }
            })
            .catch((error) => {
                setSearchLoader(false);
                if (error.response.status !== 404) {
                    const myObject = JSON.parse(error.request.response);
                    setDataToast({ open: true, variant: "error", message: myObject.error, timeAutoHide: 6000 });
                }
            });
    };

    return (
        <Container component="main" maxWidth="lg" style={{background: 'white', marginTop: '60px'}}>
            <Box style={{padding: '30px 0px'}}>
                <Typography variant="h3" style={{textAlign: 'center'}}>Dashboard</Typography>
                <Typography variant="h4" style={{textAlign: 'center', marginTop: '20px'}}>Total Users: {userLoader ? <CircularProgress size={30} /> : userCount}</Typography>
                <Box className="text-center mt-20">
                    <Grid container spacing={2}>
                        <Grid item={true} xs={4} />
                        <Grid item={true} xs={4} style={{textAlign: 'right'}}>
                            <TextField
                                name="Search"
                                variant="outlined"
                                label="Search"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item={true} xs={4} style={{textAlign: 'left'}}>
                            <Button className="primary font-bold" style={{padding: '15px'}} variant="contained" onClick={userSearch}>Submit</Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box className="text-center mt-20">
                    {searchLoader ?
                        <CircularProgress size={60} /> :
                        <Grid container spacing={2}>
                            <Grid item={true} xs={6} style={{textAlign: 'center'}}>
                                <Typography variant="h4" style={{textAlign: 'center', marginTop: '20px'}}>User Found: {searchResult.userFound ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}</Typography>
                            </Grid>
                            <Grid item={true} xs={6} style={{textAlign: 'center'}}>
                                <Typography variant="h4" style={{textAlign: 'center', marginTop: '20px'}}>User KYC: {searchResult.isKyc ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}</Typography>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Box>
        </Container>
    );
};

export default Dashboard;