import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Modal } from 'semantic-ui-react';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import TransactionsContext from '../../contexts/TransactionsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import Toast from '../common/Toast';
import GenerateReport from './GenerateReport';
import TransactionsList from './TransactionsList';

const TransactionsProvider = (updateOrg) => {
    const [transactions, setTransactions] = useState({ list: undefined, count: 0, status: STATUS_INIT });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [transactionSelected, setTransactionSelected] = useState([]);
    // const [getTemp, setTemp] = useState([]);
    const [enableButtonReport, setEnableButtonReport] = useState(true);
    const { t } = useTranslation();
    const { getIdToken, logout } = useAuth0();
    const SEARCH_URL = '/v3/admin/restricted/organization/search_remittance_index';
    const urlParams = { page: 1, limit: itemsPerPage, from: '', to: '', sort: 'timestamp', direction: "DESC" };
    // const SEARCH_URLTemp = '/v3/mobile/everid/temporalRemittance/list';
    // const urlParamsTemp = itemsPerPage;
    //const statusMap = ["COMPLETED", "REJECTED", "INCOMPLETE", "REVIEW"];

    const resolved = (response) => {
        const { remittancesList, remittancesCount, success } = response.data;
        if (success === true) {
            setTransactions({
                list: remittancesList,
                count: remittancesCount,
                status: (remittancesList && remittancesList.length > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
            // const source = cancelToken.source();
            // const promise = helpers
            //     .searchTemp(SEARCH_URLTemp,urlParamsTemp, source)
            //     .then(response=>{
            //
            //         const documents=[];
            //         for (var i = 0; i < response.data.length; i++) {
            //             const tempList ={
            //                 phone:response.data[i].phone,
            //                 addressMemberFrom:response.data[i].addressMemberFrom, addressMemberTo:"", addressReceivingMTO:"",
            //                 addressSendingMTO:"",amountCRDT:response.data[i].amountCRDT, amountFrom:response.data[i].amountFrom,
            //                 amountProvision:response.data[i].amountProvision, amountTo:response.data[i].amountTo,
            //                 countryFrom:response.data[i].countryFrom, countryTo:response.data[i].countryTo,
            //                 currencyFrom:response.data[i].currencyFrom, currencyProvision:response.data[i].currencyProvision,
            //                 currencyTo:response.data[i].currencyTo, reasonForSending:response.data[i].reasonForSending,
            //                 receivingSubOrgName:response.data[i].orgTo, sendingSubOrgName:response.data[i].orgFrom,
            //                 sourceOfMoney:response.data[i].sourceOfMoney, timestamp:response.data[i].timestamp, txID:response.data[i].id, bfID:"",
            //                 nameMemberFrom:response.data[i].nameMemberFrom+" "+response.data[i].surnameMemberFrom, nameMemberTo:response.data[i].name+" "+response.data[i].lastName,
            //                 additionalData:{
            //                     risk: "1.15",
            //                     status: "in_progress"
            //                   }
            //             }
            //             documents.push(tempList);
            //         }
            //         console.log("SecondList",documents)
            //         // var a = remittancesList
            //         // var b = getTemp
            //         // var c = [...a, ...b]
            //         // console.log("FinalList",c)
            //         var c = [...documents, ...remittancesList]
            //         setTransactions({
            //             list: c,
            //             count: c.length,
            //             status: (c && c.length > 0) ? STATUS_OK : STATUS_NO_RESULTS
            //         });
            //     })
            //     .catch(rejected);
            // return () => {
            //     helpers.cancel(promise, source);
            // };
        }
    };

    const onCloseModal = () => {
        setShowModal(false);
    };

    const generateReportModalClick = () => {
        setShowModal(true);
    };

    const rejected = (error) => {
        if (error.message !== cancelMessage) {
            setTransactions({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setTransactions({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    useEffect(() => {
        refreshList({ statusText: STATUS_LOADING });
        setEnableButtonReport(true);
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(SEARCH_URL, urlParams, source, getIdToken())
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    }, [updateOrg]);

    if (transactions.error && transactions.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }

    return (
        <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
            <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                    <TransactionsContext.Provider value={setTransactions}>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    {/*<SearchBar onSearchTermChange={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} />*/}
                                </Grid.Column>
                                <Grid.Column textAlign='right'>
                                    {/*<Button type="button" disabled={enableButtonReport} onClick={generateReportModalClick} style={{*/}
                                    {/*backgroundColor: '#fa4261',*/}
                                    {/*color: 'white',*/}
                                    {/*padding: 8*/}
                                    {/*}}>*/}
                                    {/*{t('transaction_report_button_open_modal')}*/}
                                    {/*</Button>*/}
                                    <ShowResults onListRefresh={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <TransactionsList
                            status={transactions.status}
                            list={transactions.list}
                            listURL={SEARCH_URL}
                            totalCount={transactions.count}
                            error={transactions.error}
                            transactionSelected={transactionSelected}
                            setTransactionSelected={setTransactionSelected}
                            setDataToast={updateOrg.setDataToast}
                            setEnableButtonReport={setEnableButtonReport}
                            enableButtonReport={enableButtonReport}
                            currentOrg={updateOrg.currentOrg} />
                        {/*<Modal open={showModal} onClose={onCloseModal}>*/}
                        {/*<GenerateReport*/}
                        {/*showModal={showModal}*/}
                        {/*setShowModal={setShowModal}*/}
                        {/*transactionSelected={transactionSelected}*/}
                        {/*setTransactionSelected={setTransactionSelected}*/}
                        {/*setEnableButtonReport={setEnableButtonReport}*/}
                        {/*setDataToast={updateOrg.setDataToast}*/}
                        {/*currentOrg={updateOrg.currentOrg}/>*/}
                        {/*</Modal>*/}
                    </TransactionsContext.Provider>
                </PageContext.Provider>
            </ShowResultsContext.Provider>
        </SearchTermContext.Provider>
    );
};

export default TransactionsProvider;
