import React from 'react';
import {Box, Typography} from "@material-ui/core";
import StepActive from "../../assets/img/step-active.png";
import StepInActive from "../../assets/img/step-inactive.png";

const Step = ({totalSteps, currentStep}) => {
    return (
        <Box mb={5}>
            <Typography variant="body2" mr={1} style={{color: '#00B171', fontSize: '16px', fontWeight: 'bold', display: 'inline'}}>Page {currentStep} of {totalSteps}</Typography>
            {[...Array(currentStep)].map((x, i) =>
                <img key={i} src={StepActive} alt="active" width={43} style={{margin: '0px 3px'}} />
            )}
            {[...Array(totalSteps - currentStep)].map((x, i) =>
                <img key={i} src={StepInActive} alt="inactive" width={43} style={{margin: '0px 3px'}} />
            )}
        </Box>
    );
};

export default Step;