import styled from 'styled-components';

const DetailsLine = styled.p`
  span, strong {
    display: inline-block;
  }

  span {
    width: ${props => props.width || 250}px;
  }
`;

export default DetailsLine;
